import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/pro-light-svg-icons";
import {
  faPlusCircle as SolidPlus,
  faThumbsUp as SolidThumbsUp,
  faThumbsDown as SolidThumsDown,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/auth-context";
import {
  useUpdateFavouritesMutation,
  useUpdateLikesMutation,
} from "../../../forYou/mediaSlidersApiSlice";

export const SliderTooltip = ({
  slideContent,
  handleTileTooltip,
  closeTooltip,
}) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [updateFavourites] = useUpdateFavouritesMutation();
  const [updateLikes] = useUpdateLikesMutation();
  const [favourite, setFavourite] = useState();
  const [like, setLike] = useState();
  const [dislike, setDislike] = useState();
  const favourites = useSelector((state) => state.mediaSliders.favourites);
  const likes = useSelector((state) => state.mediaSliders.likes);
  const dislikes = useSelector((state) => state.mediaSliders.dislikes);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (
      !slideContent ||
      !favourites ||
      !favourites?.media ||
      !likes ||
      !dislikes
    )
      return;
    setFavourite(() =>
      favourites.media.some((element) => element._id === slideContent._id)
    );
    setLike(() => likes.includes(slideContent._id));
    setDislike(() => dislikes.includes(slideContent._id));
  }, [favourites?.media.length, likes, dislikes, slideContent]);

  useEffect(() => {
    const focusableElements = tooltipRef.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (!focusableElements?.length) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      } else if (e.key === "Escape") {
        closeTooltip();
      } else if (e.key === "Enter" || e.key === " ") {
        // Symulujemy kliknięcie przycisku
        e.preventDefault();
        document.activeElement?.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    firstElement.focus();

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeTooltip]);

  const handleTooltipClick = (e, type) => {
    e.preventDefault();
    handleTileTooltip(e);
    let url = "/media";
    if (slideContent?.category?.toLowerCase() === "programme") {
      url = "/programme";
    } else if (slideContent?.category.toLowerCase() === "masterclass") {
      url = "/masterclass";
    } else if (slideContent?.chid.startsWith("TA")) {
      url = "/take-actions";
    }
    if (type === "fave" && slideContent?._id) {
      updateFavourites({
        token: auth.token,
        url: `${url}/favourites`,
        mediaId: slideContent._id,
      }).catch((err) => {
        console.log(err);
      });
    } else if ((type === "like" || type === "dislike") && slideContent) {
      updateLikes({
        token: auth.token,
        url: `${url}/preference`,
        action: type,
        mediaId: slideContent._id,
      }).catch((err) => {
        console.log(err);
      });
    }
    closeTooltip();
  };

  return (
    <div
      className="slider-tooltip arrow"
      role="dialog"
      aria-modal="true"
      ref={tooltipRef}
    >
      {favourite ? (
        <button
          className="transparent-button tooltip-row"
          onClick={(e) => handleTooltipClick(e, "fave")}
        >
          <FontAwesomeIcon icon={SolidPlus} /> {t("FY002")}
        </button>
      ) : (
        <button
          className="transparent-button tooltip-row"
          onClick={(e) => handleTooltipClick(e, "fave")}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> {t("FY001")}
        </button>
      )}
      {!(
        slideContent.category === "service" ||
        slideContent.chid.startsWith("TA")
      ) && (
        <>
          <button
            className="transparent-button tooltip-row"
            onClick={(e) => handleTooltipClick(e, "like")}
          >
            <FontAwesomeIcon icon={like ? SolidThumbsUp : faThumbsUp} />{" "}
            {t("FY003")}
          </button>
          <button
            className="transparent-button tooltip-row"
            onClick={(e) => handleTooltipClick(e, "dislike")}
          >
            <FontAwesomeIcon icon={dislike ? SolidThumsDown : faThumbsDown} />{" "}
            {t("FY004")}
          </button>
        </>
      )}
    </div>
  );
};
