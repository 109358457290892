export const navigation = [
  {
    target: 31,
    title: "settings",
    code: "S001",
    fullstory: "settings_nav",
  },
  { target: 32, title: "stats", code: "S003", fullstory: "stats_nav" },
  { target: 33, title: "invite", code: "S004", fullstory: "invite_nav" },
  {
    target: 34,
    title: "assessment",
    code: "S132",
    fullstory: "assessment_nav",
  },
  {
    target: 35,
    title: "leadership hub",
    code: "S133",
    fullstory: "leadership-hub_nav",
  },
];
