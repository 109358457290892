import React, { useState, useEffect } from "react";

import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";

import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const StressDialSlider = (props) => {
  const [textValue, setTextValue] = useState("");
  const [rotation, setRotation] = useState("");
  const [startValue, setStartValue] = useState("");

  const convertPercentToRotation = (value) => {
    let newRotation = -90 + (180 / 100) * value;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    return newRotation;
  };

  const convertPercentValues = (value) => {
    switch (value) {
      case "1":
        return 10;
      case "2":
        return 30;
      case "3":
        return 50;
      case "4":
        return 70;
      default:
        return 90;
    }
  };

  const convertTextValues = (value) => {
    switch (value) {
      case "1":
        return props.textValue[0];
      case "2":
        return props.textValue[1];
      case "3":
        return props.textValue[2];
      case "4":
        return props.textValue[3];
      default:
        return props.textValue[4];
    }
  };

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      if (props.assessmentValues[props.questionid].textValue) {
        setRotation(27);
        setTextValue(props.textValue[2]);
        setStartValue(65);
      } else {
        let text = convertTextValues(
          props.assessmentValues[props.questionid].toString()
        );
        setTextValue(text);
        let percentages = convertPercentValues(
          props.assessmentValues[props.questionid].toString()
        );
        setStartValue(percentages);
        let newRotation = convertPercentToRotation(percentages);
        setRotation(newRotation);
      }
    } else {
      setRotation(0);
      setTextValue(props.textValue[2]);
      setStartValue(50);
      props.onSetValue({ id: props.questionid, value: "3" });
    }
  }, []);

  const onSlide = (render, handle, value, un, percent) => {
    let degree = -90 + (180 / 100) * value[0].toFixed(0);
    let newRotation = degree;
    if (newRotation >= 360) {
      newRotation = -360;
    }

    let statusValue;
    if (value[0].toFixed(0) <= 20) {
      statusValue = "1";
    } else if (value[0].toFixed(0) <= 40) {
      statusValue = "2";
    } else if (value[0].toFixed(0) <= 60) {
      statusValue = "3";
    } else if (value[0].toFixed(0) <= 80) {
      statusValue = "4";
    } else {
      statusValue = "5";
    }

    setRotation(newRotation);
    let textValue = convertTextValues(statusValue);
    setTextValue(textValue);

    props.onSetValue({ id: props.questionid, value: statusValue });
  };

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      <div className="answer_block fs-exclude">
        <div>
          <div className={"dialSlider " + props.width} id={props.questionid}>
            <div className="dial">
              <div
                className="needle"
                style={{ transform: `rotate(${rotation}deg)` }}
              ></div>
            </div>

            <Nouislider
              id="dial_slider"
              range={{ min: 0, max: 100 }}
              start={startValue}
              connect="lower"
              onSlide={onSlide}
            />

            <div
              className="valueHandle"
              id={props.questionid + "_value_handle"}
            >
              {textValue}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StressDialSlider;
