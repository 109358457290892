import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf } from "@fortawesome/sharp-regular-svg-icons";
import {
  faCircleCheck,
  faLock,
  faTimer,
} from "@fortawesome/sharp-solid-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";

const ProgrammeBlock = ({ programme, activeDay }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDayClick = (index) => {
    if (activeDay) {
      let activeIndex = programme.days.findIndex(
        (day) => activeDay.title === day.title
      );
      if (
        (activeIndex > -1 && index <= activeIndex) ||
        programme.days[index].progress === 100
      ) {
        navigate(
          `/programme/${programme.chid}/${programme.chid.replace(
            / /g,
            "_"
          )}/day_${index + 1}`
        );
      }
    }
  };
  return (
    <div className="author prog">
      <section className="library prog" aria-labelledby="programme-days-heading">
        <h2 id="programme-days-heading">{t("M034")}</h2>
        <div className="scrollable">
          {programme.days &&
            programme.days.map((item, index) => (
              <button
                className="content prog transparent-button"
                key={index}
                onClick={() => handleDayClick(index)}
                aria-label={`${programme.days[index].title}, ${convertTime(
                  programme.days[index].length
                )}. ${programme.days[index].description}`}
              >
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(${programme.days[index].thumbnail_image})`,
                  }}
                  role="img"
                  aria-label={programme.days[index].title}
                >
                  <FontAwesomeIcon
                    icon={
                      programme.days[index]?.completed
                        ? faCircleCheck
                        : programme.days[index]?.chid === activeDay?.chid
                        ? faTimer
                        : faLock
                    }
                    className="prog-icon"
                  />
                </div>
                <div className="text" aria-hidden="true">
                  <p>{programme.days[index].title}</p>
                  <p>{convertTime(programme.days[index].length)}</p>
                  <p>{programme.days[index].description}</p>
                </div>
              </button>
            ))}
        </div>
      </section>
      <section className="library prog" aria-labelledby="progress-section-heading">
        <h2 id="progress-section-heading">{t("M031")}</h2>
        <div className="prog-cont">
          <div className="progress-charts">
            <div className="progress-graph">
              <span className="srOnly">
                {`${t("M037")} ${t("M038")}, ${Number(programme?.progress || 0).toFixed()}% ${t("M032")}`}
              </span>
              <div className="progress-circle" aria-hidden="true">
                <div className="circle-text">
                  <FontAwesomeIcon icon={faTimer} />
                  <span aria-hidden="true">
                    {Number(programme?.progress)?.toFixed() || 0}%
                  </span>
                  <div className="sml">{t("M032")}</div>
                </div>
              </div>
              <div className="progress-text" aria-hidden="true">
                {t("M037")} {t("M038")}
              </div>
            </div>
            <div className="progress-graph">
              <div className="srOnly">
                {`${t("M039")} ${t("M040")}, ${Number(programme?.minutes_watched || 0).toFixed()} ${t("M033")}`}
              </div>
              <div className="progress-circle" aria-hidden="true">
                <div className="circle-text">
                  <FontAwesomeIcon icon={faHourglassHalf} />
                  <span>{Number(programme?.minutes_watched || 0).toFixed()}</span>
                  <div className="sml">{t("M033")}</div>
                </div>
              </div>
              <div className="progress-text" aria-hidden="true">
                {t("M039")} {t("M040")}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ProgrammeBlock;
