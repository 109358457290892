import React, { useState, useCallback, useEffect } from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import CheckboxRadioItem from "./CheckboxRadioItem";

import OutsideAlerter from "../../../shared/hooks/OffClickClose";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function CheckboxRadio(props) {
  const [checkboxRadioClass, setCheckboxRadioClass] = useState("inactive");

  const [checkboxSelectorClass, setCheckboxSelectorClass] = useState("");

  const [upDownArrow, setupDownArrow] = useState(faChevronDown);

  const [radioOption, setRadioOption] = useState([]);

  const checkboxRadioActiveHandler = useCallback(
    (props) => {
      if (checkboxRadioClass === "inactive" && props.id !== "offclick") {
        setCheckboxRadioClass("active");
        setupDownArrow(faChevronUp);
      } else {
        setCheckboxRadioClass("inactive");
        setupDownArrow(faChevronDown);
      }
    },
    [checkboxRadioClass]
  );

  function addRadioItemHandler(item) {
    if (item.id === radioOption.id) {
      //already selected
      setCheckboxSelectorClass("");
      item = "";
    } else {
      setCheckboxSelectorClass("filled");
    }
    setRadioOption(item);
    saveValue(item);
  }

  function saveValue(item) {
    delete item.add;
    if (props.questionid === "financial_position") {
      props.onSetValue({ id: props.questionid, value: [item] });
    } else {
      props.onSetValue({ id: props.questionid, value: item });
    }
  }
  useEffect(() => {
    if (
      props.assessmentValues &&
      props.assessmentValues[props.questionid] &&
      !radioOption.id
    ) {
      setRadioOption(() =>
        Array.isArray(props.assessmentValues[props.questionid])
          ? props.assessmentValues[props.questionid][0]
          : props.assessmentValues[props.questionid]
      );
      setCheckboxSelectorClass("filled");

      if (props.questionid === "worst_area_of_pain") {
        props.onSetValue({
          id: props.questionid,
          value: props.assessmentValues[props.questionid],
        });
      }
    }
    if (
      radioOption.id &&
      props.includeOnly &&
      props.includeOnly.filter((item) => item.id === radioOption.id).length ===
        0
    ) {
      setCheckboxSelectorClass("");
    }
  }, [props.assessmentValues, radioOption]);

  useEffect(() => {
    if (
      (props.questionid === "region" || props.questionid === "sub_dept") &&
      props.options &&
      radioOption &&
      !props.options.some((el) => el.title === radioOption.title)
    ) {
      setRadioOption("");
    }
  }, [props.options]);

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  return (
    <div
      id={props.questionid}
      className={
        "question_block" + props.width + (props.skippable ? "" : " req")
      }
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      {props.questionTitle ? <h3>{props.questionTitle}</h3> : ""}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      <div className="answer_block">
        <OutsideAlerter onOffClickDetected={checkboxRadioActiveHandler}>
          <div
            className={"checkbox " + checkboxRadioClass}
            id={props.questionid}
          >
            <FontAwesomeIcon
              icon={upDownArrow}
              className="icon drop_down_operator"
            />
            <button
              className={"checkbox_selector " + checkboxSelectorClass}
              onClick={checkboxRadioActiveHandler}
              aria-labelledby={`please_select_${props.questionid}`}
            >
              <span
                className={checkboxSelectorClass && "visuallyHidden"}
                id={`please_select_${props.questionid}`}
                aria-hidden
              >
                {props.placeholder}
              </span>
            </button>
            <ul className="checkbox_list">
              {radioOption.id &&
                (!props.includeOnly ||
                  (props.includeOnly &&
                    props.includeOnly.filter(
                      (item) => item.id === radioOption.id
                    ).length > 0)) && (
                  <li key={radioOption.id} className="fs-exclude">
                    {radioOption.title}
                  </li>
                )}
            </ul>
            <div className="checkbox_container fs-exclude">
              {props.options.map((option) => {
                if (
                  !props.includeOnly ||
                  props.includeOnly.filter((item) => item.id === option.id)
                    .length > 0
                ) {
                  let checked;
                  {
                    radioOption.id === option.id.toString()
                      ? (checked = "checked")
                      : (checked = "");
                  }
                  {
                    props.secondaryQ && radioOption.id === option.id + "_sq"
                      ? (checked = "checked")
                      : (checked = checked);
                  }
                  return (
                    <CheckboxRadioItem
                      key={option.id}
                      name={props.questionid}
                      item={option}
                      onAddRadioItem={addRadioItemHandler}
                      checked={checked}
                      secondaryQ={props.secondaryQ}
                    />
                  );
                }
              })}
            </div>
          </div>
        </OutsideAlerter>
      </div>
    </div>
  );
}

export default CheckboxRadio;
