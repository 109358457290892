import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { faGridRound } from "@fortawesome/sharp-light-svg-icons";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { colours } from "../../../assets/colours";
import { useTranslation } from "react-i18next";
import { MenuOptionButton } from "../../components/menuOptionButton/MenuOptionButton";

export const SecurityLeftMenu = ({
  profile,
  changeMenu,
  changeSubMenu,
  activeSubMenu,
}) => {
  const { t } = useTranslation();

  return (
    profile && (
      <>
        <div className="btn-cont">
          <ChevronBackBtn onClick={() => changeMenu(0, "back")} />
        </div>

        <MenuOptionButton
          icon={faLockAlt}
          color={colours.yellow}
          text={t("S026")}
          onClick={() => changeSubMenu(1)}
          isActive={activeSubMenu?.id === 52}
        />

        <MenuOptionButton
          icon={faGridRound}
          color={colours.orange}
          text={t("S028")}
          onClick={() => changeSubMenu(2)}
          isActive={activeSubMenu?.id === 53}
        />
      </>
    )
  );
};
