import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChevronBackBtn.css";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const ChevronBackBtn = ({ className, onClick }) => {
  const { t } = useTranslation();

  return (
    <button className={`chevronBackBtn ${className}`} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} className="icon" />
      <span className="visuallyHidden">{t("M011")}</span>
    </button>
  );
};

export default ChevronBackBtn;
