import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colours } from "../../../assets/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faChevronRight,
  faCrosshairs,
  faMegaphone,
  faMoon,
} from "@fortawesome/pro-light-svg-icons";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import ToggleBtn from "../../../shared/components/styledComponents/toggleBtn/ToggleBtn";
import { useTranslation } from "react-i18next";

export const NotificationsLeftMenu = ({
  profile,
  hide,
  activeSubMenu,
  changeSubMenu,
}) => {
  const { t } = useTranslation();
  const [doNotDisturb, setDoNotDisturb] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  const navigate = useNavigate();

  const handleDoNotDisturb = () => {
    setDoNotDisturb(!doNotDisturb);
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      {profile && (
        <>
          <div className="btn-cont">
            <ChevronBackBtn onClick={() => navigate("/profile/settings")} />
          </div>
          <div className="profile-slider-btn">
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faMoon}
                style={{ color: colours.blue }}
                className="slider-icon"
              />

              <div className="slider-text">
                Do Not Disturb: {doNotDisturb ? "On" : "Off"}
              </div>
            </div>
            <ToggleBtn
              isOn={doNotDisturb}
              onToggle={handleDoNotDisturb}
              containerColor={doNotDisturb ? colours.blue : "#281D2D"}
              toggleColor={doNotDisturb ? "#fff" : "#64596B"}
              size={{ width: "62px", height: "35px" }}
            />
          </div>{" "}
          <p className="cm">
            {doNotDisturb ? "All notifications are paused" : ""}
          </p>
          <div
            className={`profile-slider-btn ${
              activeSubMenu?.id === 55 && !hide ? "active" : ""
            }`}
            onClick={() => {
              changeSubMenu(4);
            }}
          >
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faBell}
                style={{ color: colours.green }}
                className="slider-icon"
              />
              <div className="slider-text">{t("T338")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
          <div
            className={`profile-slider-btn ${
              activeSubMenu?.id === 56 && !hide ? "active" : ""
            }`}
            onClick={() => {
              changeSubMenu(5);
            }}
          >
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faCrosshairs}
                style={{ color: colours.orange }}
                className="slider-icon"
              />
              <div className="slider-text">{t("T339")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
          <div className="profile-slider-btn">
            {" "}
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faMegaphone}
                style={{ color: colours.yellow }}
                className="slider-icon"
              />
              <div className="slider-text">{t("T340")}</div>
            </div>
            <ToggleBtn
              isOn={isToggled}
              onToggle={handleToggle}
              containerColor={isToggled ? colours.yellow : "#281D2D"}
              toggleColor={isToggled ? "#fff" : "#64596B"}
              size={{ width: "62px", height: "35px" }}
            />
          </div>
        </>
      )}
    </>
  );
};
