import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBullseyeArrow,
  faMagnifyingGlass,
  faUser,
  faLifeRing,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth-context";
import "./NavStickyBar.css";

const navItems = [
  { path: "/for-you", icon: faHouse, label: "FT001", authRequired: true },
  { path: "/goals", icon: faBullseyeArrow, label: "FT002", authRequired: true },
  {
    path: "/explore",
    icon: faMagnifyingGlass,
    label: "FT003",
    authRequired: true,
  },
  { path: "/profile/settings", icon: faUser, label: "FT004" },
  { path: "/support", icon: faLifeRing, label: "H001" },
];

export const NavStickyBar = () => {
  const { tags } = useContext(AuthContext);
  const { t } = useTranslation();
  const location = useLocation();
  const pageId = location.pathname.split("/")[1];

  return (
    <nav className="main_navbar">
      <div id="scroll_fade_bottom"></div>
      <div id="progress_navbar_container">
        <div id="progress_navbar">
          <div className="icon_navbar">
            {navItems.map(({ path, icon, label, authRequired }) => {
              if (authRequired && (!tags || tags.length === 0)) return null;
              const isActive = pageId === path.split("/")[1];

              return (
                <div className="icon_navbar_item" key={path}>
                  <Link
                    to={path}
                    aria-label={t(label)}
                    aria-current={isActive ? "page" : undefined}
                  >
                    <div className={`icon-cont ${isActive ? "active" : ""}`}>
                      <FontAwesomeIcon icon={icon} className="icon" />
                    </div>
                    {t(label)}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};
