import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/SnoozeButtonStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmSnooze } from "@fortawesome/pro-thin-svg-icons";

const SnoozeButton = (props) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [snoozeTime, setSnoozeTime] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (props.isPlaying) {
      setIsVisible(true);
    } else {
      const timeout = setTimeout(() => setIsVisible(false), 700);
      return () => clearTimeout(timeout);
    }
  }, [props.isPlaying]);

  const handleSnoozeClick = (time) => {
    if (props.isPlaying) {
      if (time === snoozeTime) {
        setSnoozeTime(null);
        props.handleSnooze(null);
      } else {
        setSnoozeTime(time);
        props.handleSnooze(time);
      }
    }
  };

  const handleSnoozeButtonHover = () => {
    setIsHovered(true);
  };

  const handleSnoozeButtonLeave = () => {
    setIsHovered(false);
  };

  const handleSnoozeButtonClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (!props.isPlaying && snoozeTime !== null) {
      setSnoozeTime(null);
    }
    if (props.snooze) {
      setSnoozeTime(props.snooze);
    }
  }, [props.isPlaying, props.snooze]);

  return (
    <div className={`snooze-container ${isVisible ? "visible" : ""}`}>
      {(isHovered || showOptions) && (
        <div className="snooze-title">{t("M003")}</div>
      )}
      <div
        id={snoozeTime ? "active-snooze" : ""}
        className="snooze-button"
        onMouseEnter={handleSnoozeButtonHover}
        onMouseLeave={handleSnoozeButtonLeave}
      >
        <button
          className={isHovered ? "snooze-button-hovered alarm" : "alarm"}
          onClick={handleSnoozeButtonClick}
        >
          <span className="visuallyHidden">
            {showOptions ? t("M104") : t("M103")}
          </span>
          <FontAwesomeIcon icon={faAlarmSnooze} className="icon" />
        </button>

        {(isHovered || showOptions) && (
          <div className="snooze-options">
            <button
              className={
                snoozeTime === 5 ? "snooze-option-selected top" : "top"
              }
              onClick={() => handleSnoozeClick(5)}
            >
              <span className="visuallyHidden">{t("M003")}</span>
              5min
            </button>
            <button
              className={
                snoozeTime === 15 ? "snooze-option-selected middle" : "middle"
              }
              onClick={() => handleSnoozeClick(15)}
            >
              <span className="visuallyHidden">{t("M003")}</span>
              15min
            </button>
            <button
              className={
                snoozeTime === 30 ? "snooze-option-selected bottom" : "bottom"
              }
              onClick={() => handleSnoozeClick(30)}
            >
              <span className="visuallyHidden">{t("M003")}</span>
              30min
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SnoozeButton;
