import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  faDroplet,
  faMartiniGlass,
  faShieldCheck,
  faWatermelonSlice,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import { Slider } from "../../shared/components/slider/Slider";
import { colours } from "../../assets/colours";
import MotivationTile from "../components/MotivationTile";
import CircleValueTile from "../components/CircleValueTile";
import WaterIntakeGraph from "../components/WaterIntakeGraph";
import JournalBlock from "../components/JournalBlock";
import {
  blockersHyd,
  motivatorsHyd,
  reasonAlc,
} from "../../shared/components/data";

const HydrationResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;

  return (
    <div className="results-container ">
      {props.showAssessment && assessScores?.water_data && (
        <div className="results-row ">
          <BarChart
            title={t("D128")}
            icon={faDroplet}
            average={assessScores.water_data_average}
            data={assessScores.water_data}
            units={t("D139")}
            width="half bars"
            average_type={t("D079")}
            color={colours.blue}
            modalText={[t("D140"), t("D141"), t("D142")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
          <CircleValueTile
            title={t("D129")}
            textValue={assessScores.caffeine}
            value={t("D148")}
            units=""
            icon={faShieldCheck}
            colour={colours.yellow}
            width="quarter"
            modalText={[t("D143")]}
            openModal={props.openModal}
          />
          <CircleValueTile
            title={t("D130")}
            textValue={assessScores.caffeine_in_bloodstream}
            units="mg"
            value={t("D149")}
            icon={faWatermelonSlice}
            colour={colours.yellow}
            width="quarter"
            modalText={[t("D144")]}
            openModal={props.openModal}
          />
          <BarChart
            title={t("D131")}
            icon={faMartiniGlass}
            data={assessScores.alcohol_data}
            average={assessScores.alcohol_data_average}
            units={t("D150")}
            average_type={t("D079")}
            width="half bars"
            color={colours.yellow}
            modalText={[t("D145"), t("D146"), t("D147")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
        </div>
      )}
      {!props.showAssessment &&
        trackerScores?.hydration?.hydration.length > 0 && (
          <div className="results-row ">
            <BarChart //daily water intake
              title={t("D128")}
              average={trackerScores.hydration.hydration_average}
              data={trackerScores.hydration.hydration}
              icon={faDroplet}
              units={t("D139")}
              average_type={t("DW032")}
              width="half bars"
              color={colours.blue}
              modalText={[t("D161"), t("D141"), t("D160")]}
              openModal={props.openModal}
            />
            <WaterIntakeGraph //today's water intake
              title={t("DW018")}
              percent={
                trackerScores.hydration.today_hydration_percentage || "0%"
              }
              glasses={trackerScores.hydration.today_total_hydration || 0}
              litres={trackerScores.hydration.today_total_hydration * 0.25}
              modalText={[t("D162")]}
              openModal={props.openModal}
              width="half"
            />
            <MotivationTile //hydration motivators
              title={t("D134")}
              colour={colours.green}
              data={trackerScores.hydration.topEnhancers}
              valueArr={motivatorsHyd}
              width="quarter"
              modalText={[t("D152")]}
              openModal={props.openModal}
            />
            <MotivationTile
              title={t("D135")} //hydration blockers
              colour={colours.red}
              data={trackerScores.hydration.topInhibitors}
              valueArr={blockersHyd}
              width="quarter"
              modalText={[t("D153")]}
              openModal={props.openModal}
            />
            <BarChart //weekly alcohol units
              title={t("D131")}
              data={trackerScores.alcohol.units}
              icon={faMartiniGlass}
              average={trackerScores.alcohol.units_average}
              units={t("D150")}
              average_type={t("DW035")}
              width="half bars"
              color={colours.yellow}
              modalText={[t("D156"), t("D154"), t("D157")]}
              openModal={props.openModal}
            />
            <CircleValueTile //drink-free days
              title={t("D137")}
              data={trackerScores.alcohol.total_drink_free}
              value={t("M070")}
              icon={faShieldCheck}
              colour={colours.green}
              width="quarter"
              withIcon
              modalText={[t("D155")]}
              openModal={props.openModal}
            />
            <MotivationTile //reasons for drinking
              title={t("D138")}
              data={trackerScores.alcohol.topInhibitors}
              valueArr={reasonAlc}
              colour={colours.red}
              width="quarter"
              modalText={[t("D151")]}
              openModal={props.openModal}
            />
            <JournalBlock
              width="quarter"
              startWith={6}
              journalArr={trackerJournals}
              openModal={props.openModal}
            />
          </div>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default HydrationResults;
