import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, props) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                props.onOffClickDetected({id: 'offclick'});
                //alert("You clicked outside of me! " + props.active);
            }
        }

        // Bind the event listener
        document.addEventListener("keyup", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("keyup", handleClickOutside);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props);

    return <div ref={wrapperRef}>{props.children}</div>;
  };
  
  export default OutsideAlerter;