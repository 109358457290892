import { faBarChart, faBolt } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { MenuOptionButton } from "../components/menuOptionButton/MenuOptionButton";
import { colours } from "../../assets/colours";

export const ProfileStatsLeftMenu = ({ activeSubMenu, changeSubMenu }) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuOptionButton
        icon={faBolt}
        text={t("S043")}
        onClick={() => changeSubMenu(0)}
        active={activeSubMenu.id === 61}
        color={colours.blue}
      />
      <MenuOptionButton
        icon={faBarChart}
        text={t("S047")}
        onClick={() => changeSubMenu(1)}
        active={activeSubMenu.id === 62}
        color={colours.yellow}
      />
    </>
  );
};
