import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";

const PasswordInput = (props) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");

// setting field type to "password", so browser does not remember text
  useEffect(()=>{
    if(props.submitting)setShowPassword(false)
  }, [ props.submitting])

  const handleValueChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const el = document.getElementById(id);
    let valid = false;

    setPasswordValue(value);

    if (id === "password") {
      // Create an array and push all possible values that you want in password
      let matchedCase = [];
      matchedCase.push("[A-Z]"); // Uppercase Alpabates
      matchedCase.push("[0-9]"); // Numbers
      matchedCase.push("[a-z]"); // Lowercase Alphabates

      // Check the conditions
      let ctr = 0;
      for (let i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(value)) {
          ctr++;
        }
      }
      if (ctr > 2 && value.length > 7) {
        valid = true;
        props.handlePassword(value);
      }
      //check if matches with confirmed password (if any)
      if (
        props.confirmPassword &&
        props.confirmPassword.length > 7 &&
        props.confirmPassword !== value
      ) {
        valid = false;
      }
    } else if (id === "confirm_password") {
      if (value === props.password) {
        valid = true;
        props.handleConfirmPassword(value);
      }
    }
    if (valid) {
      el.classList.remove("invalid");
    } else {
      el.classList.add("invalid");
    }
  };

  const handlePasswordPreview = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      {props.visible ? (
        <div className="password_container">
          <input
            type={showPassword ? "text" : "password"}
            className="code_input "
            id="password"
            name="password"
            placeholder={t("R024")}
            onChange={handleValueChange}
            value={passwordValue}
          />
          <button
            className="password_preview transparent-button"
            onClick={handlePasswordPreview}
          >
            <span className="visuallyHidden">
              {showPassword ? t("R094") : t("R093")}
            </span>
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="icon"
            />
          </button>
        </div>
      ) : (
        <input
          type= "password"
          className="code_input "
          id="confirm_password"
          name="confirm_password"
          placeholder={t("R025")}
          onChange={handleValueChange}
          value={passwordValue}
        />
      )}
    </>
  );
};
export default PasswordInput;
