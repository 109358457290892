import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

function MskBody({ questionid, sex, onSetValue, areas_of_pain }) {
  const { t } = useTranslation();
  const [areasOfPainFront, setAreasOfPainFront] = useState([]);
  const [areasOfPainBack, setAreasOfPainBack] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (areasOfPainFront.length === 0) {
      var front = [];
      var back = [];

      if (sex !== "male") {
        front = [
          { id: "_jaw", title: t("MSK001"), top: "50px", left: "130px" },
          { id: "_pelvis", title: t("MSK002"), top: "215px", left: "140px" },
          { id: "_hip", title: t("MSK003"), top: "215px", left: "65px" },
          { id: "_knee", title: t("MSK004"), top: "355px", left: "95px" },
          { id: "_ankle", title: t("MSK005"), top: "460px", left: "125px" },
          { id: "_foot", title: t("MSK006"), top: "485px", left: "95px" },
          { id: "_shoulder", title: t("MSK007"), top: "90px", left: "55px" },
          { id: "_elbow", title: t("MSK008"), top: "170px", left: "45px" },
          { id: "_wrist", title: t("MSK009"), top: "220px", left: "195px" },
          { id: "_fingers", title: t("MSK010"), top: "265px", left: "220px" },
        ];

        back = [
          { id: "_neck", title: t("MSK011"), top: "70px", left: "110px" },
          { id: "_mid-back", title: t("MSK012"), top: "140px", left: "110px" },
          {
            id: "_lower-back",
            title: t("MSK013"),
            top: "200px",
            left: "110px",
          },
        ];
      } else {
        front = [
          { id: "_jaw", title: t("MSK001"), top: "50px", left: "130px" },
          { id: "_pelvis", title: t("MSK002"), top: "230px", left: "150px" },
          { id: "_hip", title: t("MSK003"), top: "230px", left: "75px" },
          { id: "_knee", title: t("MSK004"), top: "355px", left: "95px" },
          { id: "_ankle", title: t("MSK005"), top: "460px", left: "130px" },
          { id: "_foot", title: t("MSK006"), top: "485px", left: "95px" },
          { id: "_shoulder", title: t("MSK007"), top: "90px", left: "55px" },
          { id: "_elbow", title: t("MSK008"), top: "170px", left: "45px" },
          { id: "_wrist", title: t("MSK009"), top: "230px", left: "205px" },
          { id: "_fingers", title: t("MSK010"), top: "275px", left: "220px" },
        ];

        back = [
          { id: "_neck", title: t("MSK011"), top: "70px", left: "115px" },
          { id: "_mid-back", title: t("MSK012"), top: "155px", left: "115px" },
          {
            id: "_lower-back",
            title: t("MSK013"),
            top: "215px",
            left: "115px",
          },
        ];
      }
      setAreasOfPainFront(front);
      setAreasOfPainBack(back);
    }
  }, [sex]);

  function addSelectedItemHandler(event) {
    const id = event.target.id;
    const title = event.target.title;
    updateBodyMap(id, title);
  }

  function markerClickHandler(event) {
    let str = event.target.classList;
    let title = event.target.title;
    updateBodyMap(str[1], title);
    if (str[2] === "active") {
      document.getElementById(str[1]).checked = true;
    } else {
      document.getElementById(str[1]).checked = false;
    }
  }

  function updateBodyMap(item_id, item_title) {
    let list = [...selectedItems];
    if (!list.some((e) => e.id === item_id.substring(1))) {
      list.push({ id: item_id, title: item_title });
    } else {
      let index = list
        .map(function (x) {
          return x.id;
        })
        .indexOf(item_id.substring(1));
      list.splice(index, 1);
    }
    setSelectedItems(list);
    saveValue(list);
    setTimeout(() => {
      const element = document.getElementById(item_id);
      if (element) {
        element.focus();
      }
    }, 0);
  }

  function saveValue(list) {
    let array = [...list];
    if (array.length < 1) {
      array = "";
    } else {
      array.forEach((item) => {
        if (item.id.startsWith("_")) {
          item.id = item.id.substring(1);
        }
      });
    }
    onSetValue({ id: questionid, value: array });

    //if one item then set as worst area of pain
    if (array.length === 1) {
      onSetValue({
        id: "worst_area_of_pain",
        value: { id: array[0].id, title: list[0].title },
      });
    } else {
      onSetValue({ id: "worst_area_of_pain", value: "" });
    }
  }

  useEffect(() => {
    if (areas_of_pain && selectedItems.length === 0) {
      let array = [...areas_of_pain];
      array.forEach((item) => {
        if (!item.id.startsWith("_")) {
          item.id = "_" + item.id;
        }
      });
      setSelectedItems(array);
      saveValue(areas_of_pain);
    }
  }, [areas_of_pain, questionid, selectedItems.length]);

  const BodyMarker = ({ item, active }) => {
    return (
      <div
        className={"body_marker " + item.id + " " + active}
        style={{ top: item.top, left: item.left }}
        title={item.title}
        onClick={markerClickHandler}
      ></div>
    );
  };

  const CheckboxItem = ({item, checked}) => {
    return (
      <div className="checkbox_item">
        <input
          type="checkbox"
          id={item.id}
          name={item.id}
          className="fdatacollect"
          onChange={addSelectedItemHandler}
          title={item.title}
          checked={checked}
        />
        <label htmlFor={item.id}>
          <FontAwesomeIcon icon={faCheckCircle} className="icon" />
          {item.title}
        </label>
      </div>
    );
  };

  return (
    <div id={questionid} className="req question_block_wide fs-exclude">
      <div className="answer_block msk_container" id="msk_bg">
        <div className={"msk_body " + sex + " front"}>
          <div className="front" style={{ display: "inline-block" }}>
            {areasOfPainFront.map((option) => {
              let active;
              selectedItems.find(
                (element) =>
                  "_" + element.id === option.id || element.id === option.id
              )
                ? (active = "active")
                : (active = "");

              return (
                <BodyMarker key={option.id} item={option} active={active} />
              );
            })}
          </div>
        </div>
        <div className={"msk_body " + sex + " back"}>
          <div className="back">
            {areasOfPainBack.map((option) => {
              let active;
              selectedItems.find(
                (element) =>
                  "_" + element.id === option.id || element.id === option.id
              )
                ? (active = "active")
                : (active = "");
              return (
                <BodyMarker key={option.id} item={option} active={active} />
              );
            })}
          </div>
        </div>
        <div
          className="msk_options front checkbox"
          style={{ display: "inline-block" }}
        >
          {areasOfPainFront.map((option) => {
            let checked = "";
            selectedItems.find(
              (element) =>
                "_" + element.id === option.id || element.id === option.id
            )
              ? (checked = "checked")
              : (checked = "");
            return (
              <CheckboxItem key={option.id} item={option} checked={checked} />
            );
          })}
          {areasOfPainBack.map((option) => {
            let checked = "";
            selectedItems.find(
              (element) =>
                "_" + element.id === option.id || element.id === option.id
            )
              ? (checked = "checked")
              : (checked = "");
            return (
              <CheckboxItem key={option.id} item={option} checked={checked} />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MskBody;
