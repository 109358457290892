import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import "./PromoContent.css";

export const PromoContent = ({
  label,
  title,
  fade,
  labelColourFirst,
  labelColourSecond,
  subTitle,
  url,
  company_logo,
}) => {
  return (
    <div className="promoContent">
      <PromoLabel
        label={label}
        labelColourFirst={labelColourFirst}
        labelColourSecond={labelColourSecond}
      />
      <CompanyLogo company_logo={company_logo} />
      <div className="promoContent_titleContainer">
        <div className="promoContent_title">{title}</div>
        <Subtitle subTitle={subTitle} url={url} />
      </div>
      <div
        className="promoContent_fade"
        style={{
          background: `linear-gradient(to bottom, transparent 0%, transparent 30%, ${fade} 100%)`,
        }}
      />
    </div>
  );
};

const PromoLabel = ({ label, labelColourFirst, labelColourSecond }) => {
  return (
    <div className="promoContent_labelContainer">
      {label}
      <div
        className="promoContent_outerLabelContainer"
        style={{
          background: `linear-gradient(to right, ${labelColourFirst} 0%,  ${labelColourSecond} 100%)`,
        }}
      />
    </div>
  );
};

const CompanyLogo = ({ company_logo }) => {
  if (!company_logo) return null;

  return (
    <img
      src={company_logo}
      className="promoContent_companyLogo"
      alt="company logo"
    />
  );
};

const Subtitle = ({ subTitle, url }) => {
  if (!subTitle) return null;

  return (
    <div className="promoContent_subtitleContainer">
      <div className="promoContent_subtitle">
        {subTitle}{" "}
        {url && <FontAwesomeIcon icon={faArrowRight} className="small-icon " />}
      </div>
    </div>
  );
};
