import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import ProtectedRoute from "./shared/components/ProtectedRoute";

import "./App.css";

import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";

import ErrorBoundary from "./shared/components/errorBoundary/ErrorBoundary";

import { AuthContext } from "./shared/context/auth-context";
import { isClientDemo, useAuth } from "./shared/hooks/auth-hook";

import AccessCode from "./pages/onboarding/AccessCode";
import { RequestAccessCode } from "./pages/onboarding/RequestAccessCode";
import Signup from "./pages/onboarding/Signup";
import Login from "./pages/onboarding/Login";
import { Profile } from "./profile/Profile";
import Settings from "./pages/onboarding/Settings";
import RequestPasswordReset from "./pages/onboarding/RequestPasswordReset";
import ResetPassword from "./pages/onboarding/ResetPassword";

import Assessment from "./assessment/pages/Assessment";
import ForYou from "./forYou/ForYou";
import Explore from "./explore/Explore";
import Goals from "./goals/Goals";
import Authors from "./platform/pages/Authors";

import { SingleMediaCoverWrap } from "./contentTypes/singleMedia/SingleMediaCoverWrap";
import { SingleMediaPlayerWrap } from "./contentTypes/singleMedia/SingleMediaPlayerWrap";
import { MasterclassCoverWrap } from "./contentTypes/masterclass/MasterclassCoverWrap";

import { ProgrammeCoverWrap } from "./contentTypes/programme/ProgrammeCoverWrap";
import { ProgrammePlayerWrap } from "./contentTypes/programme/ProgrammePlayerWrap";

import { CompanyReport } from "./companyReport/pages/CompanyReport";

import Results from "./results/Results";
import Support from "./support/Support";
import { MasterclassPlayerWrap } from "./contentTypes/masterclass/MasterclassPlayerWrap";
import WhitePopUpCTA from "./shared/modal/whitePopUp/WhitePopUpCTA";

import iosImg from "./assets/images/App_Store_Badge.png";
import androidImg from "./assets/images/GoogleStore.png";
import Challenges from "./challenges/Challenges";
import { useTranslation } from "react-i18next";
import { useUrl } from "./shared/hooks/url-hook";
import { SupportedLanguages } from "./shared/util/enum";
import { initializeDatadogRum } from "./shared/util/datadogConfig";

initializeDatadogRum();

function App(_props) {
  const {
    token,
    login,
    logout,
    userId,
    fname,
    access,
    email,
    image,
    tags,
    clientName,
    npsStatus,
    authType,
    language,
    isHub,
    profileHandler,
    npsStatusHandler,
    tagHandler,
    languageHandler,
  } = useAuth();
  const url = useUrl();

  const [openOverlay, setOpenOverlay] = useState(false);
  const [isAndroidState, setIsAndroidState] = useState(false);
  const [isIOSState, setIsIOSState] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isMobile) {
      if (isTablet) {
        //this is a tablet
      } else {
        setOpenOverlay(true);
        if (isAndroid) {
          setIsAndroidState(true);
        } else if (isIOS) {
          setIsIOSState(true);
        }
      }
    } else {
      //this is not a mobile device
    }
  }, []);

  const [lostNetwork, setLostNetwork] = useState(false);
  const whitePopUpCTAContents = {
    title: t("OL001"),
    textArr: [
      {
        text: t("OL002"),
        className: "",
      },
    ],
    buttons: [
      {
        text: false,
        color: false,
        onClick: () => {
          return;
        },
      },
      {
        text: false,
        color: false,
        onClick: () => {
          return;
        },
      },
    ],
  };

  useEffect(() => {
    function updateOnlineStatus() {
      if (navigator.onLine) {
        setLostNetwork(false);
      } else {
        setLostNetwork(true);
      }
    }

    // Check online status immediately
    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []); // Empty dependency array so the effect only runs once on mount

  const RedirectToCode = () => {
    const { codeid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      navigate(`/code/${codeid}`);
    }, [codeid, navigate]);

    return null;
  };

  //before user logs in, set language based on browser language
  useEffect(() => {
    if (!token && !language && !localStorage.getItem("i18nextLng")) {
      const languages = navigator.languages;
      // Use a Set to filter out duplicate language codes (ignoring region)
      const uniqueLanguages = Array.from(
        new Set(languages.map((lang) => lang.split("-")[0]))
      );

      // Get the top two unique languages
      const topTwoLanguages = uniqueLanguages.slice(0, 2);

      const preferredLanguages = topTwoLanguages;

      let browserLanguage = preferredLanguages.find((code) =>
        SupportedLanguages.includes(code)
      );

      if (browserLanguage) {
        languageHandler(browserLanguage);
      }
    }
  });

  async function hashUserId(userId) {
    const msgUint8 = new TextEncoder().encode(userId);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedUserId = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashedUserId;
  }

  window["_fs_capture_on_startup"] = false;

  useEffect(() => {
    if (!userId || !clientName) return;
    const excludedUrls = [
      "/company-report/live-analytics",
      "/company-report/erv-import",
    ];

    // Get the current path
    const currentPath = new URL(url).pathname;

    hashUserId(userId).then((hashedUserId) => {
      window.FS("setIdentity", {
        userId: hashedUserId,
        properties: {
          userId: hashedUserId,
          clientName: clientName,
        },
      });
    });

    // Check if the current path is in the list of excluded URLs, or if clientName includes "champion" or "DEMO"
    if (excludedUrls.includes(currentPath) || isClientDemo(clientName)) {
      window.FS("shutdown");
    } else {
      window.FS("start");
    }
  }, [userId, clientName, url]); // Add url to the dependency array

  // google analytics collecting client name
  useEffect(() => {
    if (!userId || !clientName) return;
    window.gtag("set", "user_properties", {
      Client_Name: clientName,
    });
  }, [userId, clientName, url]);

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          fname: fname,
          access: access,
          email: email,
          login: login,
          logout: logout,
          image: image,
          tags: tags,
          clientName: clientName,
          npsStatus: npsStatus,
          authType: authType,
          language: language,
          isHub,
          profileHandler: profileHandler,
          npsStatusHandler: npsStatusHandler,
          tagHandler: tagHandler,
          languageHandler: languageHandler,
        }}
      >
        {openOverlay && (
          <div id="mobile-device-overlay">
            <div className="mobile-device-txt">
              Dive into your world of wellbeing with the Champion Health app!
            </div>
            {isIOSState && (
              <a
                href="https://apps.apple.com/us/app/champion-health/id1580359828"
                target="_blank"
                rel="noopener noreferrer"
                alt="Link to App Store"
              >
                <div
                  className="store-btn"
                  style={{
                    backgroundImage: `url(${iosImg})`,
                    width: "180px",
                  }}
                ></div>
              </a>
            )}
            {isAndroidState && (
              <a
                href="https://play.google.com/store/apps/details?id=com.championhealth.app"
                target="_blank"
                rel="noopener noreferrer"
                alt="Link to Google Play"
              >
                <div
                  className="store-btn"
                  style={{
                    backgroundImage: `url(${androidImg})`,
                    width: "203px",
                  }}
                ></div>{" "}
              </a>
            )}
          </div>
        )}
        {lostNetwork && (
          <WhitePopUpCTA popupContents={whitePopUpCTAContents} offline />
        )}
        <Router>
          <Routes>
            {/* Access Code Pages/redirect */}
            <Route path="*" element={<Navigate replace to="/code" />} />
            <Route path="/" element={<Navigate replace to="/code" />} />
            <Route path="/" element={<AccessCode />} />
            <Route path="/register" element={<Navigate replace to="/code" />} />
            <Route path="/register/:codeid" element={<RedirectToCode />} />
            <Route
              path="/code"
              element={!!token ? <Navigate to="/for-you" /> : <AccessCode />}
            />
            <Route
              path="/code/:codeid"
              element={!!token ? <Navigate to="/for-you" /> : <AccessCode />}
            />
            <Route path="/find-code" element={<RequestAccessCode />} />
            {/* Onboarding Pages */}
            <Route
              path="/signup"
              element={!!token ? <Navigate to="/for-you" /> : <Signup />}
            />
            <Route
              path="/sign-in"
              element={!!token ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route
              path="/sign-in/*"
              element={!!token ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route
              path="/signin"
              element={<Navigate replace to="/sign-in" />}
            />
            {/* Password Reset Pages */}
            <Route
              path="/reset-sign-in"
              element={!!token ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route
              path="/reset/:token"
              element={!!token ? <Navigate to="/for-you" /> : <ResetPassword />}
            />
            <Route
              path="/reset"
              element={
                !!token ? <Navigate to="/for-you" /> : <RequestPasswordReset />
              }
            />
            {/* In App Pages */}

            {/* routes only available once the user signed in */}
            <Route
              path="/assessment"
              element={<ProtectedRoute element={Assessment} />}
            />
            <Route
              path="/for-you"
              element={<ProtectedRoute element={ForYou} tags={tags} />}
            />
            <Route
              path="/:healthareaid/library"
              element={<ProtectedRoute element={ForYou} tags={tags} />}
            />
            <Route
              path="/:healthareaid/challenge"
              element={<ProtectedRoute element={Challenges} tags={tags} />}
            />
            <Route
              path="/:healthareaid/results"
              element={<ProtectedRoute element={Results} tags={tags} />}
            />
            <Route
              path="/:healthareaid/track"
              element={<ProtectedRoute element={Results} tags={tags} />}
            />
            <Route
              path="/goals"
              element={<ProtectedRoute element={Goals} tags={tags} />}
            />
            <Route
              path="/explore"
              element={<ProtectedRoute element={Explore} tags={tags} />}
            />

            <Route
              path="/masterclass/:chid/:title"
              element={
                <ProtectedRoute element={MasterclassCoverWrap} tags={tags} />
              }
            />
            <Route
              path="/masterclass/:chid/:title/:chapterNo/:chapterTitle"
              element={
                <ProtectedRoute element={MasterclassPlayerWrap} tags={tags} />
              }
            />

            <Route
              path="/programme/:chid/:title"
              element={
                <ProtectedRoute element={ProgrammeCoverWrap} tags={tags} />
              }
            />
            <Route
              path="/programme/:chid/:title/:day"
              element={
                <ProtectedRoute element={ProgrammePlayerWrap} tags={tags} />
              }
            />

            <Route
              path="/media/player/:chid/:title"
              element={
                <ProtectedRoute element={SingleMediaPlayerWrap} tags={tags} />
              }
            />
            <Route
              path="/media/:chid/:title"
              element={
                <ProtectedRoute element={SingleMediaCoverWrap} tags={tags} />
              }
            />
            <Route
              path="/take-actions/:chid/:title"
              element={
                <ProtectedRoute element={SingleMediaCoverWrap} tags={tags} />
              }
            />
            <Route
              path="/company-report/:pageid?/:subpageid?"
              element={<ProtectedRoute element={CompanyReport} tags={tags} />}
            />
            <Route
              path="/authors/:authorid"
              element={<ProtectedRoute element={Authors} tags={tags} />}
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute
                  element={() => <Navigate to="/profile/settings" />}
                />
              }
            />
            <Route
              path="/profile/:subpage"
              element={<ProtectedRoute element={Profile} />}
            />
            <Route
              path="/profile/:subpage/:subsubpage"
              element={<ProtectedRoute element={Profile} />}
            />
            <Route
              path="/support"
              element={<ProtectedRoute element={Support} />}
            />

            <Route
              path="/settings"
              element={<ProtectedRoute element={Settings} />}
            />

            <Route render={() => <Navigate to="/" replace />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}
export default App;
