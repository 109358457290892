import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faBullseyeArrow,
  faCrosshairs,
  faCrown,
  faLightbulb,
  faPencil,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";

import { colours } from "../../assets/colours";
import { formatAssessmentDate } from "../../shared/util/helperfunctions";

import CircleValueTile from "../../results/components/CircleValueTile";
import WhitePopUpNoTitle from "../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import WhitePopUpCTA from "../../shared/modal/whitePopUp/WhitePopUpCTA";
import { ContentItem } from "../../shared/components/slider/contentItem/ContentItem";

const SingularGoal = (props) => {
  const { t } = useTranslation();
  const singleGoal = useSelector((state) =>
    state.goals.goals.find((goal) => goal.id === props.goal)
  );
  const goals = useSelector((state) => state.goals.goals);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");
  const [openDeleteGoalModal, setOpenDeleteGoalModal] = useState(false);
  const [whitePopUpCTAContents, setWhitePopUpCTAContents] = useState({});

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const openModal = useCallback(
    (title, text) => {
      setModalIsOpen(true);
      setModalTextContent({ title: title, text: text });
    },
    [modalIsOpen, modalTextContent]
  );

  const handleDeleteGoal = () => {
    if (goals.length === 1) {
      setWhitePopUpCTAContents({
        title: t("G044"),
        textArr: [
          { text: t("G035"), className: "sub-heading" },
          {
            text: t("G036"),
            className: "",
          },
        ],
        buttons: [
          {
            text: t("G037"),
            color: "dark",
            onClick: () => {
              props.handleOpenGoalSelector();
              setOpenDeleteGoalModal(false);
            },
          },
          {
            text: t("G055"),
            color: "pink",
            onClick: () => {
              setOpenDeleteGoalModal(false);
            },
          },
        ],
      });
    } else {
      setWhitePopUpCTAContents({
        title: t("G044"),
        textArr: [
          { text: t("S020"), className: "sub-heading" },
          {
            text: t("G054"),
            className: "",
          },
        ],
        buttons: [
          {
            text: t("R013"),
            color: "dark",
            onClick: () => {
              props.confirmDeleteGoal(singleGoal);
              setOpenDeleteGoalModal(false);
            },
          },
          {
            text: t("G055"),
            color: "pink",
            onClick: () => {
              setOpenDeleteGoalModal(false);
            },
          },
        ],
      });
    }
    setOpenDeleteGoalModal(true);
  };

  return (
    <div id={`goal-${singleGoal.id}`} className="single-goal-cont ">
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      {openDeleteGoalModal && (
        <WhitePopUpCTA popupContents={whitePopUpCTAContents} />
      )}
      <div className="goal-top-line">
        <div className="goal-title fs-exclude">
          <FontAwesomeIcon
            icon={faBullseyeArrow}
            style={{ color: colours.orange }}
          />{" "}
          <span className="text-wrapper-7">&nbsp;&nbsp;</span>
          {t(singleGoal.title)}
        </div>
        <div className="goal-actions ">
          <div className="goal-action" onClick={props.handleOpenGoalSelector}>
            <FontAwesomeIcon icon={faPencil} className="icon" />{" "}
            <span className="text-wrapper-7">&nbsp;&nbsp;</span>
            {t("G037")}
          </div>

          <div className="goal-action" onClick={handleDeleteGoal}>
            {" "}
            <FontAwesomeIcon icon={faTrashCan} className="icon" />{" "}
            <span className="text-wrapper-7">&nbsp;&nbsp;</span>
            {t("G044")}
          </div>
        </div>
      </div>
      <div className="goal-middle-line ">
        <div className="goal-date fs-exclude">
          {t("G045")}
          {formatAssessmentDate(singleGoal.date)}
        </div>
      </div>
      <div className="goal-graph-cont ">
        <CircleValueTile
          title={t("G046")}
          value={t("M070")}
          data={singleGoal.daysOnTarget}
          icon={faBullseyeArrow}
          colour={colours.orange}
          width="third"
          modalText={[t("G038")]}
          openModal={openModal}
          withIcon
        />
        <CircleValueTile
          title={t("G047")}
          value={t("M070")}
          data={singleGoal.currentStreak}
          icon={faBolt}
          colour={colours.blue}
          width="third"
          modalText={[t("G039")]}
          openModal={openModal}
          withIcon
        />
        <CircleValueTile
          title={t("G048")}
          value={t("M070")}
          data={singleGoal.bestStreak}
          icon={faCrown}
          colour={colours.green}
          width="third"
          modalText={[t("G040")]}
          openModal={openModal}
          withIcon
        />
        <div className="chart-container goal-half ">
          <div className="title-container">
            <div className="title">{t("G049")}</div>
          </div>
          <div className="chart-rectangle">
            <div className="goal-grey-box">
              <FontAwesomeIcon icon={faCrosshairs} className="icon goals" />
              <div className="goal-grey-box-text">{t("G005")}</div>
              <Link to={`/${singleGoal.url}/track`}>
                <div className="goal-grey-box-button">{t("G052")}</div>
              </Link>
            </div>
            <div className="goal-grey-box">
              <FontAwesomeIcon icon={faLightbulb} className="icon goals" />
              <div className="goal-grey-box-text">{t("G007")}</div>
              <Link to={`/${singleGoal.url}/library`}>
                <div className="goal-grey-box-button">{t("G008")}</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="chart-container goal-half ">
          <div className="title-container">
            <div className="title">{t("G050")}</div>
            <Link to={`/${singleGoal.url}/library`}>
              <div className="goal-see-more">{t("G051")}</div>
            </Link>
          </div>
          <div className="media-container goals">
            {singleGoal.media.length > 0 &&
              singleGoal.media.map((item, index) => (
                <ContentItem key={index} slideContent={item} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingularGoal;
