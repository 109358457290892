import React, { useEffect, useState } from "react";

import "./DropDownRegistration.css";

import ModalTrigger from "../../shared/modal/ModalTrigger";

function DropDownRegistration(props) {
  const [dropDownTitle, setDropDownTitle] = useState("");
  const [focus, setFocus] = useState("");

  useEffect(() => {
    if (props.activeItem) {
      setDropDownTitle(props.activeItem.replace(/&amp;/g, "&"));
    }
  }, [props.activeItem]);

  const handleChange = (event) => {
    setDropDownTitle(event.target.value);
    props.setActiveItem(event.target.value, event.target.id);
  };

  return (
    <div className="rdrop_down_wrapper">
      {props.wtfText && (
        <ModalTrigger
          modalText={props.wtfText}
          onOpenModal={props.onOpenModal}
          registration
          question={props.placeholder?.toLowerCase()?.replace(/ /g, "_")}
        />
      )}
      <div className={"custom_select " + focus}>
        <select
          value={dropDownTitle}
          onChange={handleChange}
          onFocus={() => setFocus("focus")}
          onBlur={() => setFocus("")}
        >
          <option disabled={true} value="" className="disabled">
            {props.placeholder}
          </option>
          {props.data &&
            props.data.map((item, index) => (
              <option
                key={index}
                id={item.id}
                value={item.title}
                className="r_drop_down_content"
              >
                {item.title.replace(/&amp;/gi, "&")}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default DropDownRegistration;
