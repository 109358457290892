import { AccountDetailsRightMenu } from "./accountDetails/AccountDetailsRightMenu";
import { RemoveAccountRightMenu } from "./children/RemoveAccountRightMenu";
import { RemindersRightMenu } from "./children/RemindersRightMenu";
import { TrackersRightMenu } from "./children/TrackersRightMenu";
import { LanguagesRightMenu } from "./languageDetails/LanguagesRightMenu";
import { ChangePasswordRightMenu } from "./securityDetails/ChangePasswordRightMenu";
import { ChangePasscodeRightMenu } from "./securityDetails/ChangePasscodeRightMenu";

export const ProfileSettingsRightPanel = ({
  activeMenu,
  activeSubMenu,
  profile,
  saveChanges,
  changeMenu,
  isHidden,
}) => {
  return (
    <aside className={`menu-right ${isHidden ? "simplify" : ""}`}>
      {activeMenu?.id === 41 && activeSubMenu?.id === 51 && (
        <AccountDetailsRightMenu
          hide={isHidden}
          changeMenu={changeMenu}
          profile={profile}
          saveChanges={saveChanges}
        />
      )}
      {activeMenu?.id === 42 && activeSubMenu?.id === 52 && (
        <ChangePasswordRightMenu
          hide={isHidden}
          profile={profile}
          changeMenu={changeMenu}
          saveChanges={saveChanges}
        />
      )}
      {activeMenu?.id === 42 && activeSubMenu?.id === 53 && (
        <ChangePasscodeRightMenu
          hide={isHidden}
          profile={profile}
          changeMenu={changeMenu}
          saveChanges={saveChanges}
        />
      )}
      {activeMenu?.id === 41 && activeSubMenu?.id === 54 && (
        <RemoveAccountRightMenu hide={isHidden} changeMenu={changeMenu} />
      )}
      {activeMenu?.id === 43 && activeSubMenu?.id === 55 && (
        <RemindersRightMenu hide={isHidden} changeMenu={changeMenu} />
      )}
      {activeMenu?.id === 43 && activeSubMenu?.id === 56 && (
        <TrackersRightMenu hide={isHidden} changeMenu={changeMenu} />
      )}
      {activeMenu?.id === 41 && activeSubMenu?.id === 57 && (
        <LanguagesRightMenu
          hide={isHidden}
          changeMenu={changeMenu}
          profile={profile}
          saveChanges={saveChanges}
        />
      )}
    </aside>
  );
};
