import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./MainHeader.css";
import LogoImg from "./images/white_mountains.png";
import LogoImgColour from "./images/colour_mountains.png";
import UserBgImg from "./images/profile_1.png";

import { AuthContext } from "../context/auth-context";
import NotificationsList from "./NotificationsList";

function MainHeader(props) {
  const [profileImage, setProfileImage] = useState(UserBgImg);
  const [openNotificationsList, setOpenNotificationsList] = useState(false);
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.image) {
      setProfileImage(auth.image);
    }
  }, [auth.image]);

  return (
    <React.Fragment>
      {openNotificationsList && <NotificationsList />}
      {props.page !== "register" && (
        <a tabIndex={0} href="/">
          <span className="visuallyHidden">{t("SD001")}</span>
          {props.page !== "company" && (
            <img src={LogoImg} alt="Champion Health" />
          )}
          {props.page === "company" && (
            <img src={LogoImgColour} alt="Champion Health" />
          )}
        </a>
      )}
      {auth.isLoggedIn && (
        <div tabIndex={0} className="header-right">
          <Link to="/profile/settings">
            <span className="visuallyHidden">{t("SD001")}</span>
            <div
              id="photo_outer"
              className="fs-exclude"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainHeader;
