import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../shared/header/MainHeader";
import { NavStickyBar } from "../shared/navStickyBar/NavStickyBar";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import { ProfileSettings } from "./profileSettings/ProfileSettings";
import { ProfileStats } from "./profileStats/ProfileStats";
import { Invite } from "./profileFnF/Invite";
import { ProfileTabNavigation } from "./profileTabNavigation/ProfileTabNavigation";
import { navigation } from "./profileTabNavigation/navigation";

import "./styles/Profile.css";

const MOBILE_WIDTH = 1240;

export const Profile = () => {
  const { isLoading } = useHttpClient();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [activePage, setActivePage] = useState();
  const [isHidden, setIsHidden] = useState(window.innerWidth < MOBILE_WIDTH);

  useEffect(() => {
    const handleResize = () => setIsHidden(window.innerWidth < MOBILE_WIDTH);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!activePage) {
      setActivePage(
        navigation.find(
          (item) => item.title === window.location.pathname.split("/")[2]
        )
      );
    }
  }, [activePage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const renderPageContent = () => {
    switch (activePage?.target) {
      case navigation[0].target:
        return <ProfileSettings isHidden={isHidden} />;
      case navigation[1].target:
        return <ProfileStats hide={isHidden} />;
      case navigation[2].target:
        return <Invite hide={isHidden} />;
      default:
        return null;
    }
  };

  return (
    <div id="main_container" className="foryou health-area platform glowrm">
      {isLoading && <LoadingSpinner asOverlay />}
      {auth.isLoggedIn && (
        <>
          <div id="nav_container">
            <Header />
          </div>
          <header className="heading">
            <h2>{t("S002")}</h2>
          </header>
          {activePage && (
            <ProfileTabNavigation
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )}
          <main className="page-container">{renderPageContent()}</main>
          <NavStickyBar />
        </>
      )}
    </div>
  );
};
