import { useTranslation } from "react-i18next";
import "../styles/RecipeStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

export const Recipe = ({ recipe }) => {
  const { t } = useTranslation();

  return (
    <div className="recipe">
      <div className="container ingredients">
        <div className="title">
          <h3>{t("M036")}</h3>
          <h4>{`${t("M043")} ${recipe.serves}`}</h4>
        </div>
        <div className="body">
          <div className="fade"></div>
          <ul className="list">
            {recipe.ingredients?.map((item, index) => {
              return (
                <li className="item" key={index + "ingr"}>
                  <FontAwesomeIcon icon={faCheckCircle} className="check" />
                  <div className="step">{item}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="container method">
        <div className="title">
          <h3>{t("M041")}</h3>
          <h4> </h4>
        </div>
        <div className="body">
          <div className="fade"></div>
          <div className="list">
            {recipe.method?.map((item, index) => {
              return (
                <div className="item" key={index + "method"}>
                  <div className="number">{index + 1}</div>
                  <div className="step">{item.replace(/^\d+\.\s/, "")}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container nutrition">
        <div className="title">
          <h3>Nutrition</h3>
          <h4>{t("M042")}</h4>
        </div>
        <span className="visuallyHidden">
          {`${recipe.kcal} ${t("M044")}, ${recipe.carbs}g ${t("M045")}, ${
            recipe.fat
          }g ${t("M046")}, ${recipe.protein}g ${t("M047")}`}
        </span>
        <div className="body" aria-hidden={true}>
          <div className="nutri-info">
            <h4 className="kcal">{t("M044")}</h4>
            <div>{recipe.kcal}</div>
          </div>
          <div className="nutri-info">
            <h4 className="carbs">{t("M045")}</h4>
            <div>{recipe.carbs}g</div>
          </div>
          <div className="nutri-info">
            <h4 className="fat">{t("M046")}</h4>
            <div>{recipe.fat}g</div>
          </div>
          <div className="nutri-info">
            <h4 className="protein">{t("M047")}</h4>
            <div>{recipe.protein}g</div>
          </div>
        </div>
      </div>
    </div>
  );
};
