import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import { ProgressBars } from "./ProgressBars";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { AuthContext } from "../../shared/context/auth-context";

export const Footer = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [lastPage, setLastPage] = useState(false);
  const [scrollControlIcon, setScrollControlIcon] = useState(faChevronDown);

  const navigateNextPage = () => {
    setScrollControlIcon(faChevronDown);
    props.onNextPage();
    setScrollControlIcon("down");
  };

  const navigatePrevPage = () => {
    setScrollControlIcon(faChevronDown);
    props.onPrevPage();
    setScrollControlIcon("down");
  };

  const scrollToBottom = () => {
    let containerHeight =
      document.getElementsByClassName("question_container")[0].clientHeight -
      200;
    if (scrollControlIcon === "down") {
      window.scroll({
        top: containerHeight,
        behavior: "smooth",
      });
      setScrollControlIcon("up");
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      setScrollControlIcon("down");
    }
  };

  useEffect(() => {
    if (props.iProgress) {
      if (
        props.iProgress === 13 &&
        auth?.access === "2" &&
        props.goals?.length > 0
      ) {
        setLastPage(true);
      } else if (
        props.goals?.length > 0 &&
        props.iProgress === 15 &&
        auth.access !== "10"
      ) {
        setLastPage(true);
      } else if (props.iProgress === 16) {
        setLastPage(true);
      } else {
        setLastPage(false);
      }
    }
  }, [props.iProgress]);

  return (
    <footer className="main_footer">
      <div id="scroll_fade_bottom">
        <button id="down_icon" onClick={scrollToBottom}>
          <span className="visuallyHidden">
            {t("FTR001")}
            {scrollControlIcon === "up" ? t("FTR003") : t("FTR002")}
          </span>
          <FontAwesomeIcon
            icon={scrollControlIcon === "up" ? faChevronUp : faChevronDown}
            className="icon"
          />
        </button>
      </div>
      <div id="progress_footer_container">
        <div id="progress_footer" className="progress_footer">
          {props.onPrevPage && (
            <button id="back" onClick={navigatePrevPage}>
              <span className="visuallyHidden">{t("A136")}</span>
              <FontAwesomeIcon icon={faChevronLeft} className="icon" />
            </button>
          )}
          <ProgressBars
            sectionNo={props.sectionNo}
            sectionMax={props.sectionMax}
          />
          {props && props.onNextPage && (
            <button
              id="next"
              className={
                lastPage
                  ? "last_page"
                  : props.iProgress
                  ? "i" + props.iProgress
                  : "no_progress"
              }
              onClick={navigateNextPage}
            >
              {!lastPage && <span className="visuallyHidden">{t("A133")}</span>}
              {lastPage ? (
                <span id="generate_report">
                  {t("A116")}
                  <br />
                  {t("A117")}
                </span>
              ) : (
                <FontAwesomeIcon icon={faChevronRight} className="icon" />
              )}
            </button>
          )}
        </div>
      </div>
    </footer>
  );
};
