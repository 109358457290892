import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import "./Carousel.css";
import CarouselItem from "./CarouselItem";
import { getImagesItems } from "../../shared/util/getImagesItems";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation();
  const translatedImagesList = getImagesItems(t);

  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState(translatedImagesList);
  const [activeItem, setActiveItem] = useState(translatedImagesList[0]);
  const [margin, setMargin] = useState();
  const [left, setLeft] = useState();

  const recalculateWidth = useCallback(() => {
    const container = document.getElementById("left_container");
    if (container) {
      const width = container.clientWidth;
      const indent = (860 - width) / 2;
      const indent2 = width / 2 - 75;
      setMargin(-indent);
      setLeft(indent2);
    }
  }, []);

  useLayoutEffect(() => {
    recalculateWidth();
    window.addEventListener("resize", recalculateWidth);
    return () => window.removeEventListener("resize", recalculateWidth);
  }, [recalculateWidth]);

  const updateIndex = useCallback((newIndex) => {
    const normalizedIndex = (newIndex + items.length) % items.length;
    setActiveIndex(normalizedIndex);
    setActiveItem(items[normalizedIndex]);
  }, [items]);

  useEffect(() => {
    const timer = setTimeout(() => updateIndex(activeIndex + 1), 6000);
    return () => clearTimeout(timer);
  }, [activeIndex, updateIndex]);

  return (
    <div className="carousel_wrapper" role="region" aria-label={t("Carousel region")}>
      <div className="indicators" style={{ left: `${left}px` }}>
        {items.map((_, index) => (
          <div
            key={index}
            className={index === activeIndex ? "active" : ""}
            onClick={() => updateIndex(index)}
            role="button"
            aria-label={`${t("Jump to")} ${items[index].name}`}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                updateIndex(index);
              }
            }}
          />
        ))}
      </div>
      <div className="carousel">
        <div className="inner" style={{ marginLeft: margin }}>
          <CarouselItem
            key={activeIndex}
            activeItem={activeItem}
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
