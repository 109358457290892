import "./WhitePopUpStyles.css";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/sharp-regular-svg-icons";
import DarkButton from "../../components/styledComponents/darkButton/DarkButton";

const WhitePopUpNoTitle = ({ popupContents, ctaBtn, handleSubmit }) => {
  const modalRef = useRef(null);
  const firstFocusableRef = useRef(null);
  const lastFocusableRef = useRef(null);

  // Focus trap + zamknięcie Escape
  useEffect(() => {
    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (focusableElements.length > 0) {
      firstFocusableRef.current = focusableElements[0]; // Pierwszy element do focusu
      lastFocusableRef.current =
        focusableElements[focusableElements.length - 1]; // Ostatni element
      firstFocusableRef.current.focus();
    }

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleSubmit();
      } else if (e.key === "Tab") {
        if (
          e.shiftKey &&
          document.activeElement === firstFocusableRef.current
        ) {
          e.preventDefault();
          lastFocusableRef.current.focus();
        } else if (
          !e.shiftKey &&
          document.activeElement === lastFocusableRef.current
        ) {
          e.preventDefault();
          firstFocusableRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleSubmit]);

  return (
    <div
      className="popup-overlay"
      role="dialog"
      aria-modal="true"
      ref={modalRef}
    >
      <div className="popup-container results">
        <div className="popup-title">{popupContents.title}</div>
        <div className="popup-body">
          <div className="popup-text-container">
            {popupContents.text.map((item, index) => (
              <div
                key={index}
                className={index === 1 ? "mid-heading" : ""}
                dangerouslySetInnerHTML={{
                  __html: item.replace(/\n/g, "<br />"),
                }}
              ></div>
            ))}
          </div>
        </div>
        <div className="popup-cta single">
          <DarkButton
            className="wb"
            onClick={handleSubmit}
            ref={lastFocusableRef}
          >
            {ctaBtn} <FontAwesomeIcon icon={faTimes} className="cta-icon" />
          </DarkButton>
        </div>
      </div>
    </div>
  );
};

export default WhitePopUpNoTitle;
