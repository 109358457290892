import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import { ContentCover } from "../components/ContentCover";
import { useFetchProgrammeQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setProgramme } from "../mediaSlice";

export const ProgrammeCoverWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[2];

  const programme = useSelector((state) => state.media.programme);

  const {
    data: media,
    isFetching,
    refetch,
  } = useFetchProgrammeQuery(
    {
      token: auth.token,
      chid: chid,
    },
    {
      skip: !chid,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isFetching && media) {
      if (
        media.favourite !== programme.favourite ||
        programme.chid !== chid ||
        media.progress !== programme.progress ||
        media.like !== programme.like ||
        media.dislike !== programme.dislike
      ) {
        dispatch(setProgramme(media));
      }
    }
  }, [dispatch, media, isFetching]);

  return (
    <div id="cover-page">
      {isFetching ? (
        <LoadingSpinner asOverlay />
      ) : (
        <ContentCover mediaObject={programme} />
      )}
    </div>
  );
};
