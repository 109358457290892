export const formatDate = (inputDate) => {
  let date, month, year;
  if (inputDate instanceof Date) {
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");

    return `${year}-${month}-${date}`;
  }
};

export const convertTime = (duration) => {
  if (duration < 60) {
    return `${duration}min`;
  } else {
    if (duration % 60 === 0) {
      return `${duration / 60}h`;
    } else {
      return `${Math.floor(duration / 60)}h ${duration % 60}min `;
    }
  }
};

export const formatAssessmentDate = (inputDate) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateRegex = /^\d{2}-\d{2}-\d{4}$/;

  if (dateRegex.test(inputDate)) {
    const [day, month, year] = inputDate.split("-");
    inputDate = `${month}/${day}/${year}`;
  }
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const timeConversion = (minutes) => {
  // Up to 120 minutes, return in minutes
  if (minutes <= 120) {
    return { id: "Minutes", value: minutes };
  }

  // Up to 24 hours, return in hours rounded up
  if (minutes <= 1440) {
    return { id: "Hours", value: Math.ceil(minutes / 60) };
  }

  // Beyond 24 hours, return in days rounded up
  return { id: "Days", value: Math.ceil(minutes / 1440) };
};

export const validateUserInput = (id, value, type) => {
  //switch statement to validate input
  let valid = false;
  let regex = "";
  switch (id) {
    case "name":
      regex = /^[a-zA-Z0-9\s.'-]*$/;
      valid = regex.test(value);
      valid = value.length < 50 && valid;
      return valid;
    case "email":
      regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      valid = regex.test(String(value).toLowerCase());
      return valid;
    case "password":
      let matchedCase = [];
      matchedCase.push("[A-Z]"); // Uppercase Alpabates
      matchedCase.push("[0-9]"); // Numbers
      matchedCase.push("[a-z]"); // Lowercase Alphabates
      // Check the conditions
      let ctr = 0;
      for (let i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(value)) {
          ctr++;
        }
      }
      if (ctr > 2 && value.length > 7) {
        valid = true;
      }
      return valid;

    default:
      break;
  }
};

export const getPeriodInDates = (dateStr, period) => {
  // Create a Date object from the input date string
  const inputDate = new Date(dateStr);

  // Initialize start and end dates
  let startDate;
  let endDate;

  // Determine the period and calculate the start and end dates accordingly
  switch (period.toLowerCase()) {
    case "week":
      startDate = new Date(inputDate);
      endDate = new Date(inputDate);
      // Set the start date to the beginning of the week (Monday)
      startDate.setDate(inputDate.getDate() - inputDate.getDay() + 1);
      // Set the end date to the end of the week (Sunday)
      endDate.setDate(inputDate.getDate() - inputDate.getDay() + 7);
      break;

    case "month":
      startDate = new Date(inputDate);
      endDate = new Date(inputDate);
      // Set the start date to the beginning of the month
      startDate.setDate(1);
      // Set the end date to the last day of the month
      endDate.setMonth(inputDate.getMonth() + 1);
      endDate.setDate(0);
      break;

    case "year":
      startDate = new Date(inputDate);
      endDate = new Date(inputDate);
      // Set the start date to the beginning of the year
      startDate.setMonth(0, 1);
      // Set the end date to the end of the year
      endDate.setMonth(11, 31);
      break;

    default:
      throw new Error("Invalid period specified.");
  }

  return [formatDate(startDate), formatDate(endDate)];
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDateRange = (dateArray, period) => {
  if (dateArray.length !== 2) {
    return "Invalid date array";
  }

  const [startDate, endDate] = dateArray.map((date) => new Date(date));

  const startDay = startDate.getDate();
  const startMonth = monthNames[startDate.getMonth()];
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate();
  const endMonth = monthNames[endDate.getMonth()];
  const endYear = endDate.getFullYear();

  switch (period) {
    case "week":
      if (startMonth === endMonth && startYear === endYear) {
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
      }
      return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
    case "month":
      return `${startMonth} ${startYear}`;
    case "year":
      return `${startYear}`;
    default:
      return "Invalid period";
  }
};

export const checkImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

export const transformSecondsToMinutes = (seconds) => {
  return Math.round(seconds / 60);
};
