const AllowedDomains = [
  "media.championhealth.co.uk",
  "media.championhealth.dev",
];

const rewriteS3Url = (s3Url) => {
  // Parse the URL
  const url = new URL(s3Url);

  // Check if the URL is an S3 URL
  const s3Pattern = /^s3\.[a-z0-9-]+\.amazonaws\.com$/;
  if (s3Pattern.test(url.hostname)) {
    // Extract the bucket name from the first part of the pathname
    const [, customDomain, ...remainingPath] = url.pathname.split("/");

    // Check if customDomain matches any of the allowed domains
    if (AllowedDomains.includes(customDomain)) {
      // Reconstruct the new URL
      return `https://${customDomain}/${remainingPath.join("/")}`;
    }
  }

  // If previous condintions are not met, return the original URL
  return s3Url;
};

export { rewriteS3Url };
