import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Goals.css";

import { useDispatch, useSelector } from "react-redux";
import { useFetchGoalsQuery } from "./goalsApiSlice";
import { setGoals } from "./goalsSlice";
import { useDeleteGoalMutation } from "./goalsApiSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseyeArrow,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";

import { AuthContext } from "../shared/context/auth-context";
import { colours } from "../assets/colours";
import Header from "../shared/header/MainHeader";
import { NavStickyBar } from "../shared/navStickyBar/NavStickyBar";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import SingularGoal from "./children/SingularGoal";
import GoalSelector from "./children/GoalSelector";

const Goals = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const goals = useSelector((state) => state.goals.goals);
  const [deleteGoalAPI] = useDeleteGoalMutation();
  const {
    data: goalsData,
    isLoading: isFetchingGoals,
    refetch,
  } = useFetchGoalsQuery(auth.token);
  const dispatch = useDispatch();

  const [openGoalSelector, setOpenGoalSelector] = useState(false);

  const handleCloseGoalSelector = () => {
    setOpenGoalSelector(false);
  };

  const handleOpenGoalSelector = () => {
    setOpenGoalSelector(true);
  };

  useEffect(() => {
    if (!isFetchingGoals && goalsData) {
      dispatch(setGoals(goalsData));
    }
  }, [dispatch, goalsData, isFetchingGoals]);

  useEffect(() => {
    refetch();
  }, []);

  const confirmDeleteGoal = (oldGoal) => {
    deleteGoalAPI({
      token: auth.token,
      goalId: oldGoal.id,
    }).catch((err) => {
      console.log(err);
    });
  };

  const pageScroll = useCallback((item) => {
    const topOfEl = document.getElementById(`goal-${item.id}`).offsetTop - 150; //take away the header height
    if (topOfEl >= 0) {
      window.scroll({ top: topOfEl, behavior: "smooth" });
    } else {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <div id="main_container" className="foryou health-area platform">
      {isFetchingGoals && <LoadingSpinner asOverlay />}
      {openGoalSelector && (
        <GoalSelector handleClose={handleCloseGoalSelector} />
      )}
      {auth.isLoggedIn && (
        <React.Fragment>
          <div id="nav_container">
            <Header />
            <div className="heading">
              <h2>{t("G002")}</h2>
            </div>
          </div>
          <ul className="nav_slider na goals">
            {goals?.length > 0 &&
              goals?.map((item, index) => {
                return (
                  <li
                    className="fs-exclude"
                    key={index}
                    id={index}
                    onClick={() => {
                      pageScroll(item);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBullseyeArrow}
                      className="icon"
                      style={{ color: colours.orange }}
                    />{" "}
                    <span className="text-wrapper-7">&nbsp;&nbsp;</span>
                    {t(item.title)}
                  </li>
                );
              })}
            {goals?.length < 3 && (
              <li
                key={goals?.length}
                onClick={() => handleOpenGoalSelector()}
                className="fs-exclude"
              >
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />{" "}
                <span className="text-wrapper-7 ">&nbsp;&nbsp;</span>
                {t("G043")}
              </li>
            )}
          </ul>
          <div className="goals-container">
            {goals?.length > 0 &&
              goals?.map((goal, index) => (
                <SingularGoal
                  goal={goal.id}
                  key={index}
                  handleOpenGoalSelector={handleOpenGoalSelector}
                  confirmDeleteGoal={confirmDeleteGoal}
                />
              ))}
          </div>
          <NavStickyBar />
        </React.Fragment>
      )}
    </div>
  );
};
export default Goals;
