import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../../shared/hooks/http-hook";

import { Slider } from "../../../shared/components/slider/Slider";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

function Page(props) {
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const pageid = window.location.pathname.split("/")[2];
  const [slidersList, setSlidersList] = useState();

  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/media/sliders/leadershiphub`
        );
        if (responseData.sliders) {
          setSlidersList(responseData.sliders);
        }
      } catch (err) {}
    };
    if (!pageid) return;
    fetchSliders();
  }, [pageid]);

  //scroll top on page change
  useEffect(() => {
    if (!pageid || slidersList) return;
    if (sessionStorage.length > 0) {
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );
      setTimeout(() => {
        if (previousLocation) {
          window.scrollTo(0, previousLocation.scrollY);
          sessionStorage.clear();
        } else {
          window.scrollTo(0, 0);
        }
      }, 50);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pageid, slidersList]);

  return (
    <div className="report-section active ">
      <h2 className="main_title">{t("LL001")}</h2>
      <div id="leaders_lab_sliders" className="results-content">
        {!slidersList && <LoadingSpinner asOverlay />}

        {pageid && slidersList && (
          <React.Fragment>
            {slidersList.map((slider) => {
              if (slider.type === "slider") {
                return (
                  <Slider
                    title={slider.title}
                    key={slider.id}
                    id={slider.id}
                    media={slider.media}
                    target={slider.target || false}
                    sliderLength={4}
                  />
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
export default Page;
