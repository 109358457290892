import React from "react";

import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
  return (
    <div
      tabIndex={-1}
      className={`${
        props.asOverlay
          ? "loading-spinner__overlay"
          : props.asImage
          ? "image-cont"
          : "center"
      }`}
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default LoadingSpinner;
