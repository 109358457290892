import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Backdrop from "../menu/Backdrop";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

function Modal(props) {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const modalRef = useRef(null);
  const pageContents = {
    sub_title: t("MH003"),
    title: t("MH004"),
    text1: t("MH005"),
    text2: t("MH006"),
    buttonText: t("MH007"),
  };

  function modalClickHandler() {
    props.modalClickHandler();
  }

  useEffect(() => {
    if (props.modalIsOpen) {
      buttonRef.current?.focus();
    }
  }, [props.modalIsOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!props.modalIsOpen) return;
      if (event.key === 'Tab') {
        event.preventDefault();
        buttonRef.current?.focus();
      }
      if (event.key === 'Escape') {
        modalClickHandler();
      }
    };
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [props.modalIsOpen]);

  return (
    <Backdrop
      open={`${props.modalIsOpen ? "open" : "closed"}`}
      onBackdropClose={modalClickHandler}
    >
      <div
        ref={modalRef}
        id="modal-container-onboarding"
        className={` ${props.modalIsOpen ? "open" : "closed"}`}
      >
        <div id="mentalwellbeing_image" className="left_side_onboarding"></div>
        <div className="right_side_onboarding">
          <h3>{pageContents.sub_title}</h3>
          <h1>{pageContents.title}</h1>
          <p>{pageContents.text1}</p>
          <p>{pageContents.text2}</p>
          <button 
            id="mentalwellbeing-btn" 
            onClick={modalClickHandler}
            ref={buttonRef}
          >
            {pageContents.buttonText}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </button>
        </div>
      </div>
    </Backdrop>
  );
}
export default Modal;
