import { TFunction } from "i18next";

type ImageItem = {
  src: string;
  alt: string;
};

type ImageGroup = {
  name: string;
  images: ImageItem[];
};

const workouts = [
  { filename: "H_WO_07" }, { filename: "H_WO_13" }, { filename: "H_WO_24" },
  { filename: "H_WO_25" }, { filename: "H_WO_26" }, { filename: "H_WO_27" },
  { filename: "H_WO_29" }, { filename: "H_WO_32" }, { filename: "H_WO_40" },
];

const soundscapes = [
  { filename: "H_SO_06" }, { filename: "H_SO_07" }, { filename: "H_SO_08" },
  { filename: "H_SO_14" }, { filename: "H_SO_19" }, { filename: "H_SO_21" },
  { filename: "H_SO_30" }, { filename: "H_SO_34" }, { filename: "H_SO_36" },
];

const sleepstories = [
  { filename: "H_SS_01_F" }, { filename: "H_SS_02" }, { filename: "H_SS_03" },
  { filename: "H_SS_10" }, { filename: "H_SS_11" }, { filename: "H_SS_12" },
  { filename: "H_SS_13" }, { filename: "H_SS_14" }, { filename: "H_SS_15" },
];

const recipes = [
  { filename: "H_R_07" }, { filename: "H_R_25" }, { filename: "H_R_26" },
  { filename: "H_R_27" }, { filename: "H_R_40" }, { filename: "H_R_46" },
  { filename: "H_R_50" }, { filename: "H_R_56" }, { filename: "H_R_74" },
];

const meditations = [
  { filename: "H_M_05" }, { filename: "H_M_08" }, { filename: "H_M_11" },
  { filename: "H_M_20" }, { filename: "H_VI_02" }, { filename: "H_VI_03" },
  { filename: "H_VI_05" }, { filename: "H_VI_06" }, { filename: "H_VI_07" },
];

const masterclasses = [
  { filename: "A_MH_EX_02" }, { filename: "A_MH_SE_04" }, { filename: "A_NU_SE_01" },
  { filename: "A_PH_SE_02" }, { filename: "A_PR_EX_05" }, { filename: "A_PR_EX_07" },
  { filename: "A_PR_SE_01" }, { filename: "A_SL_EX_02" }, { filename: "A_SL_SE_01" },
];

const generateImageData = (category, folder, t) =>
  category.map(item => ({
    src: require(`../../pages/images/${folder}/${item.filename}.jpg`),
    alt: t(item.filename),
  }));

export const getImagesItems = (t: TFunction): ImageGroup[] => 
  [
    { name: t("R003"), images: generateImageData(workouts, "workouts", t) },
    { name: t("R007"), images: generateImageData(soundscapes, "soundscapes", t) },
    { name: t("R006"), images: generateImageData(sleepstories, "sleepstories", t) },
    { name: t("R004"), images: generateImageData(recipes, "recipes", t) },
    { name: t("R005"), images: generateImageData(meditations, "meditations", t) },
    { name: t("R002"), images: generateImageData(masterclasses, "masterclasses", t) },
  ];

