import React from "react";
import { useTranslation } from "react-i18next";
import "./Modal.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

function ModalTrigger(props) {
  const { t } = useTranslation();
  function openModal(event) {
    if (props.registration) {
      event.preventDefault();
    }
    props.onOpenModal(props.modalText);
  }

  return (
    <button onClick={openModal} id={`modalTrigger_${props.question}`} className="modal_trigger_container">
      <span className="visuallyHidden">{t("MDL001")}</span>
      {props.registration ? (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="rdrop_down_wtf icon"
        />
      ) : (
        <FontAwesomeIcon icon={faInfoCircle} className="icon modal_trigger" />
      )}
    </button>
  );
}
export default ModalTrigger;
