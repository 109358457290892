import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  faBoltLightning,
  faChartLineDown,
  faChartLineUp,
  faDash,
  faFaceSmileBeam,
  faMartiniGlass,
  faMoonStars,
  faPersonSkiing,
  faSalad,
  faWind,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBatteryFull,
  faDroplet,
  faLightbulb,
} from "@fortawesome/sharp-regular-svg-icons";

export const useInfoTile = (tileContents) => {
  const [icon, setIcon] = useState(null);
  const [colour, setColour] = useState(null);
  const [comment, setComment] = useState(null);
  const [trackerIcon, setTrackerIcon] = useState(null);
  const goals = useSelector((state) => state.goals.goals);

  useEffect(() => {
    if (!tileContents) return;

    const goal = goals.find((goal) => goal.title === tileContents.title);
    if (!goal) return;

    if (tileContents.previousValue < tileContents.currentValue) {
      setIcon(faChartLineUp);
      setColour("green");
      setComment(goal.improves);
    } else if (tileContents.previousValue > tileContents.currentValue) {
      setIcon(faChartLineDown);
      setColour("red");
      setComment(goal.worsens);
    } else {
      setIcon(faDash);
      setColour("blue");
      setComment(goal.maintains);
    }

    const trackerIcons = {
      energy: faBatteryFull,
      hydration: faDroplet,
      productivity: faLightbulb,
      stress: faBoltLightning,
      sleep: faMoonStars,
      mood: faFaceSmileBeam,
      activity: faPersonSkiing,
      smoking: faWind,
      alcohol: faMartiniGlass,
      nutrition: faSalad,
    };
    setTrackerIcon(trackerIcons[tileContents.id] || null);
  }, [tileContents, goals]);

  return { icon, colour, comment, trackerIcon };
};
