import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../shared/context/auth-context";
import { navigation } from "./navigation";

import "./ProfileTabNavigation.css";

const TAB_IDS = {
  INVITE: 2,
  ASSESSMENT: 3,
  LEADERSHIP_HUB: 4,
};

const USER_ACCESS = {
  FRIEND_FAMILY: "2",
  LEADER: "3",
  ADMIN: "10",
};

export const ProfileTabNavigation = ({ activePage, setActivePage }) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggleActivePage = (tabId) => {
    const navigationPaths = {
      [TAB_IDS.ASSESSMENT]: "/assessment",
      [TAB_IDS.LEADERSHIP_HUB]: "/company-report/live-analytics",
    };

    if (navigationPaths[tabId]) {
      navigate(navigationPaths[tabId]);
    } else {
      setActivePage(navigation[tabId]);
      navigate(`/profile/${navigation[tabId].title}`);
    }
  };

  const isUserFriendFamily = auth.access === USER_ACCESS.FRIEND_FAMILY;
  const isUserAdmin = auth.access === USER_ACCESS.ADMIN;
  const isUserLeader = auth.access === USER_ACCESS.LEADER;
  const hasTags = auth.tags.length > 0;
  const isUserHub = auth.isHub;

  const shouldHideTab = (index) => {
    if (index === TAB_IDS.INVITE && (isUserFriendFamily || isUserHub))
      return true;
    if (index === TAB_IDS.ASSESSMENT && !(isUserAdmin || !hasTags)) return true;
    if (
      index === TAB_IDS.LEADERSHIP_HUB &&
      (isUserHub || !(isUserLeader || isUserAdmin))
    )
      return true;
    return false;
  };

  return (
    <nav id="profile_tab_navigation" className="tabs_container">
      {navigation.map((item, index) => {
        if (shouldHideTab(index)) return null;

        return (
          <button
            key={item.target}
            id={item.fullstory}
            className={`tab ${
              activePage?.target === item.target ? "active" : ""
            }`}
            onClick={() => toggleActivePage(index)}
          >
            {t(item.code)}
          </button>
        );
      })}
    </nav>
  );
};
