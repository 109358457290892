import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/auth-context";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "./UIElements/LoadingSpinner";

const ProtectedRoute = ({ element: Component, tags = undefined, ...rest }) => {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const valid = await validToken();
      setIsValid(valid);
      setIsLoading(false);
    };

    checkToken();
  }, []);

  const validToken = async () => {
    let now = new Date().getTime();
    let userData = JSON.parse(localStorage.getItem("userData"));
    let expiry = userData && new Date(userData.expiration).getTime();
    if (!expiry) {
      return false;
    } else if (now > expiry) {
      auth.logout();
      return false;
    } else {
      return true;
    }
  };

  if (isLoading) {
    return <LoadingSpinner asOverlay />;
  }

  return isValid ? (
    tags === undefined || tags.length > 0 ? (
      <Component {...rest} />
    ) : (
      <Navigate to="/assessment" />
    )
  ) : (
    <Navigate to="/code" state={{ from: window.location.pathname }} />
  );
};

export default ProtectedRoute;
