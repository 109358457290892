import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function Dehydration(props) {
  const { t } = useTranslation();
  const rows = [
    { index: 0, value: 1, description: t("A145") },
    { index: 1, value: 2, description: t("A146") },
    { index: 2, value: 3, description: t("A147") },
    { index: 3, value: 4, description: t("A148") },
    { index: 4, value: 5, description: t("A149") },
    { index: 5, value: 6, description: t("A150") },
    { index: 6, value: 7, description: t("A151") },
    { index: 7, value: 8, description: t("A152") },
  ];

  const [radioOption, setRadioOption] = useState();

  function saveValue(event) {
    const value = event.target.value;
    if (!value) return;
    setRadioOption(value);
    props.onSetValue({ id: props.questionid, value });
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid] && !radioOption) {
      setRadioOption(props.assessmentValues[props.questionid]);
    }
  });

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      <div className="answer_block fs-exclude">
        <div
          className={"dot_radio dehydration " + props.questionid}
          id={props.questionid}
        >
          {rows.map((option) => {
            let checked;
            {
              parseFloat(radioOption) === parseFloat(option.value)
                ? (checked = "checked")
                : (checked = "");
            }
            return (
              <div
                className="input_wrap"
                key={option.index}
              >
                <input
                  type="radio"
                  id={props.questionid + option.value}
                  name={props.questionid}
                  value={option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    saveValue(e);
                  }}
                  readOnly
                  checked={checked}
                  aria-labelledby={`${props.questionid}_${option.value}_label`}
                />
                <label htmlFor={props.questionid + option.value} aria-hidden id={`${props.questionid}_${option.value}_label`}>
                  <span className="visuallyHidden">
                    {option.description}
                  </span>
                </label>
                <div aria-hidden>
                  {option.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Dehydration;
