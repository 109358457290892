import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Style.css";

import Header from "../../shared/header/MainHeader";
import { NavStickyBar } from "../../shared/navStickyBar/NavStickyBar";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useMedia } from "../../shared/hooks/media-hook";
import { ContentItem } from "../../shared/components/slider/contentItem/ContentItem";
import WhiteVideoPopUp from "../../shared/modal/whitePopUp/WhiteVideoPopup";
import { Slider } from "../../shared/components/slider/Slider";

const Authors = () => {
  const { sendRequest } = useHttpClient();
  const { openMediaModal } = useMedia();

  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const authorid = useParams().authorid;
  const authorName =
    authorid && authorid.split("_").length > 1 ? authorid.split("_")[0] : "";

  const [author, setAuthor] = useState(false);
  const [media, setMedia] = useState();
  const [featuredContent, setFeaturedContent] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getAuthor = async () => {
    if (authorid && auth.userId && auth.token) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/authors/${authorid}`
        );
        if (responseData.author) {
          setAuthor(responseData.author);
          setMedia(responseData.media);
          if (responseData.featuredContent) {
            setFeaturedContent(responseData.featuredContent);
          }
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (authorid && !author && auth.userId) {
      getAuthor();
      document.body.classList.remove("popup_open");
      window.scroll({
        top: "#nav",
        behavior: "smooth",
      });
    }
  }, [authorid, auth.userId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("pageReload", true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleAuthorVideoClick = () => {
    setOpenModal(true);
  };

  return (
    <div id="main_container" className="foryou glowrm platform authorPage">
      <div id="nav_container" className="individual-report">
        <Header />
      </div>
      {!media && <LoadingSpinner asOverlay />}
      {media && auth.isLoggedIn && author && (
        <div className="page-cont visible">
          <div
            className="results-img"
            style={{
              background: author?.web_background_url
                ? `url(${author.web_background_url}) center top/cover no-repeat`
                : `url('https://res.cloudinary.com/champion-health/image/upload/v1725359707/authors/images/bg%20images/author_default_web.jpg') center top/cover no-repeat`,
            }}
          >
            <div className="gradient-overlay">
              <div className="hero-contents">
                <p className="hero-subtitle">{t("AT001")}</p>
                <div className="hero-container">
                  {!author.web_background_url && author?.profile_icon && (
                    <div
                      className="hero-icon"
                      style={{
                        background: `url(${author?.profile_icon}) center/cover no-repeat`,
                      }}
                    ></div>
                  )}
                  <div className="hero-info-container">
                    <h1 className="hero-title">{author.author}</h1>
                    <h2 className="hero-description">{author.author_title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="author_tiles"
            style={{
              justifyContent: featuredContent ? "space-around" : "flex-end",
            }}
          >
            {featuredContent && (
              <div className="author_featured">
                <div className="author_featured_inner">
                  <ContentItem slideContent={featuredContent} author />
                </div>
              </div>
            )}

            <div className="author_desc_container">
              <div className="author_desc_header">
                <div className="author_desc_header_about">{`${t(
                  "AT002"
                )} ${authorName}`}</div>
                <div className="author_desc_header_additiontal">
                  {author.author_video && (
                    <div
                      className="author_desc_header_video"
                      onClick={handleAuthorVideoClick}
                    ></div>
                  )}
                  {author.author_url && (
                    <div className="author_site">
                      <a
                        href={author.author_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("AT004")}
                      </a>
                    </div>
                  )}
                </div>
              </div>{" "}
              <div className="scrollable">
                <p className="author_desc_info">{author.description}</p>
              </div>
            </div>
          </div>
          {media.length > 1 && (
            <Slider
              title={`${t("AT003")} ${authorName}`}
              key={authorid}
              id={authorid}
              media={media}
              onOpenMediaModal={openMediaModal}
              target={false}
            />
          )}
        </div>
      )}
      <NavStickyBar />
      {openModal && (
        <WhiteVideoPopUp
          title={`${t("AT006")} ${authorName}`}
          video={author.author_video}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};
export default Authors;
