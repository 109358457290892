import { useContext, useEffect, useState } from "react";
import { faBolt, faCrown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useTranslation } from "react-i18next";
import { YourStats } from "./YourStats";

export const ProfileStatsRightMenu = ({ activeSubMenu, hide }) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { sendRequest } = useHttpClient();
  const [userStats, setUserStats] = useState(null);

  useEffect(() => {
    const getStats = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`
        );
        if (responseData) {
          setUserStats({
            currentStreak: responseData.current_days_streak,
            bestStreak: responseData.best_days_streak,
            platformMinutes: responseData.platformMinutes,
            mindfulMinutes: responseData.mindfulnessMinutes,
            activeMinutes: responseData.activeMinutes,
          });
        }
      } catch (err) {}
    };
    if (!userStats && auth.userId) {
      getStats();
    }
  }, [auth.userId, userStats]);

  return (
    <>
      {userStats && (
        <>
          {(activeSubMenu.id === 61 || hide) && (
            <div className="rm-box fs-exclude">
              <div className="profile-name left">{t("S043")}</div>
              <div className="streaks-cont">
                <div className="streak-clmn">
                  <div className="streak-name">{t("S044")}</div>
                  <div className="streak-circle blue">
                    <FontAwesomeIcon icon={faBolt} className="icon" />
                    <div className="profile-name nm">
                      {userStats.currentStreak}
                    </div>
                    <div className="bold-sm-txt">{t("M070")}</div>
                  </div>
                </div>
                <div className="streak-clmn">
                  <div className="streak-name">{t("S045")}</div>
                  <div className="streak-circle green">
                    <FontAwesomeIcon icon={faCrown} className="icon" />
                    <div className="profile-name nm">
                      {userStats.bestStreak}
                    </div>
                    <div className="bold-sm-txt">{t("M070")}</div>
                  </div>
                </div>
              </div>
              <p className="cm">{t("S046")}</p>
            </div>
          )}
          {(activeSubMenu.id === 62 || hide) && (
            <YourStats hide={hide} userStats={userStats} />
          )}
        </>
      )}
    </>
  );
};
