import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";

export const AuthorContainer = ({ author }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="author">
      <section className="author-about" aria-labelledby={`author-heading-${author.author_shortcode}`}>
        <div className="author-img" style={{backgroundImage: `url(${author.author_image})`}}></div>
        <div className="author-bio">
          <h3 id={`author-heading-${author.author_shortcode}`}>{`${t("M020")} ${author.author}`}</h3>
          <p>{author.author_title}</p>
          <p>{author.description}</p>
          <p>
            <Link to={`/authors/${author.author_shortcode}`}>
              {t("M020")} {author.author.split(" ")[0]}{" "}
              <FontAwesomeIcon icon={faArrowRight} className="icon " aria-hidden="true" />
            </Link>
          </p>
        </div>
      </section>
      {author.content?.length > 0 && (
        <div className="library">
          <h2>{t("M073W")}</h2>
          <div className="scrollable">
            {author.content &&
              author.content.map((item, index) => (
                <button
                  className="transparent-button content"
                  key={index}
                  onClick={() =>
                    navigate(
                      `/${
                        author.content[index].category.toLowerCase() ===
                        "masterclass"
                          ? "masterclass"
                          : author.content[index].category.toLowerCase() ===
                            "programme"
                          ? "programme"
                          : "media"
                      }/${author.content[index].chid}/${author.content[
                        index
                      ].title.replace(/ /g, "_")}`
                    )
                  }
                >
                  <div
                    className="content-img"
                    style={{
                      backgroundImage: `url(${author.content[index].thumbnail_image})`,
                    }}
                  />
                  <div className="text">
                    <p
                      className={`text-title ${
                        author.content[index].length ? "" : "text-title-notime"
                      }`}
                    >
                      {author.content[index].title}
                    </p>
                    {author.content[index]?.length && (
                      <p>{convertTime(author.content[index].length)}</p>
                    )}
                    <p>{author.content[index].description}</p>
                  </div>
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
