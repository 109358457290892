import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import { ContentCover } from "../components/ContentCover";
import { useFetchSingleMediaQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setSingleMedia } from "../mediaSlice";

export const SingleMediaCoverWrap = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleMedia = useSelector((state) => state.media.singleMedia);

  const {
    data: media,
    isFetching,
    refetch,
  } = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/media/${location.pathname.split("/")[2]}`,
    },
    {
      skip:
        !location.pathname.split("/")[2] ||
        location.pathname.split("/")[2]?.startsWith("TA"),
    }
  );

  useEffect(() => {
    if (!isFetching && media) {
      if (
        media.favourite !== singleMedia.favourite ||
        singleMedia.chid !== location.pathname.split("/")[2] ||
        media.progress !== singleMedia.progress ||
        media.like !== singleMedia.like ||
        media.dislike !== singleMedia.dislike
      )
        dispatch(setSingleMedia(media));
    }
  }, [dispatch, media, isFetching]);

  useEffect(() => {
    if (
      location.pathname.split("/")[2]?.startsWith("TA") &&
      (!singleMedia || singleMedia.chid !== location.pathname.split("/")[2])
    ) {
      navigate("/for-you");
    } else if (
      location.pathname.split("/")[2]?.startsWith("TA") &&
      singleMedia?.chid === location.pathname.split("/")[2]
    ) {
      return;
    } else {
      refetch();
    }
  }, []);

  return (
    <div id="cover-page">
      {isFetching ? (
        <LoadingSpinner asOverlay />
      ) : (
        <ContentCover mediaObject={singleMedia} />
      )}
    </div>
  );
};
