import { useTranslation } from "react-i18next";

import {
  faClock,
  faMoon,
  faMoonStars,
  faSunBright,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import { Slider } from "../../shared/components/slider/Slider";
import { colours } from "../../assets/colours";
import MotivationTile from "../components/MotivationTile";
import LineChart from "../components/LineChart";
import JournalBlock from "../components/JournalBlock";
import {
  enhancers_sleep,
  inhibitors_sleep,
} from "../../shared/components/data";

const SleepResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;

  return (
    <div className="results-container ">
      {props.showAssessment && assessScores?.hours_sleep_data && (
        <div className="results-row ">
          <LineChart
            title={t("D184")}
            average={assessScores.hours_sleep_data_average}
            data={assessScores.hours_sleep_data}
            icon={faClock}
            units={t("D077")}
            average_type={t("D079")}
            width="half bars"
            color={colours.blue}
            modalText={[t("D185")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
          <LineChart // sleep quality
            title={t("D186")}
            icon={faMoon}
            average={assessScores.quality_of_sleep_data_average}
            data={assessScores.quality_of_sleep_data}
            units={t("D035")}
            yMaxLimit={10}
            average_type={t("D079")}
            width="half bars"
            color={colours.green}
            modalText={[t("D187")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            outOfYMax
          />
          <BarChart
            title={t("D190")}
            color={colours.red}
            data={assessScores.clock_bed_data}
            average={assessScores.clock_bed_data_average}
            icon={faMoonStars}
            units=""
            average_type={t("D079")}
            yMaxLimit={24}
            width="half bars"
            modalText={[t("D191")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            isTime
          />
          <BarChart
            title={t("D192")}
            color={colours.yellow}
            data={assessScores.clock_wake_data}
            average={assessScores.clock_wake_data_average}
            icon={faSunBright}
            units=""
            average_type={t("D079")}
            yMaxLimit={24}
            width="half bars"
            modalText={[t("D193")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            isTime
          />
        </div>
      )}
      {!props.showAssessment && trackerScores?.sleep?.hours_of_sleep && (
        <div className="results-row ">
          <LineChart //hours of sleep
            title={t("D184")}
            color={colours.blue}
            icon={faClock}
            average={trackerScores.sleep.hours_of_sleep_average}
            average_type={t("D079")}
            data={trackerScores.sleep.hours_of_sleep}
            units={t("D077")}
            width="half bars"
            modalText={[t("DW036")]}
            openModal={props.openModal}
          />
          <LineChart
            title={t("D186")}
            data={trackerScores.sleep.quality_of_sleep}
            icon={faMoon}
            average={trackerScores.sleep.quality_of_sleep_average}
            yMaxLimit={10}
            outOfYMax
            units={t("D035")}
            average_type={t("D079")}
            width="half bars"
            color={colours.green}
            modalText={[t("DW037"), t("DW038"), t("DW039")]}
            openModal={props.openModal}
          />
          <BarChart //went to bed
            title={t("D190")}
            average={trackerScores.sleep.clock_bed_average}
            data={trackerScores.sleep.clock_bed}
            average_type={t("D079")}
            yMaxLimit={24}
            icon={faMoonStars}
            units=""
            width="half bars"
            color={colours.red}
            isTime
            modalText={[t("DW040")]}
            openModal={props.openModal}
          />
          <BarChart //woke up
            title={t("D192")}
            average={trackerScores.sleep.clock_wake_average}
            data={trackerScores.sleep.clock_wake}
            icon={faSunBright}
            units=""
            yMaxLimit={24}
            average_type={t("D079")}
            width="half bars"
            color={colours.yellow}
            isTime
            modalText={[t("DW041")]}
            openModal={props.openModal}
          />
          <MotivationTile //sleep enhancers
            title={t("D194")}
            colour={colours.green}
            data={trackerScores.sleep.topEnhancers}
            valueArr={enhancers_sleep}
            width="quarter"
            modalText={[t("DW042")]}
            openModal={props.openModal}
          />
          <MotivationTile //sleep inhibitors
            title={t("D196")}
            colour={colours.red}
            data={trackerScores.sleep.topInhibitors}
            valueArr={inhibitors_sleep}
            width="quarter"
            modalText={[t("DW043")]}
            openModal={props.openModal}
          />
          <JournalBlock
            width="quarter"
            startWith={6}
            journalArr={trackerJournals}
            modalText={[t("D193")]}
            openModal={props.openModal}
          />
        </div>
      )}
      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div> */}
    </div>
  );
};
export default SleepResults;
