import { datadogRum } from "@datadog/browser-rum";

export function initializeDatadogRum() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "champion-health-app",
    env: process.env.REACT_APP_DD_ENV,
    version: process.env.REACT_APP_DD_VERSION,
    sessionSampleRate: process.env.REACT_APP_DD_ENV === "prod" ? 33 : 100,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enablePrivacyForActionName: true,
    allowedTracingUrls: [
      (url) => url.startsWith("https://api.staging.championhealth.dev"),
      (url) => url.startsWith("https://api.championhealth.co.uk"),
    ],
  });
}
