import React, { useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import "./Header.css";
import LogoImg from "./images/white_mountains.png";
import LogoImgColour from "./images/colour_mountains.png";
import UserBgImg from "./images/profile_1.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

import SideDrawer from "../../shared/menu/SideDrawer";
import { AuthContext } from "../../shared/context/auth-context";
import useDocumentTitle from "../../shared/hooks/title-hook";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Header(props) {
  const [profileImage, setProfileImage] = useState(UserBgImg);
  const [searchTerm, setSearchTerm] = useState();
  const { t } = useTranslation();

  //set p titles
  let pagetitle = window.location.pathname.substring(1);
  let description =
    "Welcome to your world of wellbeing. One platform covering all areas of wellbeing, from mindfulness to workouts.";

  if (pagetitle.slice(-1) === "/") {
    pagetitle = pagetitle.slice(0, -1);
  }

  if (pagetitle === "sign-in") {
    description =
      "Access your world of wellbeing from Champion Health. Sign in for one platform covering all areas of wellbeing, from mindfulness to workouts.";
  }

  pagetitle = pagetitle
    .split("-")
    .join(" ")
    .split("_")
    .join(" ")
    .split("/")
    .reverse()
    .join(" | ")
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  //Remove token inside page title when user is in ResetPasswordOverlay component
  if (pagetitle.includes("Reset")) {
    pagetitle = "Reset";
  }

  useDocumentTitle(
    (pagetitle + " | " || "") + "World of Wellbeing | Champion Health",
    description
  );

  const auth = useContext(AuthContext);
  const pageid = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (auth.image) {
      setProfileImage(auth.image);
    }
  }, [auth.image]);

  const [drawerIsOpen, setSideDrawerIsOpen] = useState(false);

  function openSideDrawer() {
    setSideDrawerIsOpen(true);
  }

  const menuClickHandler = useCallback(() => {
    setSideDrawerIsOpen(false);
  }, [drawerIsOpen]);

  const MenuButton = () => {
    return (
      <div id="menu_button" onClick={openSideDrawer}>
        <FontAwesomeIcon icon={faBars} className="icon" />
      </div>
    );
  };

  useEffect(() => {
    if (props.search) {
      setSearchTerm(props.search);
    }
  }, [props.search]);

  return (
    <div id="nav_container" className={props.page}>
      {auth.access === "10" && (
        <SideDrawer
          drawerIsOpen={drawerIsOpen}
          onMenuClose={menuClickHandler}
          onNextPage={props.onNextPage}
        />
      )}

      <div id="left_nav">
        {props.page !== "register" && props.page !== "assessment" && (
          <a href="/">
            <span className="visuallyHidden">{t("SD001")}</span>
            {props.page !== "company" && (
              <img src={LogoImg} alt="Champion Health" />
            )}
            {props.page === "company" && (
              <img src={LogoImgColour} alt="Champion Health" />
            )}
          </a>
        )}
      </div>
      <div
        id="right_nav"
        className={auth.isLoggedIn ? "logged-in" : "logged-out"}
      >
        {props.page === "register" && (
          <a href="/">
            <span className="visuallyHidden">{t("SD001")}</span>
            <div className="register_page_logo"></div>
          </a>
        )}
        <div id="progress_bar_container">
          {props.page === "assessment" && (
            <React.Fragment>
              <div id="progress_bar_outer">
                <div
                  id="progress_bar"
                  style={{ width: props.progress + "%" }}
                ></div>
              </div>
              <p>
                <span id="progress_percentage">{props.progress}</span>%{" "}
                {t("A124")}
              </p>
            </React.Fragment>
          )}
        </div>

        {auth.access === "10" && <MenuButton />}
        {auth.isLoggedIn ? (
          <Link className="headerSettingsLink" to="/profile/settings">
            <span className="visuallyHidden">{t("SD001")}</span>
            <div
              className="fs-exclude"
              id="photo_outer"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Header;
