import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInfoTile } from "./useInfoTile";

import "./InfoTileStyle.css";

export const InfoTile = ({ tileContents }) => {
  const { t } = useTranslation();
  const { icon, colour, comment, trackerIcon } = useInfoTile(tileContents);

  return (
    <div className="rm-box">
      <div className="profile-name left">
        {t(tileContents.title)}
        <FontAwesomeIcon icon={icon} className={"icon " + colour} />
      </div>
      <div className="streaks-cont">
        <div className="streak-clmn">
          <div className="streak-name">{t("G025")}</div>
          <div className="streak-circle grey">
            <FontAwesomeIcon icon={trackerIcon} className="icon " />
            <div className="profile-name nm">
              {tileContents.previousValue || "-"}
            </div>
            <div className="bold-sm-txt">{t(tileContents.descriptor)}</div>
          </div>
        </div>
        <div className="streak-clmn">
          <div className="streak-name">{t("S044")}</div>
          <div className={"streak-circle " + colour}>
            <FontAwesomeIcon icon={trackerIcon} className="icon" />
            <div className="profile-name nm">{tileContents.currentValue}</div>
            <div className="bold-sm-txt">{t(tileContents.descriptor)}</div>
          </div>
        </div>
      </div>
      <p className="cm">{t(comment)}</p>
    </div>
  );
};
