import { useState, useRef, useEffect } from "react";
import "../styles/CountdownTimer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/sharp-solid-svg-icons";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({
  seconds,
  size,
  strokeBgColor,
  strokeColor,
  strokeWidth,
  setOpenModal,
  playNext,
}) => {
  const [countdown, setCountdown] = useState(seconds * 1000);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const milliseconds = seconds * 1000;
  const radius = size / 2;
  const circumference = size * Math.PI;
  const svgRef = useRef(null);

  const strokeDashoffset = () =>
    circumference + (countdown / milliseconds) * circumference;

  const startTimer = () => {
    setIsPlaying(true);
    setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 10);
    }, 10);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const stopCountdown = () => {
    setCountdown(0);
    setIsPlaying(false);
    setOpenModal(false);
    playNext();
  };

  useEffect(() => {
    if (countdown <= 0) {
      stopCountdown();
    }
  }, [countdown]);

  const secondsDisplay = Math.max(0, Math.ceil(countdown / 1000));

  return (
    <div>
      <p id="countdown-sec-label" className="visuallyHidden">
        {/* aria-live="assertive" TO-DO adjust the timer to be more accurate - need to be disscussed with the team */}
        {t("M105")}
      </p>
      <button
        className="transparent-button start-btn"
        onClick={startTimer}
        disabled={isPlaying || countdown <= 0}
      >
        {t("M049")}
      </button>
      <div
        className="countdown-cont"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <p className="countdown-sec" onClick={() => stopCountdown()}>
          {isHovered ? (
            <FontAwesomeIcon
              icon={faPlay}
              className={`play-icon ${isHovered ? "" : "hidden"}`}
            />
          ) : (
            secondsDisplay
          )}
        </p>
        <svg className="countdown-crcl" ref={svgRef} aria-hidden>
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            fill="none"
            stroke={strokeBgColor}
            strokeWidth={strokeWidth}
          />
        </svg>
        <svg className="countdown-crcl" aria-hidden>
          <circle
            strokeDasharray={circumference}
            strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
            r={radius}
            cx={radius}
            cy={radius}
            fill="none"
            strokeLinecap="round"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
    </div>
  );
};

const CountdownTimerContainer = (props) => {
  const { t } = useTranslation();
  const dialogRef = useRef(null);

  useEffect(() => {
    if (dialogRef.current && !dialogRef.current.open) {
      dialogRef.current.showModal();
      dialogRef.current.focus();
    }
  }, []);

  return (
    <dialog
      className="autoplay"
      ref={dialogRef}
      tabIndex="-1"
      aria-labelledby="countdown-sec-label"
    >
      <div className="timer">
        <CountdownTimer
          seconds={10}
          size={120}
          strokeBgColor="rgba(255,255,255,0.5)"
          strokeColor="#ea2364"
          strokeWidth={2}
          setOpenModal={(newState) => {
            dialogRef.current.close();
            props.setOpenModal(newState);
          }}
          playNext={props.playNext}
        />
      </div>
      <button
        className="transparent-button cancel"
        onClick={() => dialogRef.current.close()}
      >
        {t("M050")}
      </button>
    </dialog>
  );
};

export default CountdownTimerContainer;
