import React, { useEffect, useState } from "react";
import "./Style.css";
import NumberSelect from "../numberSelect/NumberSelect";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function MultipleNumberSelect(props) {
  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  return (
    <div
      id={props.questionid}
      className={"question_block" + props.width}
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      <div className="answer_block multiple_number_select">
        {props.options.map((option) => {
          return (
            <NumberSelect
              key={option.id}
              questionid={option.id}
              minlimit="0"
              maxlimit={option.limit}
              trailingText={option.trailingText}
              width=""
              type="multiple"
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              controlsInfo={option.trailingText}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MultipleNumberSelect;
