import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function NumberSelectQuestion(props) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(null);
  const minlimit = Number(props.minlimit) || 0;

  function setIncreaseNumber() {
    var newValue = Number(inputValue);
    if (newValue < props.maxlimit) {
      newValue++;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function setDecreaseNumber() {
    var newValue = Number(inputValue);
    if (newValue > 0 && newValue > minlimit) {
      newValue--;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function numberChangeHandler(event) {
    setInputValue(event.target.value);
    saveValue(event.target.value);
  }

  const handleFocusOut = (event) => {
    if (props.maxlimit && Number(event.target.value) > Number(props.maxlimit)) {
      setInputValue(props.maxlimit);
      saveValue(props.maxlimit);
    }
    if (
      (props.minlimit && Number(event.target.value) < Number(props.minlimit)) ||
      (props.minlimit && !event.target.value)
    ) {
      setInputValue(props.minlimit);
      saveValue(props.minlimit);
    }
  };

  function saveValue(newValue) {
    //smoking must be 1+
    if (
      (props.questionid !== "smoke_per_day" || newValue > 0) &&
      newValue >= minlimit
    ) {
      props.onSetValue({ id: props.questionid, value: newValue });
    }
  }

  useEffect(() => {
    if (!inputValue) {
      if (props.assessmentValues[props.questionid]) {
        setInputValue(props.assessmentValues[props.questionid]);
      } else if (props.questionid === "hours_worked") {
        setInputValue("35");
        saveValue("35");
      } else if (props.questionid === "age") {
        setInputValue("16");
        saveValue("16");
      } else if (props.questionid === "days_absence") {
        setInputValue("0");
        saveValue("0");
      } else if (props.minlimit) {
        setInputValue(props.minlimit);
        saveValue(props.minlimit);
      }
    }
  }, [props]);

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  const [skipActive, setSkipActive] = useState("");

  function toggleSkipActive() {
    if (skipActive === "active") {
      setSkipActive("");
      props.onSetValue({ id: props.questionid, value: "" });
    } else {
      setSkipActive("active");
      props.onSetValue({ id: props.questionid, value: "skipped" });
    }
  }

  return (
    <div
      id={props.questionid}
      className={
        "question_block" + props.width + (props.skippable ? "" : " req")
      }
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      <label htmlFor={`numberSelect_${props.questionid}`}>{props.questionTitle}</label>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      {props.skipText ? (
        <span
          className={"suffixLabel " + skipActive}
          onClick={toggleSkipActive}
        >
          {props.skipText}
        </span>
      ) : null}
      <div className="answer_block">
        <div className="number_field">
          <div className="number_container fs-exclude">
            <input
              type="number"
              className="number"
              id={`numberSelect_${props.questionid}`}
              onChange={numberChangeHandler}
              onBlur={handleFocusOut}
              value={inputValue}
              max={props.maxlimit}
              min={props.minlimit ? props.minlimit : 0}
              aria-label={inputValue}
            />
            {props.trailingText}
          </div>
          <div className="number_controls">
            <button className="up_btn" onClick={setIncreaseNumber} aria-labelledby={`${props.questionid}_up`}>
              <span className="visuallyHidden" id={`${props.questionid}_up`} aria-hidden>
                {t("A134")} {props.controlsInfo}
              </span>
              <FontAwesomeIcon icon={faChevronUp} className="icon" />
            </button>
            <button className="down_btn" onClick={setDecreaseNumber} aria-labelledby={`${props.questionid}_down`}>
              <span className="visuallyHidden" id={`${props.questionid}_down`} aria-hidden>
                {t("A135")} {props.controlsInfo}
              </span>
              <FontAwesomeIcon icon={faChevronDown} className="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumberSelectQuestion;
