import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../shared/context/auth-context";

import Checkbox from "../questions/checkbox/Checkbox";
import Radio from "../questions/radio/Radio";
import NumberSelectQuestion from "../questions/numberSelectQuestion/NumberSelectQuestion";
import MeasurementsInput from "../questions/measurementsInput/measurementsInput";

import GooglePlay from "../../pages/images/googlePlayButton.png";
import AppStore from "../../pages/images/appStoreButton.png";

import OnboardingModal from "../../shared/modal/OnboardingModal";

function AssessmentProfile(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (auth.isLoggedIn && !props.assessmentValues.age) {
      setShowOnboarding(true);
    } else {
      setShowOnboarding(false);
    }

    if (showOnboarding && auth.isLoggedIn) {
      setTimeout(() => {
        setModalIsOpen(true);
      }, 300);
    }
  }, [showOnboarding, auth.isLoggedIn]);

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const genderOptions = [
    { id: "woman", title: t("A001") },
    { id: "man", title: t("A002") },
    { id: "trans-woman", title: t("A003") },
    { id: "trans-man", title: t("A004") },
    { id: "non-binary", title: t("A005") },
    { id: "other", title: t("A006") },
    { id: "none", title: t("A007") },
  ];

  const sexOptions = [
    { id: "male_specific", title: t("A008") },
    { id: "female_specific", title: t("A009") },
    { id: "do_not_tailor", title: t("A010") },
  ];

  const bmiOptions = [
    { id: "yes", title: t("A011") },
    { id: "no", title: t("A012") },
  ];

  const childrenOptions = [
    { id: "yes", title: t("A013") },
    { id: "no", title: t("A014") },
    { id: "prefer_not_to_say", title: t("A015") },
  ];

  const childAgeOptions = [
    { id: "0", title: t("A016") },
    { id: "1_5", title: t("A017") },
    { id: "6_12", title: t("A018") },
    { id: "13_17", title: t("A019") },
    { id: "18", title: t("A020") },
  ];

  const workTypeOptions = [
    { id: "office", title: t("A021") },
    { id: "home", title: t("A022") },
    { id: "office_and_home", title: t("A023") },
    { id: "manual", title: t("A024") },
    { id: "driver", title: t("A025") },
    { id: "healthcare", title: t("A026") },
    { id: "retail", title: t("A027") },
    { id: "education", title: t("A028") },
    { id: "other", title: t("A029") },
  ];

  const interestOptions = [
    { id: "none", title: t("A030") },
    { id: "physical_wellbeing", title: t("A031") },
    { id: "mental_wellbeing", title: t("A032") },
    { id: "spiritual_wellbeing", title: t("A033") },
    { id: "exercise_activity", title: t("A034") },
    { id: "managing_weight", title: t("A035") },
    { id: "nutrition", title: t("A036") },
    { id: "hydration_alcohol", title: t("A037") },
    { id: "sleep", title: t("A038") },
    { id: "productivity", title: t("A039") },
    { id: "another_area", title: t("A040") },
  ];

  useEffect(() => {
    if (props.assessmentValues.have_children !== "yes") {
      props.onSetValue({ id: "child_ages", value: [] });
    }
  }, [props.assessmentValues.have_children]);

  useEffect(() => {
    if (
      props.assessmentValues.height === "skipped" ||
      props.assessmentValues.height === "unsure" ||
      props.assessmentValues.weight === "skipped" ||
      props.assessmentValues.weight === "unsure"
    ) {
      props.onSetValue({ id: "show_bmi", value: null });
    }
  }, [props.assessmentValues.height, props.assessmentValues.weight]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      {auth.isLoggedIn && modalIsOpen && (
        <OnboardingModal
          modalIsOpen={modalIsOpen}
          modalClickHandler={modalClickHandler}
        />
      )}

      <div className="app-banner-mobile">
        <p>{t("A041")}</p>
        <a
          href="https://play.google.com/store/apps/details?id=com.championhealth.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={GooglePlay}
            className="app_link google_play"
            alt={t("A042")}
          ></img>
        </a>
        <a
          href="https://apps.apple.com/us/app/champion-health/id1580359828"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={AppStore}
            className="app_link app_store"
            alt={t("A043")}
          ></img>
        </a>
      </div>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <NumberSelectQuestion
        questionid="age"
        questionTitle={t("A044")}
        minlimit="16"
        maxlimit="120"
        trailingText=""
        width=""
        modalText={t("A045")}
        onOpenModal={props.onOpenModal}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        controlsInfo={t("A137")}
      />

      <Radio
        questionid="gender"
        width="_wide"
        questionTitle={t("A046")}
        options={genderOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A047")}
        onOpenModal={props.onOpenModal}
      />

      <Radio
        questionid="tailor_recommendations"
        width="_wide"
        questionTitle={t("A048")}
        questionTitleSuffix={t("A049")}
        options={sexOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        onOpenModal={props.onOpenModal}
      />

      <MeasurementsInput
        type="height"
        questionid="height"
        questionTitle={t("A050")}
        skipText={t("A051")}
        skipText2={t("A052")}
        type1Text="Ft in"
        type2Text="cm"
        width="_wide"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A053")}
        onOpenModal={props.onOpenModal}
      />

      <MeasurementsInput
        type="weight"
        questionid="weight"
        questionTitle={t("A054")}
        skipText={t("A055")}
        skipText2={t("A056")}
        type1Text="St/lb"
        type2Text="Kg"
        width="_wide"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A057")}
        onOpenModal={props.onOpenModal}
      />

      {props.assessmentValues.height === "skipped" ||
      props.assessmentValues.height === "unsure" ||
      props.assessmentValues.weight === "skipped" ||
      props.assessmentValues.weight === "unsure" ? (
        <span> </span>
      ) : (
        <Radio
          questionid="show_bmi"
          width="_wide"
          questionTitle={t("A058")}
          questionTitleSuffix={t("A059")}
          options={bmiOptions}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          onOpenModal={props.onOpenModal}
        />
      )}

      <Radio
        questionid="have_children"
        width=""
        questionTitle={t("A060")}
        options={childrenOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A061")}
        onOpenModal={props.onOpenModal}
      />
      {props.assessmentValues.have_children === "yes" && (
        <Checkbox
          questionid="child_ages"
          width=""
          questionTitle={t("A062")}
          questionTitleSuffix={t("A063")}
          options={childAgeOptions}
          placeholder={t("A064")}
          itemLimit="2"
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
        />
      )}

      <Radio
        questionid="work_type"
        width="_wide"
        questionTitle={t("A065")}
        options={workTypeOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A066")}
        onOpenModal={props.onOpenModal}
      />

      <Checkbox
        questionid="your_interests"
        width="_wide"
        questionTitle={t("A067")}
        questionTitleSuffix={t("A068")}
        options={interestOptions}
        placeholder={t("A069")}
        itemLimit="3"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("A070")}
        onOpenModal={props.onOpenModal}
      />
    </div>
  );
}

export default AssessmentProfile;
