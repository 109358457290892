import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "../styles/MasterclassPlayer.css";
import "../styles/MediaPlayerStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { Chapters } from "./Chapters";
import SingleMediaPlayer from "./SingleMediaPlayer";
import { setSingleMedia } from "../mediaSlice";
import { McMediaDescription } from "./McMediaDescription";

export const MasterclassPlayer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [chapterNo, setChapterNo] = useState(false);
  const [openCountdown, setOpenCountdown] = useState(false);
  const [keepPlaying, setKeepPlaying] = useState(false);

  const masterclass = useSelector((state) => state.media.masterclass);
  const nowActive = useSelector((state) => state.media.singleMedia);
  const chapterNumber = window.location.pathname.split("/")[4];

  //scroll top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (masterclass && chapterNumber !== chapterNo) {
      setChapterNo(chapterNumber);
      dispatch(setSingleMedia(masterclass.chapters[chapterNumber - 1]));
    }
  }, [chapterNumber, masterclass, chapterNo, dispatch]);

  useEffect(() => {
    if (nowActive?.chid) {
      const observer = new MutationObserver((mutationsList, observer) => {
        let mcMedia = document.getElementsByClassName("mcplayer");
        let text = document.getElementsByClassName("below-video");
        if (mcMedia[0]) {
          mcMedia[0].classList.add("fade-in");
          text[0].classList.add("fade-in");
          observer.disconnect(); // Stop observing when the classes are added
        }
      });

      // Start observing the document with the configured parameters
      observer.observe(document, { childList: true, subtree: true });
    }
  }, [nowActive?.chid]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("pageReload", true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleNavigation = () => {
    const defaultPath = "/for-you";
    const reloaded = sessionStorage.getItem("pageReload");

    if (sessionStorage.length > 0) {
      const previousPages =
        JSON.parse(sessionStorage.getItem("contentTypePreviousLocations")) ||
        [];
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );

      let targetPath;
      if (previousPages.length > 0) {
        targetPath = previousPages.shift();
      }

      // If the target path is the same as the current path, shift again
      if (targetPath === location.pathname) {
        targetPath = previousPages.shift();
      }
      // Update the previous pages in sessionStorage
      sessionStorage.setItem(
        "contentTypePreviousLocations",
        JSON.stringify(previousPages)
      );

      if (!targetPath) {
        targetPath = previousLocation?.pathname;
      }

      // Navigate to the target path if it exists, otherwise navigate to the default path
      targetPath
        ? reloaded
          ? navigate(targetPath)
          : navigate(-chapterCount.current)
        : navigate(defaultPath);
      chapterCount.current = 0;
      mounted.current = false;
    } else {
      navigate(defaultPath);
    }
  };

  const handleAutoPlay = () => {
    if (chapterNumber < masterclass.chapters.length) {
      setOpenCountdown(true);
    }
  };

  //function called by autoplay
  const playNext = () => {
    let mcMedia = document.getElementsByClassName("mcplayer");
    let text = document.getElementsByClassName("below-video");
    if (mcMedia[0] && text[0]) {
      mcMedia[0].classList.remove("fade-in");
      text[0].classList.remove("fade-in");
    }
    setTimeout(() => {
      navigate(
        `/masterclass/${masterclass.chid}/${masterclass.title.replace(
          / /g,
          "_"
        )}/${Number(chapterNumber) + 1}/${masterclass.chapters[
          Number(chapterNumber)
        ].title
          .replace(/ /g, "_")
          .toLowerCase()}`
      );
      setKeepPlaying(true);
    }, 700);
  };

  //setting player height, so other elements don't move around when chapters change
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mediaHeight, setMediaHeight] = useState(0);

  const calculateHeight = () => {
    let newValue;
    if (screenWidth < 850) {
      newValue = Math.floor(screenWidth * 0.6);
    } else {
      newValue = Math.floor(screenWidth * 0.38);
    }
    setMediaHeight(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  useEffect(() => {
    calculateHeight();
  }, [screenWidth]);

  const chapterCount = useRef(0);
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current && chapterNumber !== chapterNo) {
      chapterCount.current = chapterCount.current + 1;
    }
    mounted.current = true;
  }, [chapterNumber]);

  return (
    <React.Fragment>
      <div id="masterclass-container">
        <div className="column-1">
          <button
            className="back-button"
            onClick={(e) => {
              handleNavigation();
            }}
          >
            <span className="visuallyHidden">{t("M011")}</span>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="icon return-arrow back"
            />
          </button>
          <div className="media-cont" style={{ height: `${mediaHeight}px` }}>
            <SingleMediaPlayer
              openCountdown={openCountdown}
              setOpenCountdown={setOpenCountdown}
              playNext={playNext}
              mediaHeight={mediaHeight}
              masterclass
              handleAutoPlay={handleAutoPlay}
              keepPlaying={keepPlaying}
              progress={masterclass.progress_array[chapterNumber - 1]}
            />
          </div>

          <div className="below-video">
            {nowActive && (
              <McMediaDescription
                masterclass={masterclass}
                nowActive={nowActive}
                chapterNo={chapterNo}
              />
            )}
          </div>
        </div>

        {nowActive && <Chapters masterclassPage masterclass={masterclass} />}
      </div>
    </React.Fragment>
  );
};
