import "./DropDown.css";

export const DropDown = ({ options, value, onChange, defaultValue, id }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="profile rdrop_down_wrapper">
      <div className={"custom_select "}>
        <select value={value} onChange={handleChange} id={id}>
          <option disabled={true} value="" className="disabled" tabIndex={-1}>
            {defaultValue}
          </option>
          {options?.map((item, index) => (
            <option
              key={index}
              id={index}
              value={item}
              className="r_drop_down_content"
            >
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
