import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../styles/Chapters.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-thin-svg-icons";
import {
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";
import { handleSeeAll, handleSeeMore } from "../functions";

export const Chapters = ({ masterclass, masterclassPage, closeChapters }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);
  const chaptersRef = useRef(null);
  const lastActiveElementRef = useRef(null);
  const closeButtonRef = useRef(null);

  const handleSeeAllWrapper = () => handleSeeAll(seeMore, setSeeMore);

  useEffect(() => {
    if (!masterclassPage) {
      lastActiveElementRef.current = document.activeElement;
      closeButtonRef.current?.focus();

      const handleKeyDown = (e) => {
        const focusableElements = chaptersRef.current?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        if (!focusableElements?.length) return;

        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === focusableElements[0]) {
              e.preventDefault();
              focusableElements[focusableElements.length - 1].focus();
            }
          } else {
            if (
              document.activeElement ===
              focusableElements[focusableElements.length - 1]
            ) {
              e.preventDefault();
              focusableElements[0].focus();
            }
          }
        } else if (e.key === "Escape" && !masterclassPage && closeChapters) {
          closeChapters();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        if (lastActiveElementRef.current) {
          lastActiveElementRef.current.focus();
        }
      };
    }
  }, []);

  const handleSeeMoreWrapper = (id, item) => {
    const clickedId = id;
    if (masterclassPage) {
      handleSeeMore(clickedId, item);
    } else {
      let index = masterclass.chapters.findIndex((x) => x.chid === item.chid);
      navigate(
        `/masterclass/${masterclass.chid}/${masterclass.title.replace(
          / /g,
          "_"
        )}/${index + 1}/${item.title.replace(/ /g, "_").toLowerCase()}`
      );
    }
  };
  return (
    <div
      ref={chaptersRef}
      className={masterclassPage ? "chapters-popup player" : "chapters-popup"}
    >
      <div className="chapters-heading">
        <div className="title">{t("M027")}</div>
        {masterclassPage ? (
          <button
            className="expand transparent-masterclass-button"
            onClick={handleSeeAllWrapper}
          >
            <div>{seeMore ? t("M028") : t("M029")}</div>
            <FontAwesomeIcon
              icon={seeMore ? faChevronCircleUp : faChevronCircleDown}
              className="icon chevron"
            />
          </button>
        ) : (
          <button
            className="close-chapters transparent-masterclass-button"
            onClick={closeChapters}
            ref={closeButtonRef}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="times" />
          </button>
        )}
      </div>
      <div className="scrollable">
        {masterclass?.chapters?.length > 0 &&
          masterclass.chapters.map((item, index) => (
            <React.Fragment key={index}>
              <button
                className="chapter"
                onClick={() => handleSeeMoreWrapper(`${index}chapter`, item)}
                id={`${index}chapter`}
              >
                <div className="top-row">
                  <div className="constant-text">
                    <div className="number">{index + 1}</div>
                    <div className="title">
                      {item.title.replace(/&amp;/gi, "&")}
                    </div>
                  </div>
                  {masterclassPage && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="icon chevron"
                    />
                  )}
                </div>
              </button>
              <div className="see-more">
                <p className="time">{convertTime(item.length)}</p>
                <p className="text">{item.description}</p>
                <button
                  className="see-more-button"
                  onClick={() =>
                    navigate(
                      `/masterclass/${
                        masterclass.chid
                      }/${masterclass.title.replace(/ /g, "_")}/${
                        index + 1
                      }/${item.title.replace(/ /g, "_").toLowerCase()}`
                    )
                  }
                >
                  {t("M030")}
                </button>
              </div>
              {masterclass.progress_array &&
                masterclass.progress_array[index] > 0 && (
                  <div
                    className="chapter-progress-container"
                    role="progressbar"
                    aria-valuenow={masterclass.progress_array[index]}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${masterclass.progress_array[index]}%`,
                      }}
                    ></div>
                  </div>
                )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
