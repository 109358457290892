import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NavSliderStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
} from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

export const NavSlider = ({ navigation, titles, ids, tags }) => {
  // install Virtual module
  SwiperCore.use([
    Virtual,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Parallax,
    Zoom,
    Lazy,
    Controller,
    A11y,
    History,
    HashNavigation,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    Thumbs,
  ]);

  const [slidesList, setSlidesList] = useState(navigation);
  const [activeClass, setActiveClass] = useState();
  const swiperRef = useRef(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onReachBeginning = () => {
    setIsBeginning(true);
  };

  const onReachEnd = () => {
    setIsEnd(true);
  };

  useEffect(() => {
    swiperRef.current.swiper.update();
    window.scroll({
      top: "#nav",
      behavior: "smooth",
    });
    if (navigation) {
      setSlidesList(navigation);
      setActiveClass(navigation[0].target);
    }
    if (ids) {
      let slidesArr = [];
      ids.forEach((item, index) => {
        let title = titles[item];
        if (!title) {
          title = item.replaceAll("_", " ").replaceAll("-", " ");
        }
        slidesArr.push({ index: index, title: title, target: item });
      });
      setSlidesList(slidesArr);
      setActiveClass(slidesArr[0].target);
    }
  }, [navigation, tags, ids]);

  const handleNavigation = (subpage) => {
    let pageCont = document.getElementsByClassName("page-cont");
    if (pageCont.length > 0) {
      pageCont[0].classList.remove("visible");
    }

    navigate(`/${subpage}/library`);
  };

  let randoId = "sw" + Math.floor(Math.random() * 90000) + 10000;

  function sliderPosChange() {
    const swiper = swiperRef.current.swiper;
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }

  return (
    <div className="nav_slider" id={"nav"}>
      <div
        className={`swiper-button-next ${
          isEnd ? "swiper-button-disabled" : ""
        }`}
        id={randoId + "-next"}
      ></div>
      <div
        className={`swiper-button-prev ${
          isBeginning ? "swiper-button-disabled" : ""
        }`}
        id={randoId + "-prev"}
      ></div>

      <Swiper
        ref={swiperRef}
        spaceBetween={5}
        slidesPerView={"auto"}
        slidesPerGroup={1}
        loop={false}
        onReachBeginning={onReachBeginning}
        onReachEnd={onReachEnd}
        navigation={{
          nextEl: "#" + randoId + "-next",
          prevEl: "#" + randoId + "-prev",
        }}
        initialSlide={0}
        pagination={false}
        onSlideChange={() => sliderPosChange()}
        observer={false}
      >
        {slidesList &&
          slidesList.map((slideContent, index) => {
            let activeclass;
            if (slideContent.target === activeClass) {
              activeclass = "active";
            }
            let subpage = slideContent.title.toLowerCase().replaceAll(" ", "-");
            return (
              <SwiperSlide
                key={randoId + slideContent.target}
                id={"nav_" + slideContent.target}
                virtualIndex={index}
                className={activeclass}
                onClick={() => {
                  handleNavigation(subpage);
                }}
              >
                {t(slideContent.code)}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};
