import i18next, { t } from "i18next";

enum TrackerType {
  Sleep = "sleep",
  Activity = "activity",
  Stress = "stress",
  Mood = "mood",
  Nutrition = "nutrition",
  Hydration = "hydration",
  Energy = "energy",
  Alcohol = "alcohol",
  Smoking = "smoking",
  Productivity = "productivity",
}

const SupportedLanguages = ["en", "de", "sv"];

let worstAreaOfPain: { id: string; title: string }[] = [];

const updateWorstAreaOfPain = () => {
  worstAreaOfPain = [
    { id: "jaw", title: t("PH003") },
    { id: "hand", title: t("PH004") },
    { id: "fingers", title: t("PH005") },
    { id: "wrist", title: t("PH006") },
    { id: "shoulder", title: t("PH007") },
    { id: "pelvis", title: t("PH008") },
    { id: "knee", title: t("PH009") },
    { id: "ankle", title: t("PH010") },
    { id: "foot", title: t("PH011") },
    { id: "neck", title: t("PH012") },
    { id: "mid-back", title: t("PH013") },
    { id: "lower-back", title: t("PH014") },
    { id: "elbow", title: t("PH015") },
    { id: "hip", title: t("PH016") },
  ];
};

// Initialize the array when i18next is initialized
i18next.on("initialized", () => {
  updateWorstAreaOfPain();
});

// Update the array when the language is changed
i18next.on("languageChanged", () => {
  updateWorstAreaOfPain();
});

export { TrackerType, SupportedLanguages, worstAreaOfPain };
