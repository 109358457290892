import React from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

function CheckboxItem(props) {
  function setCheckedHandler(event) {
    const newItem = {
      add: event.target.checked,
      id: event.target.id,
      title: event.target.title,
      class: "",
    };

    props.onAddSelectedItem(newItem);
  }

  return (
    <div className="checkbox_item">
      <input
        type="checkbox"
        id={props.secondaryQ ? props.item.id + "_sq" : props.item.id}
        name={props.secondaryQ ? props.item.id + "_sq" : props.item.id}
        className="fdatacollect"
        onChange={setCheckedHandler}
        title={props.item.title}
        checked={props.checked}
        aria-labelledby={`${props.item.title.replace(/\s+/g, '_')}_label`}  
      />
      <label htmlFor={props.secondaryQ ? props.item.id + "_sq" : props.item.id} id={`${props.item.title.replace(/\s+/g, '_')}_label`} aria-hidden>
        <FontAwesomeIcon icon={faCheckSquare} className="icon" />
        {props.item.title}
      </label>
    </div>
  );
}

export default CheckboxItem;
