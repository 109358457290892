import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { mediaSlidersApiSlice } from "../../../forYou/mediaSlidersApiSlice";
import { mediaApiSlice } from "../../../contentTypes/mediaApiSlice";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";
import { resetMediaSliders } from "../../../forYou/mediaSlidersSlice";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import En from "../../images/En.svg";
import De from "../../images/De.svg";
import Sv from "../../images/Sv.svg";
import "./LanguagesRightMenu.css";

export const LanguagesRightMenu = ({
  profile,
  changeMenu,
  hide,
  saveChanges,
}) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const { sendRequest } = useHttpClient();
  const [userProfile, setUserProfile] = useState(profile);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [activeLang, setActiveLang] = useState(profile?.language || "en");

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  useEffect(() => {
    setUserProfile((prevState) => ({
      ...prevState,
      language: activeLang,
    }));
  }, [activeLang]);

  const saveProfile = async () => {
    if (userProfile && auth.token) {
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`,
          "POST",
          JSON.stringify({
            profile: userProfile,
          })
        );
      } catch (err) {
        console.error(err);
      }
      if (responseData) {
        showGreenCheckOverlay();
        auth.languageHandler(activeLang);
        saveChanges(userProfile);
        dispatch(resetMediaSliders());
        dispatch(
          mediaSlidersApiSlice.util.invalidateTags([
            { type: "MediaSliders", id: "LIST" },
            { type: "Feature", id: "LIST" },
            { type: "TakeAction", id: "LIST" },
          ])
        );
        dispatch(
          mediaApiSlice.util.invalidateTags([
            { type: "SingleMedia", id: "LIST" },
            { type: "Masterclass", id: "LIST" },
            { type: "Programme", id: "LIST" },
          ])
        );
      }
    }
  };

  return (
    <>
      {showGreenCheck && <GreenCheckOverlay />}
      {userProfile && (
        <div className="edit-field lang">
          {hide && (
            <div className="btn-cont ">
              <ChevronBackBtn onClick={() => changeMenu(0, "back")} />
            </div>
          )}
          <div className="edit-title">{t("PL001")}</div>
          <LanguageOption
            id="en"
            language={{ src: En, name: t("PL002") }}
            active={activeLang === "en"}
            onClick={() => setActiveLang("en")}
          />
          <LanguageOption
            id="de"
            language={{ src: De, name: t("PL003") }}
            active={activeLang === "de"}
            onClick={() => setActiveLang("de")}
          />
          <LanguageOption
            id="sv"
            language={{ src: Sv, name: t("PL004") }}
            active={activeLang === "sv"}
            onClick={() => setActiveLang("sv")}
          />
          <PinkButton1 className="wide marginTop30" onClick={saveProfile}>
            {t("S017")}
          </PinkButton1>
        </div>
      )}
    </>
  );
};

const LanguageOption = ({ language, active, onClick, id }) => {
  return (
    <button
      className={`profile-slider-btn language-option ${active ? "active" : ""}`}
      onClick={onClick}
      aria-labelledby={`language-option-${id}`}
    >
      <div className="menu-item" aria-hidden="true">
        <img src={language.src} alt={language.name} className="slider-icon" />
        <div className="slider-text" id={`language-option-${id}`}>
          {language.name}
        </div>
      </div>
    </button>
  );
};
