import React from "react";

export const ProgressBars = (props) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const sectionBars = [];
  for (var i = 0; i < props.sectionMax; i++) {
    var active = "";
    if (i + 1 === props.sectionNo) {
      active = "active";
    }
    sectionBars[i] = { key: i, class: "section_bar " + active };
  }

  return (
    <div id="section_progress">
      {sectionBars.map((item) => {
        return <div key={item.key} className={item.class}></div>;
      })}
      <p>&copy; {currentYear} Champion Health</p>
    </div>
  );
};
