import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { faCircleXmark } from "@fortawesome/pro-thin-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

export const SharePopUp = ({ setShareOpen }) => {
  const { t } = useTranslation();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const modalRef = useRef(null);
  const lastActiveElementRef = useRef(null);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopiedToClipboard(true);
  };

  const handleShareOpen = () => {
    setCopiedToClipboard(false);
    setShareOpen(false);
  };

  useEffect(() => {
    lastActiveElementRef.current = document.activeElement;
    const focusableElements = modalRef.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    if (!focusableElements?.length) return;
    focusableElements[0].focus();

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === focusableElements[0]) {
            e.preventDefault();
            focusableElements[focusableElements.length - 1].focus();
          }
        } else {
          if (
            document.activeElement ===
            focusableElements[focusableElements.length - 1]
          ) {
            e.preventDefault();
            focusableElements[0].focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      if (lastActiveElementRef.current) {
        lastActiveElementRef.current.focus();
      }
    };
  }, []);

  return (
    <div className="rate-overlay share">
      <div className="share-container" ref={modalRef}>
        <div className="share-heading">
          <div>{t("M006")}</div>
          <button
            className="share-heading-close transparent-masterclass-button"
            onClick={handleShareOpen}
          >
            <span className="visuallyHidden">{t("M098")}</span>
            <FontAwesomeIcon icon={faCircleXmark} className="icon " />
          </button>
        </div>
        <div className="share-body">
          <div className="title">{t("M006W")}</div>
          <div className={copiedToClipboard ? "copied visible" : "copied"}>
            {t("M072W")}
            <button
              className="share-heading-small-close"
              onClick={handleShareOpen}
            >
              <span className="visuallyHidden">{t("M098")}</span>
              <FontAwesomeIcon
                onClick={handleShareOpen}
                icon={faCheck}
                className="icon "
              />
            </button>
          </div>
          <div className="url">
            <div className="url-address">{window.location.href}</div>
            <button
              className="share-heading-copy transparent-masterclass-button"
              onClick={handleCopyToClipboard}
            >
              <span className="visuallyHidden">{t("M106")}</span>
              <FontAwesomeIcon icon={faCopy} className="icon " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
