import "./style.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleCheck,
} from "@fortawesome/pro-solid-svg-icons";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { handleEmailChange } from "../../shared/util/handleValueChange";

import Logo from "../../pages/images/logo_white.svg";
import Layout from "../components/Layout";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

export const RequestAccessCode = () => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const resetHandler = async (event) => {
    event.preventDefault();
    setMessage("");
    setErrorMessage("");

    let invalid = document
      .getElementById("email")
      .classList.contains("invalid");
    if (invalid) {
      setErrorMessage(t("R084"));
      return;
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/forgottenCode`,
        "POST",
        JSON.stringify({
          email: email,
        })
      );
      if (responseData.message) {
        setMessage(responseData.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout>
      <div id="right_container">
        <div className="frame">
          <div className="logo">
            <img
              src={Logo}
              className="icon title_icon"
              alt="Champion Health Logo"
            />
          </div>
          <h1>{t("R073")}</h1>
          <p>{t("R074")}</p>
          <label className="visuallyHidden" htmlFor="email" aria-hidden="true">
            {t("R023")}
          </label>
          <input
            type="email"
            className={"code_input "}
            id="email"
            name="email"
            placeholder={t("R023")}
            aria-label={t("R023")}
            onChange={(e) => handleEmailChange(e, setEmail)}
          />
          {isLoading && <LoadingSpinner />}
          {message?.length > 0 && (
            <p className="login_error success">
              {" "}
              <FontAwesomeIcon icon={faCircleCheck} className="icon" />
              <span dangerouslySetInnerHTML={{ __html: message || error }} />
            </p>
          )}
          {error?.length > 0 && (
            <p className="login_error">
              {" "}
              <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
              <span dangerouslySetInnerHTML={{ __html: message || error }} />
            </p>
          )}
          {errorMessage?.length > 0 && (
            <p className="login_error">
              {" "}
              <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
              <span>{errorMessage}</span>
            </p>
          )}
          {!isLoading && (
            <button onClick={resetHandler} id="continue">
              {t("R079")}
            </button>
          )}
        </div>

        <div className="lower_container bottom_padding">
          <p className="signin">
            {t("R080")} <a href="/code">{t("R011")}</a>
          </p>
          <p className="signin">
            {t("R075")}{" "}
            <a
              href="https://support.championhealth.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              {t("R076")}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};
