import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MenuOptionButton = ({
  icon,
  id,
  color,
  text,
  onClick,
  isActive,
}) => (
  <button
    className={`profile-slider-btn ${isActive ? "active" : ""}`}
    onClick={onClick}
    aria-labelledby={`profile-slider-btn-${id}`}
  >
    <div className="menu-item" aria-hidden="true">
      <FontAwesomeIcon icon={icon} style={{ color }} className="slider-icon" />
      <div className="slider-text" id={`profile-slider-btn-${id}`}>
        {text}
      </div>
    </div>
    <FontAwesomeIcon
      icon={faChevronRight}
      className="slider-chevron"
      aria-hidden="true"
    />
  </button>
);
