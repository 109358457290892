import { AccountDetailsLeftMenu } from "./accountDetails/AccountDetailsLeftMenu";
import { NotificationsLeftMenu } from "./children/NotificationsLeftMenu";
import { SecurityLeftMenu } from "./securityDetails/SecurityLeftMenu";

export const ProfileSettingsLeftPanel = ({
  activeMenu,
  activeSubMenu,
  profile,
  changeMenu,
  changeSubMenu,
  isHidden,
  saveProfileImage,
}) => {
  return (
    <aside className={`menu-left ${isHidden ? "simplify" : ""}`}>
      {activeMenu?.id === 41 && (
        <AccountDetailsLeftMenu
          isHidden={isHidden}
          profile={profile}
          changeMenu={changeMenu}
          activeSubMenu={activeSubMenu}
          changeSubMenu={changeSubMenu}
          saveProfileImage={saveProfileImage}
        />
      )}
      {activeMenu?.id === 42 && (
        <SecurityLeftMenu
          hide={isHidden}
          profile={profile}
          activeSubMenu={activeSubMenu}
          changeMenu={changeMenu}
          changeSubMenu={changeSubMenu}
        />
      )}
      {activeMenu?.id === 43 && (
        <NotificationsLeftMenu
          hide={isHidden}
          profile={profile}
          activeSubMenu={activeSubMenu}
          changeSubMenu={changeSubMenu}
        />
      )}
    </aside>
  );
};
