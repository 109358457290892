import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import WhitePopUpNoTitle from "../../shared/modal/whitePopUp/WhitePopUpNoTitle";

export const YourStats = ({ hide, userStats }) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");
  const buttonRef = useRef(null);

  const openModal = () => {
    setModalIsOpen(true);
    setModalTextContent({ title: t("S047"), text: [t("S130")] });
  };

  const modalClickHandler = () => {
    setModalIsOpen(false);
    buttonRef.current?.focus();
  };

  return (
    <>
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      <div className={`rm-box ${hide ? "mt" : ""} fs-exclude`}>
        <div className="profile-name left">
          {t("S047")}
          <button
            onClick={openModal}
            className="transparent-button icon-button"
            ref={buttonRef}
          >
            <span className="visuallyHidden">{t("S047")}</span>
            <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
          </button>
        </div>
        <div className="streaks-cont">
          <div className="streak-clmn-full">
            <StreakLine name={t("S048")} number={userStats.platformMinutes} />
            <StreakLine name={t("S049")} number={userStats.mindfulMinutes} />
            <StreakLine name={t("S050")} number={userStats.activeMinutes} />
          </div>
        </div>
      </div>
    </>
  );
};

const StreakLine = ({ name, number }) => {
  return (
    <div className="streak-line">
      <div className="streak-name">{name}</div>
      {formatNumber(number)}
    </div>
  );
};

const formatNumber = (number) => {
  // Format the number with commas
  if (number < 0 || number === null) number = 0;

  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Split the formatted number into individual characters (digits and commas)
  const characters = formattedNumber.split("");

  return (
    <div className="profile-name left nm">
      {characters.map((char, index) =>
        char === "," ? (
          <span key={index}>,</span>
        ) : (
          <div className="scrolling-container">
            <div className="scrolling-number">
              <span key={char} className={`current`}>
                {char}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
};
