import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colours } from "../../assets/colours";
import PinkButton1 from "../../shared/components/styledComponents/pinkButton1/PinkButton1";
import smileyFace from "../images/smileyFace.png";
import { faShare, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import WhitePopUpCTA from "../../shared/modal/whitePopUp/WhitePopUpCTA";
import WhitePopUpNoTitle from "../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import ChevronBackBtn from "../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { useTranslation } from "react-i18next";

const colors = ["red", "orange", "yellow", "green", "blue"];

export const ManageFnFRightMenu = ({
  profile,
  hide,
  userFnF,
  saveChanges,
  removeUser,
  generateLink,
  goBack,
}) => {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState();
  const [inputName, setInputName] = useState();
  const [copied, setCopied] = useState(false);
  const [action, setAction] = useState(profile.action); // ["manage", "invite"]
  const [randomColour, setRandomColour] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (field, value) => {
    let valid = false;
    if (field === "name") {
      const nameRegex = /^[a-zA-Z0-9\s.'-]*$/;
      valid = nameRegex.test(value);
      valid = value.length < 50 && valid;
    } else {
      valid = true;
    }
    if (valid) {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    if (profile.action === "manage") {
      setUserProfile(profile.user);
      setInputName(profile.user.name);
      setAction(profile.action);
    } else if (profile.action === "invite") {
      setAction(profile.action);
      setRandomColour(colors[Math.floor(Math.random() * 5)]);
      setUserProfile({ name: "", colour: "", status: "pending" });
    }
  }, [profile, userFnF]);

  const saveToClipboard = () => {
    const content = `<p>${t("S128")} ${userProfile.token}. ${t(
      "S128A"
    )}<a href="https://app.championhealth.co.uk/code/${
      userProfile.token
    }"  target="_blank" rel="noopener noreferrer">https://app.championhealth.co.uk/code/${
      userProfile.token
    }</a>.</p>`;

    const dummy = document.createElement("p");
    dummy.innerHTML = content;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();
    // First clear, in case the user already selected some other text
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
  };

  const colorsTranslatedNames = {
    red: t("R087"),
    orange: t("R088"),
    yellow: t("R089"),
    green: t("R090"),
    blue: t("R091"),
  };

  return (
    <div className="edit-field fs-exclude">
      {hide && (
        <div className="btn-cont ">
          <ChevronBackBtn onClick={() => goBack()} />
        </div>
      )}
      {openModal && (
        <WhitePopUpCTA
          popupContents={{
            title: t("S007"),
            textArr: [
              { text: t("S020"), className: "sub-heading" },
              {
                text: t("S021"),
                className: "",
              },
              {
                text: (
                  <>
                    {t("S022")}{" "}
                    <a
                      href="https://support.championhealth.co.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("S023")}
                    </a>
                  </>
                ),
                className: "",
              },
            ],
            buttons: [
              {
                text: t("M050"),
                color: "dark",
                onClick: () => {
                  setOpenModal(false);
                },
              },
              {
                text: t("T016"),
                color: "pink",
                onClick: () => {
                  removeUser(userProfile);
                  setOpenModal(false);
                },
              },
            ],
          }}
        />
      )}
      {copied && (
        <WhitePopUpNoTitle
          popupContents={{
            title: t("S004"),
            text: [t("S129")],
          }}
          ctaBtn={t("T015")}
          handleSubmit={() => {
            setCopied(false);
          }}
        />
      )}
      {profile.action && (
        <>
          <div className="edit-title centre">
            {action === "invite" ? t("S004") : `${t("S016")} ${inputName}`}
          </div>
          <div className="cw">
            <div
              className="ff-cont"
              style={{
                backgroundColor: colours[userProfile?.colour || randomColour],
              }}
            >
              <img src={smileyFace} alt="smiley face" />
            </div>
            <div className="color-row">
              {colors.map((color) => (
                <button
                  key={color}
                  id={color}
                  className="circle-ff"
                  onClick={(e) => handleChange("colour", e.target.id)}
                >
                  <span className="visuallyHidden">
                    {colorsTranslatedNames[color]}
                  </span>
                  {color === (userProfile?.colour || randomColour) && (
                    <div className="inner-crc"></div>
                  )}
                </button>
              ))}
            </div>
          </div>
          <div className="field-cont">
            <label htmlFor="name" className="field-descr">
              {t("R027")}
            </label>
            <input
              id="name"
              type="text"
              value={userProfile?.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          {userProfile?.token && (
            <div className="field-cont">
              <span className="field-descr" aria-hidden="true">
                {t("S018")}
              </span>
              <button
                className="transparent-button link hide-outline"
                onClick={saveToClipboard}
                aria-label={t("S136")}
              >
                app.championhealth.co.uk/code/{userProfile.token}
                <FontAwesomeIcon icon={faShare} className="icon" />
              </button>
            </div>
          )}

          {action === "invite" && !userProfile?.token && (
            <PinkButton1
              className="wide marginTop30"
              disabled={!userProfile?.name || userProfile?.name?.length < 1}
              onClick={() => {
                setInputName(userProfile.name);
                generateLink(
                  userProfile.name,
                  userProfile?.colour || randomColour
                );
              }}
            >
              {t("S014")}
            </PinkButton1>
          )}
          {(action === "manage" ||
            (action === "invite" && userProfile?.token)) && (
            <PinkButton1
              className="wide marginTop30"
              onClick={() => {
                setInputName(userProfile.name);
                saveChanges(userProfile);
              }}
            >
              {t("S017")}
            </PinkButton1>
          )}
          {action === "manage" && (
            <button
              id="delete-profile"
              className="transparent-button bold-sm-txt centre"
              onClick={() => setOpenModal(true)}
            >
              {" "}
              <FontAwesomeIcon icon={faTrashAlt} className="icon" />
              {t("S007")}
            </button>
          )}
        </>
      )}
    </div>
  );
};
