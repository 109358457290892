import { useState, useContext } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import { useTranslation } from "react-i18next";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { DropDown } from "../../components/dropDown/DropDown";
import { useAccountDetailsState } from "./useAccountDetailsState";
import { AuthContext } from "../../../shared/context/auth-context";

export const AccountDetailsRightMenu = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const {
    modalIsOpen,
    modalTextContent,
    profile,
    activeDept,
    activeSubDept,
    subDeptsArr,
    activeRegion,
    regionArr,
    regionGroupArr,
    regionGroup,
    regionsOptions,
    showGreenCheck,
    isEmailValid,
    activeButton,
    modalClickHandler,
    handleEmailChange,
    handleNameChange,
    saveProfile,
    handleSelectRegionGroup,
    handleSelectRegion,
    handleSelectSubDept,
    handleSelectDept,
  } = useAccountDetailsState({
    profile: props.profile,
    saveChanges: props.saveChanges,
  });

  return (
    <>
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      {showGreenCheck && <GreenCheckOverlay />}
      {props.profile && (
        <>
          <div className="edit-field fs-exclude">
            {props.hide && (
              <div className="btn-cont ">
                <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
              </div>
            )}
            <div className="edit-title">{t("S025")}</div>
            <ProfileNameField
              label={t("R027")}
              name={profile.name}
              handleNameChange={handleNameChange}
              errorMessage={t("S054")}
            />
            <ProfileEmailField
              label={t("R023")}
              email={profile.email}
              handleEmailChange={handleEmailChange}
              errorMessage={t("S055")}
              isEmailValid={isEmailValid}
              auth={auth}
            />
            <ProfileRegionFields
              label={{ region: t("S031"), regionGroup: t("S032") }}
              defaultValue={{ region: t("S123"), regionGroup: t("S122") }}
              options={{
                region: regionGroupArr?.length ? regionsOptions : regionArr,
                regionGroup: regionGroupArr,
              }}
              value={{ region: activeRegion, regionGroup: regionGroup }}
              onChange={{
                region: handleSelectRegion,
                regionGroup: handleSelectRegionGroup,
              }}
            />
            <ProfileDepartmentFields
              profile={profile}
              active={{ department: activeDept, subDepartment: activeSubDept }}
              onChange={{
                department: handleSelectDept,
                subDepartment: handleSelectSubDept,
              }}
              label={{ department: t("S030"), subDepartment: t("S029") }}
              defaultValue={{ department: t("S124"), subDepartment: t("S125") }}
              subDeptsArr={subDeptsArr}
            />
            <PinkButton1
              disabled={!activeButton}
              className="wide marginTop30"
              onClick={saveProfile}
            >
              {t("S017")}
            </PinkButton1>
          </div>
        </>
      )}
    </>
  );
};

const ProfileNameField = ({ label, name, handleNameChange, errorMessage }) => {
  return (
    <>
      <div className={name?.length > 0 ? "field-cont" : "field-cont error"}>
        <label className="field-descr" htmlFor="name">
          {label}
        </label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={handleNameChange}
        ></input>
      </div>
      {name?.length < 1 && <div className="error">{errorMessage}</div>}
    </>
  );
};

const ProfileEmailField = ({
  label,
  email,
  handleEmailChange,
  errorMessage,
  isEmailValid,
  auth,
}) => {
  return (
    <>
      <div className={isEmailValid ? "field-cont" : "field-cont error"}>
        <label className="field-descr" htmlFor="email">
          {label}
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          //disable email editing for Hub users and non-Hub users using SSO
          disabled={
            (!auth.isHub &&
              auth.authType != null &&
              auth.authType !== "ChampionHealth") ||
            auth.isHub
          }
        ></input>
      </div>
      {!isEmailValid && <div className="error">{errorMessage}</div>}
    </>
  );
};

const ProfileRegionFields = ({
  label,
  defaultValue,
  options,
  value,
  onChange,
}) => {
  const [isRegionPresent, setIsRegionPresent] = useState(value.region !== "");
  const [isRegionGroupPresent, setIsRegionGroupPresent] = useState(
    value.regionGroup !== ""
  );

  const handleRegionGroupChange = (event) => {
    onChange.regionGroup(event);
    setIsRegionGroupPresent(event?.target?.value !== "");
  };

  const handleRegionChange = (event) => {
    onChange.region(event);
    setIsRegionPresent(event?.target?.value !== "");
  };

  return (
    <>
      {options.regionGroup?.length > 0 && (
        <div
          className={isRegionGroupPresent ? "field-cont" : "field-cont error"}
        >
          <label
            className="field-descr select-label"
            id="region-group-label"
            htmlFor="region-group"
          >
            {label.regionGroup}
          </label>
          <div className="select-cont">
            <DropDown
              defaultValue={defaultValue.regionGroup}
              options={options.regionGroup}
              value={value.regionGroup}
              onChange={handleRegionGroupChange}
              labelId="region-group-label"
              id="region-group"
            />
          </div>
        </div>
      )}
      {options.region?.length > 0 && (
        <div className={isRegionPresent ? "field-cont" : "field-cont error"}>
          <label
            className="field-descr select-label"
            id="region-label"
            htmlFor="region"
          >
            {label.region}
          </label>
          <div className="select-cont">
            <DropDown
              defaultValue={defaultValue.region}
              options={options.region}
              value={value.region}
              onChange={handleRegionChange}
              labelId="region-label"
              id="region"
            />
          </div>
        </div>
      )}
    </>
  );
};

const ProfileDepartmentFields = ({
  profile,
  active,
  onChange,
  label,
  defaultValue,
  subDeptsArr,
}) => {
  const [isDeppartmentPresent, setIsDeppartmentPresent] = useState(
    active.department !== ""
  );
  const [isSubDepartmentPresent, setIsSubDepartmentPresent] = useState(
    active.subDepartment !== ""
  );

  const handleDepartmentChange = (event) => {
    onChange.department(event);
    setIsDeppartmentPresent(event?.target?.value !== "");
  };

  const handleSubDepartmentChange = (event) => {
    onChange.subDepartment(event);
    setIsSubDepartmentPresent(event?.target?.value !== "");
  };

  return (
    <>
      {profile.departments &&
        profile.departments.length > 1 &&
        profile.access != "2" && (
          <div
            className={isDeppartmentPresent ? "field-cont" : "field-cont error"}
          >
            <label
              className="field-descr select-label"
              id="department-label"
              htmlFor="department"
            >
              {label.department}
            </label>
            <div className="select-cont">
              <DropDown
                defaultValue={defaultValue.department}
                options={profile.departments}
                value={active.department}
                onChange={handleDepartmentChange}
                labelId="department-label"
                id="department"
              />
            </div>
          </div>
        )}
      {subDeptsArr && subDeptsArr.length > 0 && profile.access != "2" && (
        <div
          className={isSubDepartmentPresent ? "field-cont" : "field-cont error"}
        >
          <label
            className="field-descr select-label"
            id="sub-department-label"
            htmlFor="sub-department"
          >
            {label.subDepartment}
          </label>
          <div className="select-cont">
            <DropDown
              labelId="sub-department-label"
              defaultValue={defaultValue.subDepartment}
              options={subDeptsArr}
              value={active.subDepartment}
              onChange={handleSubDepartmentChange}
              id="sub-department"
            />
          </div>
        </div>
      )}
    </>
  );
};
