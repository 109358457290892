import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import { useFetchSingleMediaQuery } from "../mediaApiSlice";
import { setSingleMedia } from "../mediaSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import SingleMediaPlayer from "../components/SingleMediaPlayer";

export const SingleMediaPlayerWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[3];

  const mediaCHID = useSelector((state) => state.media.singleMedia.chid);
  const progress = useSelector((state) => state.media.singleMedia.progress);

  const location = useLocation();

  const fetchResult = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/${location.pathname.split("/")[1]}/${
        location.pathname.split("/")[3]
      }`,
    },
    {
      skip: !location.pathname.split("/")[3],
    }
  );

  const { data: singleMedia, isFetching } =
    mediaCHID !== chid ? fetchResult : { data: null, isFetching: false };

  useEffect(() => {
    if (!isFetching && singleMedia) {
      if (mediaCHID !== chid || progress !== singleMedia.progress) {
        dispatch(
          setSingleMedia({
            _id: singleMedia._id,
            regions: singleMedia.regions,
            completed: singleMedia.completed,
            favourite: singleMedia.favourite,
            minutes_watched: singleMedia.minutes_watched,
            progress: singleMedia.progress,
            related_media: singleMedia.related_media,
            video_cover_image: singleMedia.video_cover_image,
            chid: singleMedia.chid,
            title: singleMedia.title,
            description: singleMedia.description,

            category: singleMedia.category, // recipe || workout || session || service || take-action || soundscape || masterclass (Chapters)
            type: singleMedia.type, // audio || video || null
            label: singleMedia.label,
            snooze: singleMedia.snooze,
            mindfulness: singleMedia.mindfulness,

            tags: singleMedia.tags,
            transcript: singleMedia.transcript,
            signposting: singleMedia.signposting,
            waiver: singleMedia.waiver,

            url: singleMedia.url,
            resources: singleMedia.resources,
            length: singleMedia.length,
            author: singleMedia.author,

            //Workouts/Recipes
            difficulty: singleMedia.difficulty,

            //Workouts
            exercises: singleMedia.exercises,
            equipment: singleMedia.equipment,

            //Recipes
            serves: singleMedia.serves,
            ingredients: singleMedia.ingredients,
            method: singleMedia.method,
            kcal: singleMedia.kcal,
            carbs: singleMedia.carbs,
            fat: singleMedia.fat,
            protein: singleMedia.protein,

            //Service/Take Action
            action_url: singleMedia.action_url,
            action_string: singleMedia.action_string,

            background_image: singleMedia.background_image,
            thumbnail_image: singleMedia.thumbnail_image,

            like: singleMedia.like,
            dislike: singleMedia.dislike,
          })
        );
      }
    }
  }, [dispatch, singleMedia]);

  return (
    <div id="cover-page" className="media">
      {isFetching ? <LoadingSpinner asOverlay /> : <SingleMediaPlayer />}
    </div>
  );
};
