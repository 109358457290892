import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Explore.css";

import Header from "../shared/header/MainHeader";
import { NavStickyBar } from "../shared/navStickyBar/NavStickyBar";

import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";

import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import SearchBar from "../shared/header/SearchBar";
import { ContentItem } from "../shared/components/slider/contentItem/ContentItem";

const Explore = (props) => {
  const { t } = useTranslation();
  const text = [
    [t("EX001"), t("EX002"), "Physical Wellbeing"],
    [t("EX003"), t("EX004"), "Movement"],
    [t("EX005"), t("EX006"), "Sleep & Rest"],
    [t("EX007"), t("EX008"), "Mental Wellbeing"],
    [t("EX009"), t("EX010"), "Financial Wellbeing"],
    [t("EX011"), t("EX012"), "Nutrition"],
    [t("EX013"), t("EX014"), "Hydration & Alcohol"],
    [t("EX015"), t("EX016"), "Work & Productivity"],
    [t("EX017"), t("EX018"), "Energy"],
  ];

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [noResults, setNoResults] = useState(false);
  const [media, setMedia] = useState();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="main_container" className="foryou health-area platform explore">
      <div className="wrap-screen">
        {isLoading && <LoadingSpinner asOverlay />}
        {auth.isLoggedIn && (
          <React.Fragment>
            {/* page = "no-submenu" or empty to have a shallow or deep header */}

            <div id="nav_container" className="no-submenu">
              <Header />
              <div className="heading">
                {/* <Link to="/for-you">
                <FontAwesomeIcon icon={faHouse} className="icon home" />
              </Link>
              <FontAwesomeIcon icon={faChevronRight} className="icon" /> */}
                <h2>{t("EX019")}</h2>
              </div>
            </div>
            <SearchBar setMedia={setMedia} setNoResults={setNoResults} />
            {media?.length > 0 ? (
              <div className="search_media_container">
                {media.map((mediaItem, index) => (
                  <ContentItem key={index} slideContent={mediaItem} />
                ))}
              </div>
            ) : !noResults ? (
              <div className="search_tile_container">
                {text.map((item, index) => (
                  <Link
                    to={`/${item[2]
                      .toLowerCase()
                      .replaceAll(" ", "-")}/library`}
                    className="page-tile"
                    key={index}
                  >
                    <div className="tile-title">{item[0]}</div>
                    <div className="tile-subtitle">{item[1]}</div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="no-results">
                <div className="tile-title">{t("EX020")}</div>
                <div className="tile-subtitle">{t("EX021")}</div>
              </div>
            )}

            <NavStickyBar />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default Explore;
