import { useState, useRef, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/pro-thin-svg-icons";

import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

export const RateBlock = ({
  like,
  dislike,
  setLike,
  setDislike,
  handleLikeDislike,
}) => {
  const { t } = useTranslation();
  const [likeDislikeOpen, setLikeDislikeOpen] = useState(false);
  const rateBlockRef = useRef(null);
  const lastActiveElementRef = useRef(null);
  const dislikeButtonRef = useRef(null);

  const handleThumbsClick = (e) => {
    if (like && e.currentTarget.id === "like") {
      setLike(false);
    } else if (dislike && e.currentTarget.id === "dislike") {
      setDislike(false);
    } else if (like && e.currentTarget.id === "dislike") {
      setDislike(true);
      setLike(false);
    } else if (dislike && e.currentTarget.id === "like") {
      setDislike(false);
      setLike(true);
    } else if (!like && !dislike) {
      e.currentTarget.id === "dislike" ? setDislike(true) : setLike(true);
    }
    setLikeDislikeOpen(false);
    handleLikeDislike(e.currentTarget.id);
  };

  useEffect(() => {
    if (likeDislikeOpen) {
      lastActiveElementRef.current = document.activeElement;
      dislikeButtonRef.current?.focus();

      const handleKeyDown = (e) => {
        const focusableElements = rateBlockRef.current?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        if (!focusableElements?.length) return;

        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === focusableElements[0]) {
              e.preventDefault();
              focusableElements[focusableElements.length - 1].focus();
            }
          } else {
            if (
              document.activeElement ===
              focusableElements[focusableElements.length - 1]
            ) {
              e.preventDefault();
              focusableElements[0].focus();
            }
          }
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        if (lastActiveElementRef.current) {
          lastActiveElementRef.current.focus();
        }
      };
    }
  }, [likeDislikeOpen]);

  return (
    <div className="rate-block-container" ref={rateBlockRef}>
      {likeDislikeOpen && <div className="rate-overlay"></div>}
      <button
        className="rate-block transparent-masterclass-button"
        onClick={() => {
          setLikeDislikeOpen(!likeDislikeOpen);
        }}
      >
        {likeDislikeOpen ? (
          <FontAwesomeIcon icon={faCircleXmark} className="icon action" />
        ) : !like && !dislike ? (
          <FontAwesomeIcon icon={faThumbsUp} className="icon action" />
        ) : like ? (
          <FontAwesomeIcon icon={faThumbsUpSolid} className="icon action" />
        ) : (
          <FontAwesomeIcon icon={faThumbsDownSolid} className="icon action" />
        )}
        {!likeDislikeOpen && <div className="rate">{t("M022")}</div>}
        <span className="visuallyHidden">{t("M022")}</span>
      </button>
      {likeDislikeOpen && (
        <div className="rate-btns">
          <button
            className="rate-btns-dislike transparent-masterclass-button"
            id="dislike"
            onClick={handleThumbsClick}
            ref={dislikeButtonRef}
          >
            <span className="visuallyHidden">{t("M099")}</span>
            <FontAwesomeIcon
              icon={dislike ? faThumbsDownSolid : faThumbsDown}
              className="icon action"
            />
          </button>
          <button
            className="rate-btns-like transparent-masterclass-button"
            id="like"
            onClick={handleThumbsClick}
          >
            <span className="visuallyHidden">{t("M100")}</span>
            <FontAwesomeIcon
              icon={like ? faThumbsUpSolid : faThumbsUp}
              className="icon action"
            />
          </button>
        </div>
      )}
    </div>
  );
};
