import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { WebVTT } from "videojs-vtt.js";

import "../styles/MediaPlayerStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowDownToBracket,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";

import { TrackerType } from "../../shared/util/enum";
import bgImage from "../images/soundwave.jpg";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import GreyButton from "../../shared/components/styledComponents/greyButton/GreyButton";
import { AuthContext } from "../../shared/context/auth-context";
import SnoozeButton from "./SnoozeButton";
import { PlayerButtons } from "./PlayerButtons";
import { useUpdateProgressMutation } from "../mediaApiSlice";
import { updateMCProgressArray, updateStateProgress } from "../mediaSlice";
import WhitePopUp from "../../shared/modal/whitePopUp/WhitePopUp";
import CountdownTimerContainer from "./CountdownTimer";
import GreenCheckOverlay from "../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ProgrammeCompleted from "./ProgrammeCompleted";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  formatDate,
  transformSecondsToMinutes,
} from "../../shared/util/helperfunctions";

const SingleMediaPlayer = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const mediaRef = useRef();
  const containerRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [updateProgressAPI] = useUpdateProgressMutation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showCaptions, setShowCaptions] = useState(false);
  const [caption, setCaption] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [snooze, setSnooze] = useState(false);
  const [snoozeTimeoutId, setSnoozeTimeoutId] = useState(null);
  const [currentTranscriptUrl, setCurrentTranscriptUrl] = useState("");
  const lastUpdateTime = useRef(0);
  const lastMediaRef = useRef(null);

  const timePlayedRef = useRef(0);
  const counterRef = useRef(0);
  const intervalIdRef = useRef(null);

  const runCounter = (start) => {
    if (start) {
      if (intervalIdRef.current === null) {
        intervalIdRef.current = setInterval(() => {
          counterRef.current++;
        }, 1000);
      }
    } else {
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
        timePlayedRef.current += counterRef.current;
        counterRef.current = 0; // Reset counter
      }
    }
  };

  const mediaContent = useSelector((state) => state.media.singleMedia);
  // hook to hide controls after 3 seconds of inactivity
  useEffect(() => {
    let timeout;
    const resetTimer = () => {
      setShowControls(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => setShowControls(false), 3000);
    };

    const events = ["mousedown", "mousemove", "keydown", "touchstart"];
    events.forEach((event) => document.addEventListener(event, resetTimer));

    timeout = setTimeout(() => setShowControls(false), 3000);

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, resetTimer)
      );
      clearTimeout(timeout);
    };
  }, []);

  //workout consent logic
  const [consented, setConsented] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const popUpContents = {
    title: t("M008"),
    heading: `${t("M009")}...`,
    paragraphs: [t("M010")],
    secondaryBtn: t("M011"),
    primaryBtn: t("M012"),
  };

  const checkForConsent = () => {
    if (
      mediaContent.waiver === true &&
      !consented &&
      mediaContent.category !== "workout"
    ) {
      setOpenPopUp(true);
      return false;
    } else {
      return true;
    }
  };

  const handleConsent = (value) => {
    const media = mediaRef.current;
    if (value) {
      setConsented(true);
      setIsPlaying(true);
      if ((currentTime * 100) / duration >= 100) {
        media.currentTime = 0;
        setCurrentTime(0);
      }
      media.play();
      runCounter(true);
    } else {
      setConsented(false);
    }
    setOpenPopUp(false);
  };

  //event listeners for media controls
  useEffect(() => {
    if (mediaContent?.chid) {
      const media = mediaRef.current;
      if (media) {
        media.src = mediaContent.url;
        media.addEventListener("timeupdate", handleTimeUpdate);
        media.addEventListener("loadedmetadata", handleMetaDataLoad);
        media.addEventListener("volumechange", handleVolumeChange);
        return () => {
          media.removeEventListener("timeupdate", handleTimeUpdate);
          media.removeEventListener("loadedmetadata", handleMetaDataLoad);
          media.removeEventListener("volumechange", handleVolumeChange);
        };
      }
    }
  }, [mediaContent.chid]);

  //subtitles and or captions
  const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
  const cuesRef = useRef([]);

  parser.oncue = (cue) => {
    const correctedCue = {
      startTime: cue.startTime,
      endTime: cue.endTime,
      text: cue.text,
    };
    cuesRef.current.push(correctedCue);
  };

  const chidRef = useRef(mediaContent.chid);

  useEffect(() => {
    chidRef.current = mediaContent.chid;
    cuesRef.current = [];
  }, [mediaContent.chid]);

  useEffect(() => {
    const transcript = mediaContent?.transcript;

    if (transcript && currentTranscriptUrl !== transcript) {
      setCurrentTranscriptUrl(transcript);

      fetch(transcript)
        .then((response) => response.text())
        .then((data) => {
          const currentTranscript = mediaContent?.transcript;
          if (currentTranscript) {
            parser.parse(data);
            parser.flush();
          }
        });
    }
  }, [currentTranscriptUrl, mediaContent, parser]);

  const toggleCaptions = (e) => {
    setShowCaptions(!showCaptions);
  };
  //end of subtitles and or captions logic

  //fullscreen event listener
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
      if (props.mediaHeight) {
        let video = document.getElementsByTagName("video");
        video[0].style.height = `${props.mediaHeight}px`;
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  //snooze button logic for soundscape and sleep story
  const handleSnooze = (time) => {
    // Clear the previous timeout if it exists
    if (snoozeTimeoutId) {
      clearTimeout(snoozeTimeoutId);
      setSnoozeTimeoutId(null);
    }

    // If time is null, just return
    if (time === null) {
      setSnooze(false);
      return;
    }

    let snoozeInSeconds = time * 60 * 1000;
    setSnooze(time);

    const timeoutId = setTimeout(() => {
      setIsPlaying(false);
      const media = mediaRef.current;
      media.pause();
      runCounter(false);
      updateProgress(media);
      setSnooze(false);
    }, snoozeInSeconds);

    // Save the timeout ID so it can be cleared later
    setSnoozeTimeoutId(timeoutId);
  };
  //end of snooze button logic

  const handleVolumeChange = () => {
    const media = mediaRef.current;
    setVolume(media.volume);
    setIsMuted(media.volume === 0);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--volume-percentage",
      `${volume * 100}%`
    );
  }, [volume]);

  const handleTimeUpdate = () => {
    const media = mediaRef.current;
    if (cuesRef.current?.length > 0) {
      const currentCue = cuesRef.current.find(
        (cue) =>
          media.currentTime >= cue.startTime && media.currentTime <= cue.endTime
      );
      if (currentCue) {
        setCaption(currentCue.text);
      } else {
        setCaption("");
      }
    }
    setCurrentTime(media.currentTime);
    let progressPercent = (media.currentTime / media.duration) * 100;
    let completed = progressPercent >= 99 ? true : false;

    // Get the current time
    const currentTime = Date.now();

    if (
      props.programme &&
      (mediaContent.completed === true ||
        props.progressArray[props.nowActiveIndex] === 100)
    ) {
      //do nothing if programme and current media has already been either marked as completed on the server or has been played to 100% locally
    } else if (
      progressPercent &&
      (currentTime - lastUpdateTime.current >= 10000 || progressPercent === 100)
    ) {
      // Update progress if 10 seconds have passed since the last update or if the media has been played to 100%
      lastUpdateTime.current = currentTime;
      updateProgressAPI({
        token: auth.token,
        mediaId: mediaContent._id,
        progress: progressPercent,
        completed: completed,
      }).catch((err) => {
        console.error(err);
      });
      dispatch(
        updateStateProgress({
          progress: progressPercent,
          completed: completed,
        })
      );
    }

    //update masterclass progress array if masterclass and not programme or if programme and current media is not completed
    if (
      (props.masterclass && !props.programme) ||
      (props.programme &&
        props.progressArray &&
        props.progressArray[props.nowActiveIndex] < 100)
    ) {
      dispatch(
        updateMCProgressArray({
          id: mediaContent._id,
          progress: progressPercent,
        })
      );
    }
    //if the media has been played to 100% and if it is a masterclass, pause the media and autoplay the next chapter
    if (progressPercent === 100 && props.masterclass && !props.programme) {
      media.pause();
      runCounter(false);
      props.handleAutoPlay();
    }

    //if the media has been played to 100% and if it is a programme, call programme autoplay
    if (progressPercent === 100 && props.programme) {
      props.handleProgAutoPlay();
    }
  };

  const updateMindfulMinutes = async (time) => {
    //check if today's tracker has already been submitted and update or create a new tracker
    const date = new Date();
    const trackerDate = formatDate(date);
    let tracker, trackerId, data, url, method, updatedTracker;

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/trackers/mood/${trackerDate}`
      );
      if (response && response.tracker?._id) {
        tracker = response.tracker;
        trackerId = response.tracker._id;
        data = JSON.parse(tracker.data);
      }

      if (!!tracker && !!trackerId) {
        method = "PUT";
        url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${trackerId}`;
        updatedTracker = {
          type: TrackerType.Mood,
          data: {
            mood_rating: data.mood_rating,
            mindful_minutes: [
              { off_platform: data.mindful_minutes[0]?.off_platform },
              {
                in_platform:
                  Number(data.mindful_minutes[1]?.in_platform) +
                  transformSecondsToMinutes(time),
              },
            ],
          },
          hidden: tracker.hidden,
          platform: "web",
          enhancers: tracker.enhancers,
          inhibitors: [],
          journal: tracker.journal,
          trackerDate: trackerDate,
        };
      } else {
        method = "POST";
        url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;

        updatedTracker = {
          type: TrackerType.Mood,
          data: {
            mood_rating: null,
            mindful_minutes: [
              { off_platform: 0 },
              { in_platform: transformSecondsToMinutes(time) },
            ],
          },
          hidden: false,
          platform: "web",
          enhancers: [],
          inhibitors: [],
          journal: "",
          trackerDate: trackerDate,
        };
      }
    } catch (err) {
      console.error(err);
    }

    if (updatedTracker && url && method) {
      try {
        await sendRequest(url, method, JSON.stringify(updatedTracker));
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Function to update progress
  const updateProgress = async (media, timePlayed) => {
    if (media) {
      let progressPercent = (media.currentTime / media.duration) * 100;
      let completed = progressPercent >= 99 ? true : false;
      if (!mediaContent || !progressPercent) return;
      try {
        await updateProgressAPI({
          token: auth.token,
          mediaId: mediaContent._id,
          progress: progressPercent,
          completed: completed,
        });

        dispatch(
          updateStateProgress({
            progress: progressPercent,
            completed: completed,
          })
        );

        if (!!mediaContent.mindfulness && timePlayed) {
          updateMindfulMinutes(timePlayed);
        }
      } catch (err) {
        console.error(err);
      }
      lastUpdateTime.current = 0;
      lastMediaRef.current = null;
      timePlayedRef.current = 0;
      counterRef.current = 0;
      intervalIdRef.current = null;
    }
  };

  const handleMetaDataLoad = () => {
    const media = mediaRef.current;
    setDuration(media.duration);
    let progress = props.progress ? props.progress : mediaContent.progress;
    if (Math.ceil(progress) === 100) {
      setCurrentTime(0);
      media.currentTime = 0;
    } else if (progress) {
      media.currentTime = (media.duration * progress) / 100;
      setCurrentTime((media.duration * progress) / 100);
    } else {
      setCurrentTime(0);
    }

    let canContinue = true;
    if (mediaContent?.waiver === true) {
      canContinue = checkForConsent();
    }

    if (
      mediaRef.current?.src &&
      props.masterclass &&
      props.keepPlaying &&
      canContinue
    ) {
      setIsPlaying(true);
      mediaRef.current.play();
      runCounter(true);
    } else {
      setIsPlaying(false);
      mediaRef.current?.pause();
      runCounter(false);
    }
  };

  useEffect(() => {
    // Listen for user navigating away via browser back/forward buttons
    const unlisten = () => {
      runCounter(false);
      if (!props.masterclass && !props.programme) {
        updateProgress(lastMediaRef.current, timePlayedRef.current);
      }
    };

    // Clean up the event listener on component unmount
    return () => {
      unlisten();
    };
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("pageReload", true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleNavigation = () => {
    const defaultPath = "/for-you";
    const reloaded = sessionStorage.getItem("pageReload");

    if (sessionStorage.length > 0) {
      const previousPages =
        JSON.parse(sessionStorage.getItem("contentTypePreviousLocations")) ||
        [];
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );

      let targetPath;
      if (previousPages.length > 0) {
        targetPath = previousPages.shift();
      }

      // If the target path is the same as the current path, shift again
      if (targetPath === location.pathname) {
        targetPath = previousPages.shift();
      }
      // Update the previous pages in sessionStorage
      sessionStorage.setItem(
        "contentTypePreviousLocations",
        JSON.stringify(previousPages)
      );

      if (!targetPath) {
        targetPath = previousLocation?.pathname;
      }

      // Navigate to the target path if it exists, otherwise navigate to the default path
      targetPath
        ? reloaded
          ? navigate(targetPath)
          : navigate(-1)
        : navigate(defaultPath);
    } else {
      navigate(defaultPath);
    }
  };

  useEffect(() => {
    // Update lastMediaRef with the current mediaRef value that will persist through renders
    lastMediaRef.current = mediaRef.current;
  });

  const goToDownloadPage = (mediaContent) => {
    navigate(`/${mediaContent.id}`);
  };

  return (
    <div className={props.masterclass ? "" : "main-background"}>
      {!mediaContent && <LoadingSpinner />}
      {mediaContent && (
        <div
          className={
            props.masterclass
              ? mediaContent.type === "audio"
                ? "mcplayer"
                : "mcplayer video"
              : mediaContent.type === "audio"
              ? "media-player"
              : "media-player video"
          }
          style={{
            ...(props.mediaHeight && {
              height: `${props.mediaHeight}px`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
            }),
            backgroundImage: props.masterclass
              ? mediaContent.type === "audio"
                ? `url(${
                    mediaContent.video_cover_image
                      ? mediaContent.video_cover_image
                      : bgImage
                  })`
                : "none"
              : mediaContent.type === "audio"
              ? `url(${
                  mediaContent.background_image
                    ? mediaContent.background_image
                    : mediaContent.video_cover_image
                })`
              : "none",
          }}
        >
          <div className={props.masterclass ? "" : "background-blur"}>
            {showControls && !props.masterclass && (
              <button
                className="back-button player"
                onClick={(e) => {
                  handleNavigation();
                }}
              >
                <span className="visuallyHidden">{t("M011")}</span>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="icon return-arrow back"
                />
              </button>
            )}
            <div ref={containerRef} className={"container-ref"}>
              {props.progCompletedOpen && (
                <ProgrammeCompleted
                  closeProgrammeCompleted={props.closeProgrammeCompleted}
                />
              )}
              {props.openCountdown && (
                <CountdownTimerContainer
                  setOpenModal={props.setOpenCountdown}
                  playNext={props.playNext}
                />
              )}
              {props.showGreenCheck && <GreenCheckOverlay masterclass />}
              {openPopUp && (
                <WhitePopUp
                  popUpContents={popUpContents}
                  handleSubmit={handleConsent}
                />
              )}
              {mediaContent.type === "audio" && (
                <React.Fragment>
                  {isFullScreen && (
                    <div
                      className="audio-fullscreen"
                      style={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        height: "100vh",
                      }}
                    ></div>
                  )}
                  <audio
                    style={{
                      ...(props.mediaHeight && {
                        height: `${props.mediaHeight}px`,
                      }),
                    }}
                    ref={mediaRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowControls(!showControls);
                    }}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                  >
                    <source src={mediaContent.url} type="audio/mp3" />
                  </audio>
                </React.Fragment>
              )}
              {mediaContent.type === "video" &&
                mediaContent.category !== "soundscape" && (
                  <video
                    style={{
                      ...(props.mediaHeight &&
                        !isFullScreen && {
                          height: `${props.mediaHeight}px`,
                        }),
                    }}
                    ref={mediaRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowControls(!showControls);
                    }}
                    poster={mediaContent.video_cover_image}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    disablePictureInPicture
                  >
                    <source src={mediaContent.url} type="video/mp4" />
                  </video>
                )}
              {mediaContent.category === "soundscape" && (
                <video
                  ref={mediaRef}
                  loop
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowControls(!showControls);
                  }}
                  poster={mediaContent.video_cover_image}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  disablePictureInPicture
                >
                  <source src={mediaContent.url} type="video/mp4" />
                </video>
              )}
              {showCaptions &&
                caption?.length > 0 &&
                mediaContent?.transcript && (
                  <div
                    className={
                      showControls
                        ? "subtitles-container raised"
                        : "subtitles-container"
                    }
                  >
                    {caption}
                  </div>
                )}
              <div
                className="controls container"
                style={{ opacity: showControls ? 1 : 0 }}
              >
                {!props.masterclass && (
                  <div className="heading">
                    <h3 className="title">{mediaContent.title}</h3>
                    <p className="author">
                      {t("M088", {
                        author:
                          mediaContent?.author?.author ?? "Champion Health",
                      })}
                    </p>
                  </div>
                )}
                <PlayerButtons
                  class="player"
                  mediaRef={mediaRef}
                  mediaContent={mediaContent}
                  volume={volume}
                  setVolume={setVolume}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  duration={duration}
                  currentTime={currentTime}
                  setCurrentTime={setCurrentTime}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  showCaptions={showCaptions}
                  toggleCaptions={toggleCaptions}
                  containerRef={containerRef}
                  mediaHeight={props.mediaHeight}
                  checkForConsent={checkForConsent}
                  runCounter={runCounter}
                />
                {!props.masterclass && (
                  <div className="action-container">
                    {!!mediaContent.snooze && isPlaying ? (
                      <SnoozeButton
                        handleSnooze={handleSnooze}
                        isPlaying={isPlaying}
                        snooze={snooze}
                      />
                    ) : (
                      <React.Fragment>
                        {mediaContent.signposting && (
                          <a
                            href={mediaContent.signposting}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="mimicButton">
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="icon"
                              />
                              {t("M001")}
                            </div>
                          </a>
                        )}
                        {mediaContent.resources && (
                          <a
                            href={mediaContent.resources}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="mimicButton">
                              <FontAwesomeIcon
                                icon={faArrowDownToBracket}
                                className="icon"
                              />{" "}
                              {t("M002")}
                            </div>
                          </a>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SingleMediaPlayer;
