import { useEffect, useState } from "react";

import "./Carousel.css";

const CarouselItem = ({activeItem}) => {
  const [fadeOut, setFadeOut] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut("fadeOut");
    }, 3500);
    return () => {
      if (interval) {
        clearInterval(interval);
        setFadeOut("");
      }
    };
  }, []);

  return (
    <>
      {activeItem && (
        <>
          <h2 className={"secondary_text " + fadeOut}>{activeItem.name}</h2>
          <div className="tiles_container ">
            {activeItem.images.map((image, index) => (
              <img
                key={index}
                className={`tile${index} ` + fadeOut}
                src={image.src}
                alt={`${image.alt}`}
              ></img>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CarouselItem;
