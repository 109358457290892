import React, { useEffect, useState } from "react";

import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";

import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const OneToTenSlider = (props) => {
  const [textValue, setTextValue] = useState("");
  const [styles, setStyles] = useState(false);
  const [percent, setPercent] = useState("");

  const convertPercentValues = (value) => {
    switch (value) {
      case "1":
        return 5;
      case "2":
        return 15;
      case "3":
        return 25;
      case "4":
        return 35;
      case "5":
        return 45;
      case "6":
        return 55;
      case "7":
        return 65;
      case "8":
        return 75;
      case "9":
        return 85;
      default:
        return 95;
    }
  };

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      if (props.assessmentValues[props.questionid].textValue) {
        setTextValue("5");
        setStyles({ left: "55%" });
        setPercent(55);
      } else {
        let percentages = convertPercentValues(
          props.assessmentValues[props.questionid].toString()
        );
        setTextValue(props.assessmentValues[props.questionid]);
        setStyles({ left: percentages + "%" });
        setPercent(percentages);
      }
    } else {
      setTextValue("5");
      setStyles({ left: "50%" });
      setPercent(50);
    }
    props.onSetValue({ id: props.questionid, value: textValue });
  }, []);

  const onSlidePain = (render, handle, value, un, percent) => {
    let pos = (Number(percent[0]) / 2).toFixed(0);

    setPipClass(pos);

    let increments = "";

    if (pos <= 5) {
      increments = "1";
    } else if (pos <= 10) {
      increments = "2";
    } else if (pos <= 15) {
      increments = "3";
    } else if (pos <= 20) {
      increments = "4";
    } else if (pos <= 25) {
      increments = "5";
    } else if (pos <= 30) {
      increments = "6";
    } else if (pos <= 35) {
      increments = "7";
    } else if (pos <= 40) {
      increments = "8";
    } else if (pos <= 45) {
      increments = "9";
    } else {
      increments = "10";
    }
    setTextValue(increments);
    setStyles({ left: Number(percent[0]).toFixed(2) + "%" });

    props.onSetValue({ id: props.questionid, value: increments });
  };

  const setPipClass = (pos) => {
    if (pos > 50) {
      pos = 50;
    }

    let prePos = parseFloat(pos) - 1;
    let postPos = parseFloat(pos) + 1;

    let prePos2 = parseFloat(pos) - 2;
    let postPos2 = parseFloat(pos) + 2;

    let x = document
      .getElementById(props.questionid + "_slider")
      .querySelectorAll(".noUi-marker");

    [].forEach.call(x, function (el) {
      el.classList.remove("active");
      el.classList.remove("semiactive");
      el.classList.remove("nearactive");
    });

    if (pos <= x.length) {
      x[pos].classList.add("active");
    }
    if (prePos > -1) {
      x[prePos].classList.add("semiactive");
    }
    if (postPos <= 50) {
      x[postPos].classList.add("semiactive");
    }
    if (prePos2 > -1) {
      x[prePos2].classList.add("nearactive");
    }
    if (postPos2 <= 50) {
      x[postPos2].classList.add("nearactive");
    }
  };

  const setPain = () => {
    //alert('go');
  };

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
          question={props.questionid}
        />
      )}
      <div className="answer_block fs-exclude">
        <div className="painSlider" id={props.questionid}>
          <div className="leftLabel">{props.textValues[0]}</div>
          <div className="rightLabel">{props.textValues[1]}</div>
          <Nouislider
            id={props.questionid + "_slider"}
            pips={{ mode: "range", density: 2 }}
            clickablePips
            range={{ min: 0, max: 100 }}
            start={percent}
            connect="lower"
            onUpdate={onSlidePain}
            onSet={setPain}
            animate={false}
          />
          {styles && textValue && (
            <div
              className="valueHandle"
              id={props.questionid + "_value_handle"}
              style={styles}
            >
              {textValue}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OneToTenSlider;
