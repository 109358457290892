import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  faArrowRightToBracket,
  faLockAlt,
  faStar,
  faTrashAlt,
  faUser,
  faLanguage,
} from "@fortawesome/pro-light-svg-icons";

import { colours } from "../../../assets/colours";
import { AuthContext } from "../../../shared/context/auth-context";
import { useState } from "react";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { ImageUploadBtn } from "./ImageUploadBtn";
import { MenuOptionButton } from "../../components/menuOptionButton/MenuOptionButton";
import { ActionButton } from "../../components/actionButton/ActionButton";
import "./AccountDetails.css";

export const AccountDetailsLeftMenu = ({
  profile,
  changeMenu,
  activeSubMenu,
  changeSubMenu,
  isHidden,
  saveProfileImage,
}) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  if (!profile) return null;

  return (
    <>
      <ProfilePicture saveProfileImage={saveProfileImage} />
      <div className="profile-name fs-exclude">{profile.name}</div>
      <MenuOptionButton
        id="account-details"
        icon={faUser}
        color={colours.blue}
        text={t("S025")}
        onClick={() => changeSubMenu(0)}
        isActive={activeSubMenu.id === 51 && !isHidden}
      />
      {(auth.authType == null || auth.authType === "ChampionHealth") && (
        <MenuOptionButton
          id="security-settings"
          icon={faLockAlt}
          color={colours.yellow}
          text={t("S126")}
          onClick={() => changeMenu(1)}
        />
      )}
      <MenuOptionButton
        id="language-settings"
        icon={faLanguage}
        color={colours.green}
        text={t("PL001")}
        onClick={() => changeSubMenu(6)}
        isActive={activeSubMenu.id === 57 && !isHidden}
      />
      <div className="profile-action-btn-container">
        <ActionButton
          onClick={() =>
            window.open(
              `https://physitrack.typeform.com/first-feedback#client=${auth.clientName.replace(
                / /,
                "_"
              )}`,
              "_blank"
            )
          }
          text={t("S005")}
          icon={faStar}
        />
        <ActionButton
          onClick={auth.logout}
          icon={faArrowRightToBracket}
          text={t("S006")}
        />
        <ActionButton
          onClick={() => changeSubMenu(3)}
          icon={faTrashAlt}
          text={t("S007")}
          color={colours.red}
        />
      </div>
      <ProfileFooter year={currentYear} t={t} />
    </>
  );
};

const ProfilePicture = ({ saveProfileImage }) => {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="profile-photo fs-exclude">
      {isLoading && <LoadingSpinner asImage />}
      <img
        id="preview"
        key={auth.imageKey}
        src={auth.image}
        alt=""
        onLoad={() => setIsLoading(false)}
        style={{ display: isLoading ? "none" : "block" }}
      />
      <ImageUploadBtn saveProfileImage={saveProfileImage} />
    </div>
  );
};

const ProfileFooter = ({ year, t }) => (
  <div className="profile-footer">
    <div className="privacy">&copy; {year} Champion Health</div>
    <button
      onClick={() =>
        window.open("https://championhealth.co.uk/privacy-policy", "_blank")
      }
      className="privacy transparent-button"
    >
      {t("S127")}
    </button>
    <button
      onClick={() =>
        window.open(
          "https://championhealth.co.uk/privacy-policy#cookies",
          "_blank"
        )
      }
      className="privacy transparent-button"
    >
      {t("S024")}
    </button>
    <button
      onClick={() =>
        window.open("https://championhealth.co.uk/eula/", "_blank")
      }
      className="privacy transparent-button"
    >
      {t("S131")}
    </button>
  </div>
);
