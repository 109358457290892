import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFetchSingleMediaQuery } from "../../../../contentTypes/mediaApiSlice";
import { setSingleMedia } from "../../../../contentTypes/mediaSlice";
import { AuthContext } from "../../../context/auth-context";
import { checkChallengeSecionId } from "../../../util/challenges";

export const useContentItem = (slideContent) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);

  const { data: media, isLoading } = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/${
        slideContent?.media?.category?.toLowerCase() === "programme"
          ? "programme"
          : slideContent?.media?.category.toLowerCase() === "masterclass"
          ? "masterclass"
          : slideContent?.media?.chid.startsWith("TA") ||
            slideContent.chid.startsWith("TA")
          ? "take-actions"
          : "media"
      }/${
        slideContent.chid.startsWith("TA")
          ? slideContent?._id
          : slideContent?.media?.chid.startsWith("TA")
          ? slideContent?.media?._id
          : slideContent?.media?.chid
      }`,
    },
    { skip: !isClicked }
  );

  useEffect(() => {
    if (!isLoading && media && isClicked) {
      setIsClicked(false);
      dispatch(setSingleMedia(media));
      let url = "media";
      if (media.chid.startsWith("TA")) {
        url = "take-actions";
      } else if (media?.category?.toLowerCase() === "programme") {
        url = "programme";
      } else if (media.category?.toLowerCase() === "masterclass") {
        url = "masterclass";
      }
      navigate(`/${url}/${media.chid}/${media.title.replace(/ /g, "_")}`);
    }
  }, [media, isLoading, isClicked, dispatch]);

  const openMediaModal = () => {
    if (
      !(
        location.pathname.startsWith("/media") ||
        location.pathname.startsWith("/take-actions") ||
        location.pathname.startsWith("/programme") ||
        location.pathname.startsWith("/masterclass")
      )
    ) {
      sessionStorage.setItem(
        "previousLocation",
        JSON.stringify({ pathname: location.pathname, scrollY: window.scrollY })
      );
    }
    if (slideContent.chid.startsWith("PB") && slideContent.url) {
      if (slideContent.url.endsWith("/challenge")) {
        const partsArray = slideContent.url.split("/");
        if (
          partsArray.length < 3 ||
          partsArray[partsArray.length - 1] !== "challenge" ||
          checkChallengeSecionId(partsArray[partsArray.length - 2])
        ) {
          return;
        }
        navigate(
          `/${partsArray[partsArray.length - 2]}/${
            partsArray[partsArray.length - 1]
          }`
        );
      } else {
        window.open(slideContent.url, "_blank");
      }
    } else if (
      (slideContent.chid.startsWith("PB") && slideContent.media) ||
      slideContent.chid.startsWith("TA")
    ) {
      setIsClicked(true);
    } else {
      let url = "media";
      if (slideContent?.category?.toLowerCase() === "programme") {
        url = "programme";
      } else if (slideContent?.category?.toLowerCase() === "masterclass") {
        url = "masterclass";
      } else if (slideContent?.media?.chid.startsWith("TA")) {
        url = "take-actions";
      }
      navigate(
        `/${url}/${slideContent.chid}/${slideContent.title.replace(/ /g, "_")}`
      );
    }
  };

  return { openMediaModal };
};
