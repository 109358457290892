import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

function NumberSelect(props) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("0");

  function setIncreaseNumber() {
    if (props.disabled) return;
    var newValue = Number(inputValue);
    if (newValue < props.maxlimit) {
      newValue++;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function setDecreaseNumber() {
    if (props.disabled) return;
    var newValue = Number(inputValue);
    if (newValue > 0) {
      newValue--;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function numberChangeHandler(event) {
    setInputValue(event.target.value);
    saveValue(event.target.value);
  }

  const handleFocusOut = (event) => {
    if (props.maxlimit && Number(event.target.value) > props.maxlimit) {
      setInputValue(props.maxlimit);
      saveValue(props.maxlimit);
    } else if (
      props.minlimit &&
      (Number(event.target.value) < props.minlimit || !event.target.value)
    ) {
      setInputValue(props.minlimit);
      saveValue(props.minlimit);
    }
  };

  function saveValue(newValue) {
    props.onSetValue({ id: props.questionid, value: newValue });
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      setInputValue(props.assessmentValues[props.questionid]);
    } else if (
      props.startVal &&
      props.assessmentValues[props.questionid] !== 0
    ) {
      setInputValue(props.startVal);
      saveValue(props.startVal);
    }
  });

  return (
    <div
      className={`number_field number_field_numberSelect ${
        props.disabled ? "disabled" : "active"
      }`}
    >
      <div className="number_wrapper">
        <div className="number_container fs-exclude">
          <input
            type="number"
            className="number"
            id={props.questionid}
            onChange={numberChangeHandler}
            onBlur={handleFocusOut}
            value={inputValue}
            max={props.maxlimit}
            min="0"
            disabled={props.disabled}
          />
          {props.trailingText && (
            <label htmlFor={props.questionid}>{props.trailingText}</label>
          )}
        </div>
        <div className="number_controls">
          <button className="up_btn" onClick={setIncreaseNumber} aria-labelledby={`${props.questionid}_up`}>
            <span className="visuallyHidden" id={`${props.questionid}_up`} aria-hidden>
              {t("A134")} {props.controlsInfo}
            </span>
            <FontAwesomeIcon icon={faChevronUp} className="icon" />
          </button>
          <button className="down_btn" onClick={setDecreaseNumber} aria-labelledby={`${props.questionid}_down`}>
            <span className="visuallyHidden" id={`${props.questionid}_down`} aria-hidden>
              {t("A135")} {props.controlsInfo}
            </span>
            <FontAwesomeIcon icon={faChevronDown} className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NumberSelect;
