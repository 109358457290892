import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { faShieldAlt } from "@fortawesome/sharp-solid-svg-icons";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { convertTime } from "../../../util/helperfunctions";
import { SliderTooltip } from "../SliderTooltip";
import { PromoContent } from "../promoContent/PromoContent";
import { useContentItem } from "./useContentItem";

export const ContentItem = ({ slideContent, promo, author }) => {
  const { t } = useTranslation();
  const { ref } = useComponentVisible(false);
  const { openMediaModal } = useContentItem(slideContent);

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTileTooltip = (e) => {
    e.stopPropagation();
    setOpenTooltip(!openTooltip);
  };

  const closeTooltip = () => {
    setOpenTooltip(false);
  };

  //detect click outside of SliderTooltip and close it
  useEffect(() => {
    if (openTooltip) {
      const closeTooltip = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setOpenTooltip(false);
        }
      };
      document.addEventListener("click", closeTooltip);
      return () => document.removeEventListener("click", closeTooltip);
    }
  }, [openTooltip]);

  return (
    <>
      <button
        className="transparent-button fs-exclude innerSlide"
        style={{
          backgroundImage: `url(${
            slideContent.chid.startsWith("PB") || author
              ? slideContent.background_image
              : slideContent.thumbnail_image
          })`,
        }}
        onClick={openMediaModal}
        ref={ref}
      >
        {author && (
          <div className="authorPage_featured_info">
            <div className="authorPage_featured_star" />
            <div className="authorPage_featured_text">{t("AT005")}</div>
            <div className="authorPage_featured_play" />
          </div>
        )}

        {slideContent.chid.startsWith("PB") && (
          <PromoContent
            label={slideContent.label}
            title={slideContent.title}
            fade={slideContent.fade}
            labelColourFirst={slideContent.labelColourFirst}
            labelColourSecond={slideContent.labelColourSecond}
            subTitle={slideContent?.subTitle}
            url={slideContent?.url}
            company_logo={slideContent?.company_logo}
          />
        )}

        {slideContent.chid.startsWith("TA") && !author && (
          <span className="ta-badge">
            <FontAwesomeIcon className="ta-shield" icon={faShieldAlt} />
          </span>
        )}

        {!(promo || slideContent.chid.startsWith("TA") || author) && (
          <button
            className="transparent-button ellipsis"
            onClick={handleTileTooltip}
          >
            <FontAwesomeIcon icon={faEllipsis} className="icon active_fav" />
            {openTooltip && (
              <SliderTooltip
                slideContent={slideContent}
                handleTileTooltip={handleTileTooltip}
                closeTooltip={closeTooltip}
              />
            )}
          </button>
        )}

        {!slideContent.chid.startsWith("PB") && (
          <div className="innerFade">
            <div className="innerContent">
              <h2>{slideContent?.title?.replace(/&amp;/gi, "&")}</h2>

              {slideContent?.length > 0 && (
                <span className="length">
                  {slideContent?.length
                    ? convertTime(slideContent.length)
                    : slideContent?.days
                    ? slideContent?.days.length + " days"
                    : ""}
                </span>
              )}

              {slideContent?.label && (
                <span
                  className={
                    slideContent?.length > 0
                      ? "category"
                      : "category full_width"
                  }
                >
                  {slideContent.label}
                </span>
              )}
              {(slideContent?.type ||
                slideContent.category?.toLowerCase() === "masterclass" ||
                slideContent.category?.toLowerCase() === "programme") &&
                !author && (
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{
                        width: slideContent.progress + "%",
                      }}
                    ></div>
                  </div>
                )}
            </div>
          </div>
        )}
      </button>
    </>
  );
};
