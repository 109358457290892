import "./style.css";
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import Logo from "../../pages/images/logo_white.svg";
import Layout from "../components/Layout";

function AccessCode() {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation();

  const [codeValue, setCodeValue] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { isLoading, sendRequest } = useHttpClient();

  const navigate = useNavigate();
  const codeid = useParams().codeid;

  const setCodeValueHandler = (event) => {
    setCodeValue(event.target.value);
  };

  const codeSubmitHandler = useCallback(
    async (event) => {
      if (event) event.preventDefault();
      let code = codeValue || codeid;

      if (code.trim().toLowerCase() === "fandfch") {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            clientid: 0,
            fandfUser: "fandfch",
            domain_validation: false,
            isStandardAuthActive: true,
            isMicrosoftAuthActive: true,
            isGoogleAuthActive: true,
            singleSignOn: [],
          })
        );
        navigate("/sign-in");
        return;
      } else {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/getClientByCode/${code}/web`
          );

          if (!response.id || response.id === 0) {
            setErrorMessage(t("R017"));
          } else {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                clientid: response.id,
                dept: response.dept,
                fandfUser: response.fandfUser,
                domain_validation: response.domainValidation,
                isStandardAuthActive: response.isStandardAuthActive,
                isMicrosoftAuthActive: response.isMicrosoftAuthActive,
                isGoogleAuthActive: response.isGoogleAuthActive,
                singleSignOn: response.sso,
              })
            );

            if (response.open === "yes") {
              setErrorMessage("");

              if (codeid) {
                navigate("/signup");
              } else {
                navigate("/sign-in");
              }
            } else {
              setErrorMessage(t("R016"));
            }
          }
        } catch (err) {}
      }
    },
    [codeValue, codeid, navigate, sendRequest, t]
  );

  useEffect(() => {
    if (location.state?.from) {
      sessionStorage.setItem("DirectLink", location.state.from);
    }
  }, [location.state]);

  //check for codes
  useEffect(() => {
    if (codeid) {
      codeSubmitHandler();
    }
    if (auth.isLoggedIn) {
      //check if assessment complete or redirected from another page
      let storedUrl = sessionStorage.getItem("DirectLink");
      sessionStorage.removeItem("DirectLink");
      if (storedUrl && auth.tags && auth.tags.length > 0) {
        navigate(storedUrl);
      } else if (auth.tags && auth.tags.length > 0) {
        navigate("/for-you");
      } else if ((auth.tags && auth.tags.length < 1) || !auth.tags) {
        navigate("/assessment");
      }
    }
  }, [auth.isLoggedIn, auth.tags, codeSubmitHandler, codeid, navigate]);

  return (
    <Layout>
      <div id="right_container">
        <div className="frame">
          <div className="logo">
            <img
              src={Logo}
              className="icon title_icon"
              alt="Champion Health Logo"
            />
          </div>
          {auth.isLoggedIn && <p>{t("R046")}</p>}
          {!auth.isLoggedIn && (
            <React.Fragment>
              <h1>{t("R008")}</h1>
              <p>{t("R009")}</p>
              <label className="visuallyHidden" htmlFor="company_code" aria-hidden="true">
                {t("R092")}
              </label>
              <input
                type="text"
                id="company_code"
                name="company_code"
                placeholder={t("R010")}
                className="code_input"
                aria-label={t("R092")}
                onChange={setCodeValueHandler}
              />
              {errorMessage && (
                <p className="login_error">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="icon"
                  />
                  {errorMessage}
                </p>
              )}
              {!isLoading && (
                <button onClick={codeSubmitHandler} id="continue">
                  {t("R078")}
                </button>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="lower_container bottom_padding">
          <p className="signin">
            {t("R077")} <a href="/find-code">{t("R079")}</a>
          </p>
          <p className="signin">
            {t("R075")}{" "}
            <a
              href="https://support.championhealth.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              {t("R076")}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}
export default AccessCode;
