import ReactConfetti from "react-confetti";
import { colours } from "../../assets/colours";

export const Confetti = ({ isActive }) => {
  return (
    <ReactConfetti
      width={window.innerWidth}
      height={window.innerHeight}
      numberOfPieces={100}
      colors={[
        colours.red,
        colours.orange,
        colours.yellow,
        colours.blue,
        colours.green,
      ]}
      recycle={isActive}
    />
  );
};
