import { useEffect, useState } from "react";

import { ProfileStatsLeftMenu } from "./ProfileStatsLeftMenu";
import { ProfileStatsRightMenu } from "./ProfileStatsRightMenu";

export const ProfileStats = ({ hide }) => {
  const subMenuOptions = [
    { id: 61, title: "streaks" },
    { id: 62, title: "stats" },
  ];
  const [activeSubMenu, setActiveSubMenu] = useState(subMenuOptions[0]);

  const [showLeftMenu, setShowLeftMenu] = useState(true);

  useEffect(() => {
    if (hide) {
      setShowLeftMenu(false);
    } else {
      setShowLeftMenu(true);
    }
  }, [hide]);

  const changeSubMenu = (props) => {
    setActiveSubMenu(subMenuOptions[props]);
  };
  return (
    <div className="profile-container">
      {showLeftMenu && (
        <div className="menu-left">
          <ProfileStatsLeftMenu
            changeSubMenu={changeSubMenu}
            activeSubMenu={activeSubMenu}
          />
        </div>
      )}
      <div className={`menu-right ${hide ? "simplify" : ""}`}>
        <ProfileStatsRightMenu activeSubMenu={activeSubMenu} hide={hide} />
      </div>
    </div>
  );
};
