import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Slider } from "../../shared/components/slider/Slider";

import ColourBars from "../components/ColourBars";
import LargeCircle from "../components/LargeCircle";

const FinancialWellbeingResults = (props) => {
  const { t } = useTranslation();
  const [finComfortCopy, setFinComfortCopy] = useState("");
  const { assessScores } = props.dataObj;

  useEffect(() => {
    if (assessScores?.financial_position_score > -1) {
      let score = assessScores.financial_position_score;
      if (score <= 2) {
        setFinComfortCopy(t("D125"));
      } else if (score <= 3) {
        setFinComfortCopy(t("D124"));
      } else {
        setFinComfortCopy(t("D123"));
      }
    }
  }, [assessScores?.financial_position_score]);

  return (
    <div className="results-container">
      {assessScores?.financial_wellbeing_score && (
        <div className="financial-grid">
          <LargeCircle
            title={t("D114")}
            percent={assessScores.financial_wellbeing_score}
            width="double-row"
            modalText={[t("D119")]}
            openModal={props.openModal}
          />
          <ColourBars
            title={t("D115")}
            value={assessScores.financial_position_score}
            text={finComfortCopy}
            width="fin-well"
          />
          <ColourBars
            title={t("D116")}
            text={t("D120")}
            value={assessScores.confident_managing_finance}
            width="fin-well"
          />
          <ColourBars
            title={t("D117")}
            text={t("D121")}
            value={assessScores.financial_security}
            width="fin-well"
          />
          <ColourBars
            title={t("D118")}
            text={t("D122")}
            value={assessScores.cannot_meet_financial_goals}
            width="fin-well"
          />
        </div>
      )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default FinancialWellbeingResults;
