import { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../../../shared/context/auth-context";
import { useTranslation } from "react-i18next";

export const useAccountDetailsState = ({ profile, saveChanges }) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState();
  const [profileItem, setProfileItem] = useState(profile);
  const [activeDept, setActiveDept] = useState();
  const [activeSubDept, setActiveSubDept] = useState();
  const [subDeptsArr, setSubDeptsArr] = useState();
  const [activeRegion, setActiveRegion] = useState();
  const [regionArr, setRegionArr] = useState();
  const [regionGroupArr, setRegionGroupArr] = useState([]);
  const [regionGroup, setRegionGroup] = useState();
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [activeButton, setActiveButton] = useState(true);

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const openModal = useCallback(
    (title, text) => {
      setModalIsOpen(true);
      setModalTextContent({ title, text });
    },
    [modalIsOpen, modalTextContent]
  );

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  useEffect(() => {
    let userRegion;
    //exclude users without client regions or those with automatic region overwrite
    if (
      profile.regions?.length > 0 &&
      !(
        profile.access == "2" &&
        profile?.fandf_region_ovewrite === profile.user?.region
      )
    ) {
      //existing user region
      userRegion = profile.regions.find((obj) => obj._id === profile.region);
      if (userRegion) {
        setActiveRegion(userRegion.verbose_name);
        if (userRegion.region_group) {
          setRegionGroup(userRegion.region_group);
        }
      }

      //all regions for this client
      let regions = [];

      profile.regions.map((region) => regions.push(region.verbose_name));
      setRegionArr(regions);
      setAllRegions(profile.regions);

      if (profile.regions[0].region_group) {
        let groupArr = [];
        profile.regions.map((region) => {
          if (!groupArr.includes(region.region_group)) {
            groupArr.push(region.region_group);
          }
        });
        let filteredOptionsArr = [];
        if (userRegion && userRegion.region_group) {
          filteredOptionsArr = profile.regions.filter(
            (region) => region.region_group === userRegion.region_group
          );
        }
        let reducedOptionsArr = [];
        filteredOptionsArr.map((region) =>
          reducedOptionsArr.push(region.verbose_name)
        );
        setRegionsOptions(reducedOptionsArr);
        setRegionGroupArr(groupArr);
      }
    }
    if (profile.departments?.length > 0 && profile.access != "2") {
      let index = Number(profile.department) - 1;
      if (index > -1) {
        setActiveDept(profile.departments[index]);
        if (profile.sub_departments.length > 0 && profile.sub_department) {
          setActiveSubDept(
            profile.sub_departments[index][Number(profile.sub_department) - 1]
          );
          if (profile.department) {
            setSubDeptsArr(profile.sub_departments[index]);
          }
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (
      profileItem.name?.length < 1 ||
      !isEmailValid ||
      (regionGroupArr && regionGroupArr.length > 0 && !regionGroup) ||
      (regionArr && regionArr.length > 1 && !activeRegion) ||
      (profileItem.departments &&
        profileItem.departments.length > 1 &&
        profileItem.access != "2" &&
        !activeDept) ||
      (subDeptsArr &&
        subDeptsArr.length > 0 &&
        profileItem.access != "2" &&
        !activeSubDept)
    ) {
      setActiveButton(false);
    } else {
      setActiveButton(true);
    }
  }, [
    profileItem.name,
    isEmailValid,
    activeRegion,
    regionGroup,
    activeDept,
    activeSubDept,
  ]);

  const handleEmailChange = (event) => {
    let valid = false;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = emailRegex.test(String(event.target.value).toLowerCase());

    setIsEmailValid(valid);

    setProfileItem((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const handleNameChange = (event) => {
    let valid = false;
    const nameRegex = /^[a-zA-Z0-9\s.'-]*$/;
    valid = nameRegex.test(event.target.value);
    valid = event.target.value.length < 50 && valid;
    if (valid) {
      setProfileItem((prevState) => ({
        ...prevState,
        [event.target.id]: event.target.value,
      }));
    }
  };

  const handleSelectDept = (event) => {
    let department = event;
    setActiveDept(department);
    if (subDeptsArr) {
      let index = profileItem.departments.indexOf(department);
      setActiveSubDept(profileItem.sub_departments[index][0]);
      setSubDeptsArr(profileItem.sub_departments[index]);
    }
  };

  const handleSelectSubDept = (event) => {
    let subdept = event;
    setActiveSubDept(subdept);
  };

  const handleSelectRegion = (event) => {
    let region = event;
    setActiveRegion(region);
  };

  const handleSelectRegionGroup = (event) => {
    let group = event;
    setRegionGroup(group);
    let regionFilteredArr = allRegions.filter(
      (region) => region.region_group === group
    );
    let reducedOptions = [];
    regionFilteredArr.map((region) => reducedOptions.push(region.verbose_name));
    setRegionsOptions(reducedOptions);
    setActiveRegion(reducedOptions[0]);
  };

  const saveProfile = async () => {
    if (profileItem.name?.length < 1) {
      openModal(t("S002"), [t("S054")]);
      return;
    }
    if (!isEmailValid) {
      openModal(t("S002"), [t("S055")]);
      return;
    }
    if (profileItem.departments?.length > 0 && auth.access != "2") {
      if (!activeDept) {
        openModal(t("S002"), [t("R058")]);
        return;
      }
      if (
        !activeSubDept &&
        profileItem.sub_departments?.[activeDept]?.length > 0
      ) {
        openModal(t("S002"), [t("R059")]);
        return;
      }
      let deptIndex = profileItem.departments.indexOf(activeDept);
      if (
        profileItem.department &&
        profileItem.departments[Number(profileItem.department) - 1] !==
          activeDept
      ) {
        profileItem.department = String(deptIndex + 1);
      } else if (!profileItem.department && activeDept) {
        //if departments were added after the user signed up, but now needs/can pick a department
        profileItem.department = String(deptIndex + 1);
      }
      if (!!activeSubDept) {
        profileItem.sub_department = String(
          profileItem.sub_departments[deptIndex].indexOf(activeSubDept) + 1
        );
      }
      //if user changed departments from one with sub-departments to one without, clear sub-department
      if (
        profileItem?.sub_departments &&
        activeSubDept === undefined &&
        (!profileItem.sub_departments?.[deptIndex] ||
          profileItem.sub_departments?.[deptIndex]?.length < 1)
      ) {
        profileItem.sub_department = "";
      }
    }

    if (profileItem.regions.length > 0) {
      if (!activeRegion) {
        openModal(t("S002"), [t("R057")]);
        return;
      }
      let region = profileItem.regions.find(
        (obj) => obj.verbose_name === activeRegion
      );
      if (profileItem.region !== region._id) {
        profileItem.region = region._id;
      }
    }

    if (profileItem && auth.token) {
      saveChanges(profileItem);
      showGreenCheckOverlay();
    }
  };
  return {
    modalIsOpen,
    modalTextContent,
    profile: profileItem,
    activeDept,
    activeSubDept,
    subDeptsArr,
    activeRegion,
    regionArr,
    regionGroupArr,
    regionGroup,
    regionsOptions,
    showGreenCheck,
    isEmailValid,
    activeButton,
    modalClickHandler,
    handleEmailChange,
    handleNameChange,
    saveProfile,
    handleSelectRegionGroup,
    handleSelectRegion,
    handleSelectSubDept,
    handleSelectDept,
  };
};
