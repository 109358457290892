import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { colours } from "../../assets/colours";
import { useTranslation } from "react-i18next";
import smileyFace from "../images/smileyFace.png";

export const InviteLeftMenu = ({
  userFnF,
  activeProfile,
  handleActiveProfile,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="profile-name left">{t("I001")}</div>
      {Array.from({ length: 3 }).map((_, index) =>
        index < userFnF?.length ? (
          <ActiveProfile
            key={index}
            userFnF={userFnF}
            index={index}
            activeProfile={activeProfile}
            handleActiveProfile={handleActiveProfile}
          />
        ) : (
          <InviteOption
            key={index}
            index={index}
            handleActiveProfile={() => handleActiveProfile(index)}
          />
        )
      )}
    </>
  );
};

const ActiveProfile = ({
  userFnF,
  index,
  activeProfile,
  handleActiveProfile,
}) => {
  const { t } = useTranslation();

  const isPending = userFnF?.[index]?.status === "pending";

  return (
    <button
      className={`profile-slider-btn large ${
        index === activeProfile.id ? "active" : ""
      } fs-exclude`}
      onClick={() => handleActiveProfile(index)}
      aria-labelledby={`profile-slider-btn-${index}`}
    >
      <div className="menu-item" aria-hidden="true">
        <div
          className="ff-cont"
          style={{
            backgroundColor: colours[userFnF?.[index]?.colour],
          }}
        >
          <img src={smileyFace} alt="smiley face" />
        </div>
        <div className="text-cont">
          <div className="slider-text" id={`profile-slider-btn-${index}`}>
            {userFnF?.[index]?.name}
            <span hidden>{`${t("S137")}: ${
              isPending ? t("S015") : t("S138")
            }`}</span>
          </div>
          {isPending && (
            <div className="pending" tabIndex={-1}>
              {t("S015")}
            </div>
          )}
        </div>
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="slider-chevron"
        aria-hidden="true"
      />
    </button>
  );
};

const InviteOption = ({ handleActiveProfile, index }) => {
  const { t } = useTranslation();
  return (
    <button
      className="profile-slider-btn large empty"
      onClick={handleActiveProfile}
      aria-labelledby={`invite-option-${index}`}
    >
      <div className="menu-item" aria-hidden="true">
        <div className="ff-cont ">
          <img src={smileyFace} alt="smiley face" />
        </div>
        <div className="text-cont">
          <div className="slider-text" id={`invite-option-${index}`}>
            {t("S013")}
            <span hidden>{t("S139")}</span>
          </div>
        </div>
      </div>
      <FontAwesomeIcon
        icon={faPlusCircle}
        className="slider-chevron"
        aria-hidden="true"
      />
    </button>
  );
};
