import React, { useContext } from "react";
import "./style.css";

import Header from "../../shared/header/Header";
import { Footer } from "../../assessment/footer/Footer";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useAuth } from "../../shared/hooks/auth-hook";

function Settings(props) {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {
    token,
    login,
    logout,
    userId,
    fname,
    assessmentId,
    dept,
    access,
    usageId,
    tags,
  } = useAuth();

  return (
    <div className="settings-page">
      <div id="nav_container" className="register">
        <Header />{" "}
      </div>
      <h1>My tags</h1>

      {isLoading && <LoadingSpinner />}
      {!auth.isLoggedIn && error && <p>Invalid log in. Please try again.</p>}
      {auth.isLoggedIn && tags && (
        <div>
          <ul>
            {tags.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
export default Settings;
