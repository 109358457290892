// mediaSlidersApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// mediaSlidersApiSlice.js
export const userApiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  endpoints: (builder) => ({
    updateNps: builder.mutation({
      query: ({ token, nps }) => ({
        url: "/users/updateNps",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ nps: nps }),
      }),
    }),
  }),
});

export const { useUpdateNpsMutation } = userApiSlice;
