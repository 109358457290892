import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Radio from "../questions/radio/Radio";
import DialSlider from "../questions/dialSlider/DialSlider";
import MskBody from "../questions/mskBody/MskBody";
import ModalTrigger from "../../shared/modal/ModalTrigger";
import Checkbox from "../questions/checkbox/Checkbox";
import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";
import PainSlider from "../questions/painSlider/PainSlider";
import MultipleNumberSelect from "../questions/multipleNumberSelect/MultipleNumberSelect";

import { worstAreaOfPain } from "../../shared/util/enum";

function PhysicalHealth1(props) {
  const { t } = useTranslation();
  const currentJointPainOptions = [
    { id: "yes", title: t("PH001") },
    { id: "no", title: t("PH002") },
  ];

  let sex;
  if (props.assessmentValues.tailor_recommendations === "male_specific") {
    sex = "male";
  } else if (
    props.assessmentValues.tailor_recommendations === "female_specific"
  ) {
    sex = "female";
  } else {
    sex = "neutral";
  }

  const seenSpecialistOptions = [
    { id: "yes", title: t("PH017") },
    { id: "no", title: t("PH018") },
  ];

  const painTextValues = [
    t("PH019"),
    t("PH020"),
    t("PH021"),
    t("PH022"),
    t("PH023"),
  ];

  const painConstantOptions = [
    { id: "constant", title: t("PH024") },
    { id: "intermittent", title: t("PH025") },
  ];

  const timeOptions = [
    { id: "years", trailingText: t("PH026"), limit: "99" },
    { id: "months", trailingText: t("PH027"), limit: "12" },
    { id: "weeks", trailingText: t("PH028"), limit: "52" },
  ];

  const longTermTreatmentOptions = [
    { id: "yes", title: t("PH029") },
    { id: "no", title: t("PH030") },
  ];

  const longTermConditionOptions = [
    { id: "none", title: t("PH031") },
    { id: "anaemia", title: t("PH032") },
    { id: "asthma", title: t("PH033") },
    { id: "copd", title: t("PH034") },
    { id: "cancer", title: t("PH035") },
    { id: "cardiovascular_disease", title: t("PH036") },
    { id: "chronic_fatigue", title: t("PH037") },
    { id: "chronic_pain", title: t("PH038") },
    { id: "fibromyalgia", title: t("PH039") },
    { id: "hay_fever", title: t("PH040") },
    { id: "inflamatory_bowel_disease", title: t("PH041") },
    { id: "ibs", title: t("PH042") },
    { id: "kidney_disease", title: t("PH043") },
    { id: "long_covid", title: t("PH044") },
    { id: "multiple_sclerosis", title: t("PH045") },
    { id: "osteoarthritis", title: t("PH046") },
    { id: "osteoporosis", title: t("PH047") },
    { id: "parkinsons_disease", title: t("PH048") },
    { id: "rheumatoid_arthritis", title: t("PH049") },
    { id: "type_1_diabetes", title: t("PH050") },
    { id: "type_2_diabetes", title: t("PH051") },
    { id: "other", title: t("PH052") },
  ];

  const coldFluOptions = [
    { id: "yes", title: t("PH053") },
    { id: "no", title: t("PH054") },
  ];

  const workingDayConditionOptions = [
    { id: "none", title: t("PH055") },
    { id: "tired_eyes", title: t("PH056") },
    { id: "dry_eyes", title: t("PH057") },
    { id: "headaches", title: t("PH058") },
    { id: "migraines", title: t("PH059") },
    { id: "blurred_vision", title: t("PH060") },
  ];

  const menstrualStatusOptions = [
    { id: "periods", title: t("PH061") },
    { id: "pregnant", title: t("PH062") },
    { id: "perimenopausal", title: t("PH063") },
    { id: "menopausal", title: t("PH064") },
    { id: "postmenopausal", title: t("PH065") },
    { id: "no_periods", title: t("PH066") },
    { id: "other", title: t("PH067") },
  ];

  const trimesterOptions = [
    { id: "trimester1", title: t("PH068") },
    { id: "trimester2", title: t("PH069") },
    { id: "trimester3", title: t("PH070") },
  ];

  const textValue = [t("PH071"), t("PH072")];

  useEffect(() => {
    if (
      props.assessmentValues.current_joint_muscle_pain !== "yes" ||
      props.assessmentValues.current_joint_muscle_pain === undefined
    ) {
      props.onSetValue({ id: "areas_of_pain", value: null });
      props.onSetValue({ id: "worst_area_of_pain", value: null });
      props.onSetValue({ id: "pain_constant", value: null });
      props.onSetValue({ id: "pain", value: null });
      props.onSetValue({ id: "duration_of_pain", value: null });
      props.onSetValue({ id: "seen_specialist_pain", value: null });
    }
  }, [props.assessmentValues.current_joint_muscle_pain]);

  useEffect(() => {
    if (props.assessmentValues.areas_of_pain?.length > 1) {
      props.onSetValue({ id: "worst_area_of_pain", value: null });
    }
  }, [props.assessmentValues.areas_of_pain]);

  useEffect(() => {
    if (
      props.assessmentValues.worst_area_of_pain === undefined ||
      props.assessmentValues.worst_area_of_pain === null
    ) {
      props.onSetValue({ id: "pain_constant", value: null });
      props.onSetValue({ id: "seen_specialist_pain", value: null });
    }
  }, [props.assessmentValues.worst_area_of_pain]);

  useEffect(() => {
    if (
      props.assessmentValues.long_term_conditions === undefined ||
      props.assessmentValues.long_term_conditions[0].id === "none"
    ) {
      props.onSetValue({ id: "long_term_treatment", value: null });
    }
  }, [props.assessmentValues.long_term_conditions]);

  useEffect(() => {
    if (props.assessmentValues.tailor_recommendations !== "female_specific") {
      props.onSetValue({ id: "menstrual_status", value: null });
      props.onSetValue({ id: "trimester", value: null });
    }
  }, [props.assessmentValues.tailor_recommendations]);

  useEffect(() => {
    if (
      props.assessmentValues.menstrual_status !== "pregnant" ||
      props.assessmentValues.menstrual_status === undefined
    ) {
      props.onSetValue({ id: "trimester", value: null });
    }
  }, [props.assessmentValues.menstrual_status]);

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <Radio
        questionid="current_joint_muscle_pain"
        width=""
        questionTitle={t("PH073")}
        options={currentJointPainOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      {props.assessmentValues.current_joint_muscle_pain === "yes" && (
        <div>
          <div className="question_block msk_qbox">
            <h3>
              {t("A123")}
              <span style={{ marginLeft: "10px" }}>
                <ModalTrigger
                  modalText={t("PH074")}
                  onOpenModal={props.onOpenModal}
                  question="areas"
                />
              </span>
            </h3>
          </div>
          <MskBody
            questionid="areas_of_pain"
            sex={sex}
            onSetValue={props.onSetValue}
            areas_of_pain={props.assessmentValues.areas_of_pain}
          />
          {props.assessmentValues.areas_of_pain &&
          props.assessmentValues.areas_of_pain.length > 1 ? (
            <CheckboxRadio
              questionid="worst_area_of_pain"
              width=""
              questionTitle={t("PH078")}
              questionTitleSuffix=""
              options={worstAreaOfPain}
              placeholder={t("PH079")}
              includeOnly={props.assessmentValues.areas_of_pain}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
            />
          ) : null}
          {props.assessmentValues.worst_area_of_pain ? (
            <Radio
              questionid="pain_constant"
              width=""
              questionTitle={
                t("PH080") +
                props.assessmentValues.worst_area_of_pain.id.replace("_", " ") +
                t("PH081")
              }
              options={painConstantOptions}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              modalText={t("PH082")}
              onOpenModal={props.onOpenModal}
            />
          ) : null}
          <PainSlider
            questionid="pain"
            width=""
            textValues={painTextValues}
            questionTitle={t("PH083")}
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <MultipleNumberSelect
            questionid="duration_of_pain"
            questionTitle={t("PH084")}
            maxlimit="99"
            options={timeOptions}
            width=""
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            modalText={t("PH085")}
            onOpenModal={props.onOpenModal}
          />
          {props.assessmentValues.worst_area_of_pain ? (
            <Radio
              questionid="seen_specialist_pain"
              width=""
              questionTitle={
                t("PH086") +
                props.assessmentValues.worst_area_of_pain.id.replace("_", " ") +
                t("PH087")
              }
              options={seenSpecialistOptions}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              modalText={t("PH088")}
              onOpenModal={props.onOpenModal}
              fadeIn="yes"
            />
          ) : null}
        </div>
      )}
      <hr />
      <Checkbox
        questionid="long_term_conditions"
        width="_wide"
        questionTitle={t("PH089")}
        questionTitleSuffix=""
        options={longTermConditionOptions}
        placeholder={t("PH090")}
        itemLimit="3"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        fadeIn="yes"
        modalText={t("PH091")}
        onOpenModal={props.onOpenModal}
      />
      {props.assessmentValues.long_term_conditions &&
      props.assessmentValues.long_term_conditions.filter(
        (item) => item.id === "none"
      ).length === 0 ? (
        <Radio
          questionid="long_term_treatment"
          width=""
          questionTitle={t("PH092")}
          options={longTermTreatmentOptions}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
          modalText={t("PH093")}
          onOpenModal={props.onOpenModal}
        />
      ) : null}
      <Radio
        questionid="frequent_colds_flu"
        width=""
        questionTitle={t("PH094")}
        options={coldFluOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PH095")}
        onOpenModal={props.onOpenModal}
      />
      <Checkbox
        questionid="working_day_conditions"
        width=""
        questionTitle={t("PH096")}
        questionTitleSuffix={t("PH097")}
        options={workingDayConditionOptions}
        placeholder={t("PH098")}
        itemLimit="2"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        secondaryQ
      />
      {props.assessmentValues.tailor_recommendations === "female_specific" && (
        <Radio
          questionid="menstrual_status"
          width=""
          questionTitle={t("PH099")}
          options={menstrualStatusOptions}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          modalText={t("PH100")}
          onOpenModal={props.onOpenModal}
        />
      )}
      {props.assessmentValues.menstrual_status &&
        sex === "female" &&
        props.assessmentValues.menstrual_status === "pregnant" && (
          <Radio
            questionid="trimester"
            width=""
            questionTitle={t("PH101")}
            options={trimesterOptions}
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            fadeIn="yes"
          />
        )}
      <DialSlider
        questionid="overall_physical_health"
        width=""
        questionTitle={t("PH102")}
        textValue={textValue}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PH103")}
        onOpenModal={props.onOpenModal}
      />
    </div>
  );
}
export default PhysicalHealth1;
